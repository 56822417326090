<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import { getEmojiFlag } from 'countries-list'
import TimeAgo from 'javascript-time-ago'
import dayjs from 'dayjs'

import en from 'javascript-time-ago/locale/en'
import InfoBox from '!/components/shared/InfoBox.vue'
import { globalProperties as app } from '!/plugins/utilities'

const props = defineProps({
  actualGuild: {
    type: Object,
    default: undefined
  },
  payers: {
    type: Array,
    default: undefined
  },
  showDrawerGuild: {
    type: Boolean,
    default: false
  },
  apiUrl: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:showDrawerGuild'])

TimeAgo.addLocale(en)

const guildDataStruct = {
  guildHistory: [],
  chat: [],
  payments: [],
  guildTabModel: 'history',
  zone: '-'
}

const guildData = ref({ ...guildDataStruct })

const loadingGuildData = ref(false)
const loadingGuildChatAIData = ref(false)
const loadingSaveComment = ref(false)
const guildChatAISummary = ref(false)
const historyGroup = ref('Season')
const comment = ref('')
const chosenChats = ref({})

const guildHistory = computed(() => {
  const data = []
  for (const row of guildData.value.guildHistory) {
    const val = {}
    val.Season = row.Season // OK
    val.Day = row.Day // OK
    val.Result = row.Result // OK
    val.OpponentResult = row.OpponentResult // OK
    val.MembersPlayers = row.MembersPlayers // OK
    val.OpponentMembersPlayers = row.OpponentMembersPlayers // OK
    val.GuildPower = row.GuildPower // OK
    val.OpponentPower = row.OpponentPower // OK
    val.GuildScore = row.GuildScore // OK
    val.OpponentGuildScore = row.OpponentGuildScore // OK
    val.Division = row.Division // OK
    val.TotalPayments = 0
    val.Payments = []
    for (const p of guildData.value.payments) {
      if (p.Season === row.Season && p.Day === row.Day) {
        val.TotalPayments += p.Price
        val.Payments.push({ price: p.Price, count: 1, player: p.PlayerID })
      }
    }

    // New
    val.EnergyEOD = row.EnergyEOD // SKIP
    val.GemsEOD = row.GemsEOD // SKIP
    val.MembersBots = row.MembersBots // SKIP
    val.OpponentCountry = row.OpponentCountry // SKIP
    val.OpponentGuildID = row.OpponentGuildID // SKIP
    val.OpponentMembersBots = row.OpponentMembersBots // SKIP

    val.PlayersActive = row.PlayersActive // OK - aktywny w ciagu dnia, zorbic cokolwieg w grze, np odebral nagrode
    val.OpponentPlayersActiveBattle = row.OpponentPlayersActiveBattle // OK - grace ktorzy dla danej walki skirmishowej wykonali jakas akcje, nawet przed dwa nid
    val.PlayersActiveBattle = row.PlayersActiveBattle // ok - to co wyzje ale dla gildi
    val.PlayersLiveBattle = row.PlayersLiveBattle // ok- wykonali akcje w bitwie ale podcas skirmisha
    // End new

    val.BattlesTypes = [0, 0, 0]
    val.BattlesTotal = 1
    val.PaymentsTotalSeason = 0
    const powerColors = {
      2: 'text-red-700',
      1: 'text-orange-400',
      0: 'text-green-700'
    }
    val.GuildPowerDiff = 0
    const powerTotal = row.GuildPower + row.OpponentPower
    if (powerTotal > 0) {
      const diff = row.GuildPower > row.OpponentPower ? row.GuildPower / row.OpponentPower : row.OpponentPower / row.GuildPower
      if (diff > 2) {
        val.GuildPowerDiff = 2
      } else if (diff > 1.5) {
        val.GuildPowerDiff = 1
      }
      val.GuildPowerDiffColor = powerColors[val.GuildPowerDiff]
    }
    val.BattlesTypes[val.GuildPowerDiff]++
    if (historyGroup.value === 'Season') {
      let has = false
      for (const i in data) {
        if (data[i].Season === val.Season) {
          has = true
          data[i].BattlesTotal++
          data[i].TotalPayments += val.TotalPayments
          for (const p of val.Payments) {
            let has = false
            for (const p2 of data[i].Payments) {
              if (p.player === p2.player) {
                p2.count++
                p2.price += p.price
                has = true
              }
            }
            if (!has) {
              data[i].Payments.push(p)
            }
          }
          if (val.Result > val.OpponentResult) {
            data[i].WinTotal++
          }
          data[i].BattlesTypes[val.GuildPowerDiff]++
          data[i].ScoreTotal += val.GuildScore
          data[i].OpponentScoreTotal += val.OpponentGuildScore
          break
        }
      }
      for (const i in data) {
        data[i].Payments.sort((a, b) => {
          return a.price > b.price ? -1 : 1
        })
      }
      if (!has) {
        val.BattlesTotal = 1
        val.WinTotal = val.Result > val.OpponentResult ? 1 : 0
        val.BattlesTypes = [0, 0, 0]
        val.BattlesTypes[val.GuildPowerDiff]++
        val.ScoreTotal = val.GuildScore
        val.OpponentScoreTotal = val.OpponentGuildScore
        data.push(val)
      }
    } else {
      data.push(val)
    }
  }
  const maxScores = {}
  for (const row of data) {
    if (maxScores[row.Season] === undefined) {
      maxScores[row.Season] = row.GuildScore
    } else {
      maxScores[row.Season] = Math.max(maxScores[row.Season], row.GuildScore)
    }
  }
  for (const row of data) {
    row.ScorePercent = 0
    row.PlayerScorePercent = 0
    if (maxScores[row.Season]) {
      row.ScorePercent = Math.round(row.GuildScore / maxScores[row.Season] * 100)
    }
  }

  data.sort((a, b) => {
    if (a.Season > b.Season) {
      return -1
    } else if (a.Season === b.Season) {
      if (a.Day > b.Day) {
        return -1
      }
      return 1
    }
    return 1
  })
  return data
})

const showDrawerGuildModel = computed({
  get() {
    return props.showDrawerGuild
  },
  set(newVal) {
    emit('update:showDrawerGuild', newVal)
  }
})

const payersMap = computed(() => {
  const m = {}
  for (const row of props.payers) {
    m[row.ID] = row
  }
  return m
})

const guildTotalWins = computed(() => {
  let total = 0
  let totalWins = 0
  for (const row of guildData.value.guildHistory) {
    total++
    if (row.OpponentResult < row.Result) {
      totalWins++
    }
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalWins / total * 100)
  return `${totalWins}/${total}(${percentage}%)`
})

const greenBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of guildHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[0] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const orangeBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of guildHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[1] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const redBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of guildHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[2] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const lazyInitTabsContent = ref({ history: true })
function dateAgo(seconds) {
  if (seconds) {
    const d = new Date()
    d.setSeconds(d.getSeconds() - seconds)
    const timeAgo = new TimeAgo('en-US')
    return timeAgo.format(d)
  }
  return ''
}

const guild = computed(() => {
  let data = {}
  if (props.actualGuild?.id) {
    data = { ...props.actualGuild }
    data.countryFlag = props.actualGuild?.country ? getEmojiFlag(props.actualGuild.country) : ''
    data.createdAgo = props.actualGuild?.created ? dateAgo(props.actualGuild.created * 86400) : ''
    data.activeAgo = props.actualGuild?.active ? dateAgo(props.actualGuild.active * 86400) : ''
  }
  return data
})

function saveComment() {
  loadingSaveComment.value = true
  app.$axios
    .post(`${props.apiUrl}/admin/api/overwatcher/comment-guild/save/`, { id: props.actualGuild.id, comment: comment.value })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingSaveComment.value = false
    })
}

function chooseChat(id) {
  chosenChats.value[id] = !chosenChats.value[id]
}

function aiChat() {
  loadingGuildChatAIData.value = true
  app.$axios
    .get(`${props.apiUrl}/admin/api/overwatcher/ai/chat-guild/`, { params: { id: guild.value.id } })
    .then((data) => {
      guildChatAISummary.value = data.data.summary
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingGuildChatAIData.value = false
    })
}
function loadGuild() {
  comment.value = props.actualGuild.comments
  guildChatAISummary.value = false
  lazyInitTabsContent.value = { history: true }
  chosenChats.value = {}
  guildData.value = { ...guildDataStruct }
  if (guild.value?.id && props.apiUrl) {
    loadingGuildData.value = true
    // http://localhost:8097
    app.$axios
      .get(`${props.apiUrl}/admin/api/overwatcher/guild/`, { params: { id: guild.value.id } })
      .then((data) => {
        guildData.value.guildHistory = data.data.data ?? []
        guildData.value.chat = data.data.chat ?? []
        guildData.value.payments = data.data.payments ?? []
        guildData.value.zone = data.data.data.length ? data.data.data[0].Zone : '-'
      })
      .catch(app.$utils.catchError)
      .then(() => {
        loadingGuildData.value = false
      })
  }
}
function onTabsChange(tabName) {
  lazyInitTabsContent.value[tabName] = true
}

function onClose() {
  lazyInitTabsContent.value = {}
  guildData.value = { ...guildDataStruct }
}
</script>

<template>
  <el-drawer
    v-model="showDrawerGuildModel"
    class="guild-drawer"
    size="1200px"
    direction="ltr"
    @open="loadGuild"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full">
        <div class="flex flex-wrap justify-start gap-2">
          <div>
            <icon-ify
              icon="clarity:users-line"
              class="inline-block text-xl mr-1"
            />
            <b>{{ guild.name }}</b>
          </div>
          <div>
            <icon-ify
              icon="ph:hash-light"
              class="inline-block text-xl"
            />
            <b>{{ guild.id }}</b>
          </div>
          <div
            v-if="$utils.countries?.[guild.country]?.name || guild.country"
            class="pr-8 relative"
          >
            <icon-ify
              icon="ph:map-pin-thin"
              class="inline-block text-xl mr-0.5"
            />
            <b>{{ $utils.countries?.[guild.country]?.name || guild.country }}</b>
            <span class="text-2xl absolute -top-1.5 right-0">{{ guild.countryFlag }}</span>
          </div>
        </div>
        <div class="flex flex-wrap justify-start gap-x-4 gap-y-2 mt-2">
          <InfoBox
            title="Wins"
            :value="guildTotalWins"
          />
          <InfoBox
            title="Green Battles"
            :value="greenBattles"
          />
          <InfoBox
            title="Orange Battles"
            :value="orangeBattles"
          />
          <InfoBox
            title="Red Battles"
            :value="redBattles"
          />
        </div>
      </div>
    </template>
    <el-tabs
      v-model="guildData.guildTabModel"
      v-loading="loadingGuildData"
      @tab-change="onTabsChange"
    >
      <el-tab-pane
        v-if="showDrawerGuildModel"
        label="History"
        name="history"
      >
        <template #default>
          <el-radio-group
            v-model="historyGroup"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Season"
              value="Season"
            />
            <el-radio-button
              label="Day"
              value="Day"
            />
          </el-radio-group>
          <table>
            <tr>
              <th>
                <el-tooltip content="Season">
                  Season
                </el-tooltip>
              </th>
              <th v-if="historyGroup === 'Day'">
                <el-tooltip content="Day of season">
                  Day
                </el-tooltip>
              </th>
              <th
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players in guild/opponent guild">
                  Members
                </el-tooltip>
              </th>
              <th
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players that made any action during the day">
                  Active
                </el-tooltip>
              </th>
              <th
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players/opponents that made any action in season day battle (at any time)">
                  Battle
                </el-tooltip>
              </th>
              <th
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players that made any action in during skirmish">
                  Skirmish
                </el-tooltip>
              </th>
              <th>
                <el-tooltip content="Battle score">
                  Win
                </el-tooltip>
              </th>
              <th
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Guild power/opponent guild power">
                  Power
                </el-tooltip>
              </th>
              <th
                v-else
              >
                <el-tooltip content="Green/Orange/Red battles">
                  Battles
                </el-tooltip>
              </th>
              <th>
                <el-tooltip content="Guild score/opponent guild score">
                  G.Score
                </el-tooltip>
              </th>
              <th>
                <el-tooltip content="Division">
                  Division
                </el-tooltip>
              </th>
              <th>Payments</th>
            </tr>
            <tr
              v-for="(row, key) in guildHistory"
              :key="key"
            >
              <td class="p-2">
                {{ row.Season }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.Day }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.MembersPlayers }}/{{ row.OpponentMembersPlayers }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.PlayersActive }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.PlayersActiveBattle }}/{{ row.OpponentPlayersActiveBattle }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.PlayersLiveBattle }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
                :class="{ 'text-green-600': row.Result > row.OpponentResult, 'text-red-600': row.Result < row.OpponentResult }"
              >
                {{ row.Result }}/{{ row.OpponentResult }}
              </td>
              <td
                v-else
                class="p-2"
                :class="{ 'text-green-600': row.WinTotal / row.BattlesTotal > 0.5, 'text-red-600': row.WinTotal / row.BattlesTotal < 0.5 }"
              >
                {{ row.WinTotal }}/{{ row.BattlesTotal }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
                :class="row.GuildPowerDiffColor"
              >
                {{ $utils.numberFormat(Math.round(row.GuildPower / 100) * 100, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentPower / 100) * 100, { shortcuts: true }) }}
              </td>
              <td
                v-else
                class="p-2"
              >
                <span class="text-green-700">{{ row.BattlesTypes[0] }}</span>|<span class="text-orange-400">{{ row.BattlesTypes[1] }}</span>|<span class="text-red-700">{{ row.BattlesTypes[2] }}</span>
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.GuildScore / 1000) * 1000, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentGuildScore / 1000) * 1000, { shortcuts: true }) }}
                </div>
                <div
                  style="height: 3px"
                  class="bg-green-700"
                  :style="{ width: `${row.ScorePercent}%` }"
                />
              </td>
              <td
                v-else
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.ScoreTotal / 1000) * 1000, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentScoreTotal / 1000) * 1000, { shortcuts: true }) }}
                </div>
              </td>
              <td
                class="p-2"
              >
                {{ row.Division }}
              </td>
              <td>
                <el-tooltip v-if="row.TotalPayments">
                  <template #content>
                    <table>
                      <tr>
                        <th>Player</th>
                        <th>Count</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Active</th>
                        <th>Paid total</th>
                      </tr>
                      <tr
                        v-for="p in row.Payments"
                        :key="p.player"
                      >
                        <td
                          v-if="payersMap[p.player]"
                          class="pr-2"
                        >
                          {{ payersMap[p.player].Name }}
                        </td>
                        <td
                          v-else
                          class="pr-2"
                        >
                          ID: {{ p.player }}
                        </td>
                        <td>{{ p.count }}</td>
                        <td class="text-green-700 font-bold pr-2">
                          {{ $utils.numberFormat(p.price) }}
                        </td>
                        <td class="pr-2">
                          <div
                            v-if="payersMap[p.player]"
                            :style="{ color: payersMap[p.player].StatusColor }"
                          >
                            {{ payersMap[p.player].Status }}
                          </div>
                        </td>
                        <td class="pr-2">
                          <div
                            v-if="payersMap[p.player]"
                            :style="{ color: payersMap[p.player].CreatedColor }"
                          >
                            {{ payersMap[p.player].CreatedLabel }} ago
                          </div>
                        </td>
                        <td class="pr-2">
                          <div
                            v-if="payersMap[p.player]"
                            :style="{ color: payersMap[p.player].ActiveColor }"
                          >
                            {{ payersMap[p.player].Active }} days ago
                          </div>
                        </td>
                        <td class="pr-2">
                          <div
                            v-if="payersMap[p.player]"
                            :style="{ color: payersMap[p.player].PaidTotalColor }"
                          >
                            {{ $utils.numberFormat(payersMap[p.player].PaidTotal) }}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </template>
                  {{ row.Payments.length }}p|
                  {{ $utils.numberFormat(row.TotalPayments) }}$
                </el-tooltip>
              </td>
            </tr>
          </table>
        </template>
        <template #label>
          <el-badge
            :value="guildData.guildHistory.length"
            :max="999"
            type="primary"
          >
            History
          </el-badge>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="showDrawerGuildModel"
        label="Chat"
        name="chat"
      >
        <template #default>
          <el-button
            v-show="guildData.chat.length >= 5"
            type="primary"
            size="small"
            :loading="loadingGuildChatAIData"
            @click="aiChat"
          >
            AI summary
          </el-button>
          <div
            v-show="guildChatAISummary"
            class="m-5 font-bold"
            style="white-space: pre-line"
          >
            {{ guildChatAISummary }}
          </div>
          <div
            v-for="(row, key) in guildData.chat"
            :key="key"
            class="m-3 p-2"
            :class="{ 'bg-green-100': chosenChats[row.PlayerID], 'bg-amber-100': !chosenChats[row.PlayerID] }"
            style="font-size: 10px"
            @click="chooseChat(row.PlayerID)"
          >
            <el-row>
              <el-col
                v-if="payersMap[row.PlayerID]"
                :span="12"
                class="font-bold"
              >
                <el-tooltip>
                  <template #content>
                    <table>
                      <tr>
                        <th />
                        <th />
                      </tr>
                      <tr>
                        <td>ID:</td>
                        <td>{{ row.PlayerID }}</td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td :style="{ color: payersMap[row.PlayerID].StatusColor }">
                          {{ payersMap[row.PlayerID].Status }}
                        </td>
                      </tr>
                      <tr>
                        <td>Created:</td>
                        <td :style="{ color: payersMap[row.PlayerID].CreatedColor }">
                          {{ payersMap[row.PlayerID].CreatedLabel }} ago
                        </td>
                      </tr>
                      <tr>
                        <td>Active:</td>
                        <td :style="{ color: payersMap[row.PlayerID].ActiveColor }">
                          {{ payersMap[row.PlayerID].Active }} days ago
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-3">
                          Paid total:
                        </td>
                        <td :style="{ color: payersMap[row.PlayerID].PaidTotalColor }">
                          {{ $utils.numberFormat(payersMap[row.PlayerID].PaidTotal) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-3">
                          Paid before:
                        </td>
                        <td :style="{ color: payersMap[row.PlayerID].PaidBeforeColor }">
                          {{ $utils.numberFormat(payersMap[row.PlayerID].PaidBefore) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-3">
                          Paid now:
                        </td>
                        <td :style="{ color: payersMap[row.PlayerID].PaidNowColor }">
                          {{ $utils.numberFormat(payersMap[row.PlayerID].PaidNow) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-3">
                          Difference:
                        </td>
                        <td :style="{ color: payersMap[row.PlayerID].Difference < 0 ? 'red' : 'green' }">
                          {{ $utils.numberFormat(payersMap[row.PlayerID].Difference) }}
                        </td>
                      </tr>
                    </table>
                  </template>
                  {{ payersMap[row.PlayerID].Name }}
                </el-tooltip>
              </el-col>
              <el-col
                v-else
                :span="12"
                class="text-gray-600"
              >
                Player {{ row.PlayerID }}
              </el-col>
              <el-col
                :span="12"
                class="text-right"
              >
                {{ new TimeAgo('en-US').format(row.Date) }} ({{ dayjs(row.Date).format('YYYY/MM/DD HH:mm') }})
                <el-tooltip
                  v-if="payersMap[row.PlayerID]"
                  content="total spent"
                >
                  <el-tag
                    class="mr-1"
                    effect="plain"
                    size="small"
                    type="info"
                    :color="payersMap[row.PlayerID].PaidTotalColor"
                  >
                    {{ payersMap[row.PlayerID].PaidTotal }}
                  </el-tag>
                </el-tooltip>
                <el-tag
                  v-if="payersMap[row.PlayerID]"
                  class="mr-1"
                  effect="dark"
                  size="small"
                  type="info"
                  :color="payersMap[row.PlayerID].StatusColor"
                >
                  {{ payersMap[row.PlayerID].Status }}
                </el-tag>
              </el-col>
            </el-row>
            <div>{{ row.Message }}</div>
          </div>
        </template>
        <template #label>
          <el-badge
            :value="guildData.chat.length"
            :max="999"
            type="primary"
          >
            Chat
          </el-badge>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="showDrawerGuildModel"
        label="Comment"
        name="comment"
      >
        <template #default>
          <el-input
            v-model="comment"
            autosize
            type="textarea"
            placeholder="Add comment"
          />
          <el-button
            type="primary"
            size="small"
            class="mt-3"
            :loading="loadingSaveComment"
            @click="saveComment"
          >
            save
          </el-button>
        </template>
        <template #label>
          <el-badge
            :value="comment ? 1 : ''"
            type="danger"
          >
            Comment
          </el-badge>
        </template>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<style lang="postcss">
.guild-drawer {
  .el-drawer__header {
    margin-bottom: 5px;
    align-items: flex-start;
  }
}
</style>

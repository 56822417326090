<script setup>
import { computed, defineEmits, defineProps, inject, provide, ref } from 'vue'
import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import { ElMessage } from 'element-plus'

import { globalProperties as app } from '!/plugins/utilities'
import AbResultsTab from '@/components/pages/gameplay/hipo/tabs/HipoAbResultsTab.vue'
import AbGroupTab from '@/components/pages/gameplay/hipo/tabs/HipoAbGroupTab.vue'
import VoutingTab from '@/components/pages/gameplay/hipo/tabs/HipoVoutingTab.vue'
import DefinitionTab from '@/components/pages/gameplay/hipo/tabs/HipoDefinitionTab.vue'
import AbConditionsTab from '@/components/pages/gameplay/hipo/tabs/HipoAbConditionsTab.vue'
import UsageTab from '@/components/pages/gameplay/hipo/tabs/HipoUsageTab.vue'

const props = defineProps({
  actualHipo: {
    type: Object,
    default: undefined
  },
  showDrawerTest: {
    type: Boolean,
    default: false
  },
  apiUrl: {
    type: String,
    default: ''
  },
  apiUrlProd: {
    type: String,
    default: ''
  },
  game: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:showDrawerTest'])

const groupLabel = 'group'

const crudDetails = inject('crudDetails', {})

TimeAgo.addLocale(en)

function initTestDataStruct() {
  return {
    definition: {},
    testTabModel: 'definition',
    zone: '-',
    loading: {}
  }
}

const testData = ref(initTestDataStruct())

const loadingTestData = ref(false)
const approvedBy = ref('')

const showDrawerTestModel = computed({
  get() {
    return props.showDrawerTest
  },
  set(newVal) {
    emit('update:showDrawerTest', newVal)
  }
})

const lazyInitTabsContent = ref({ kpi: true })

function initFormData() {
  return {
    id: null,
    status: 'Idea',
    readyStatus: 'ToApprove',
    title: '',
    description: '',
    closeComments: '',
    priority: 'Normal',
    testType: 'ABTest',
    flag: '',
    dateStart: '',
    dateEnd: '',
    myVoteStatus: '',
    myVoteComment: '',
    abStatus: 'Draft',
    control: 1,
    condition: '',
    segmentPercentage: ['', '', '', ''],
    segmentDescription: ['', '', '', ''],
    segmentPlayers: ['', '', '', ''],
    segmentCondition: ['', '', '', ''],
    segmentScreenShot1: ['', '', '', ''],
    segmentScreenShot2: ['', '', '', ''],
    votes: []
  }
}

const form = ref(initFormData())
const initForm = ref({})
const crudExternalErrors = ref({})
const elFormRef = ref(null)

provide('crudForm', form)
provide('initForm', initForm)
provide('crudExternalErrors', crudExternalErrors)
provide('crudFormRefer', elFormRef)

const saving = ref(false)
const email = app.$store.getters['auth/userData'].Email

function showLogs() {
  if (form.value.id) {
    crudDetails.form.ID = form.value.id
    crudDetails.apiPrefix = `${props.apiUrl}/admin/api/`
    crudDetails.logsDialog = true
  }
}

function updateSegmentsPlayersList(input, group, list) {
  input.split('\n').forEach((el) => {
    const checkEl = String(el).trim()
    if (checkEl) {
      list[group][el] = el
    }
  })
}

function initHipoFormData(data) {
  testData.value.definition = data.data.details ?? {}
  initForm.value.Title = testData.value.definition.Title ?? ''
  form.value.id = testData.value.definition.ID || null
  form.value.condition = testData.value.definition.Conditions ?? ''
  form.value.description = testData.value.definition.Description ?? ''
  form.value.dateStart = testData.value.definition.Start ?? ''
  form.value.dateEnd = testData.value.definition.End ?? ''
  form.value.title = testData.value.definition.Title ?? ''
  form.value.flag = testData.value.definition.Flag ?? ''
  form.value.status = testData.value.definition.Status ?? 'Idea'
  form.value.readyStatus = testData.value.definition.ApproveStatus ?? 'ToApprove'
  form.value.testType = testData.value.definition.TestType ?? 'ABTest'
  form.value.priority = testData.value.definition.Priority ?? 'Normal'
  form.value.abStatus = testData.value.definition.ABStatus ?? 'Draft'
  form.value.control = testData.value.definition.Control ?? 1
  for (let i = 1; i <= 5; i++) {
    form.value[`ScreenShot${i}`] = data.data.details[`ScreenShot${i}`] ?? ''
  }
  form.value.ScreenShot1 = data.data.details.ScreenShot1 ?? ''
  form.value.isFinishedTutorial = testData.value.definition.IsFinishedTutorial ?? false
  approvedBy.value = testData.value.definition.ApprovedBy ?? ''
  initForm.value.segmentsPlayersList = [{}, {}, {}, {}]
  for (let i = 1; i <= 4; i++) {
    const segment = testData.value.definition[`Segment${i}`]
    form.value.segmentDescription[i - 1] = segment.Description
    form.value.segmentPercentage[i - 1] = segment.Percentage ? `${segment.Percentage}` : ''
    form.value.segmentCondition[i - 1] = segment.Condition
    form.value.segmentPlayers[i - 1] = segment.Players || ''
    form.value.segmentScreenShot1[i - 1] = segment.ScreenShot1 || ''
    form.value.segmentScreenShot2[i - 1] = segment.ScreenShot2 || ''
    updateSegmentsPlayersList(form.value.segmentPlayers[i - 1], [i - 1], initForm.value.segmentsPlayersList)
  }
  form.value.votes = []
  for (const vote of data.data?.votes ?? []) {
    if (vote.User === email) {
      form.value.myVoteStatus = vote.Status
      form.value.myVoteComment = vote.Comment
    } else {
      form.value.votes.push(vote)
    }
  }
}

function loadTest() {
  lazyInitTabsContent.value = { kpi: true }
  elFormRef.value?.clearValidate?.()
  if (props.actualHipo?.id && props.apiUrl) {
    loadingTestData.value = true
    // http://localhost:8097
    app.$axios
      .get(`${props.apiUrl}/admin/api/hipo/details/`, { params: { id: props.actualHipo.id } })
      .then((data) => {
        initHipoFormData(data)
      })
      .catch(app.$utils.catchError)
      .then(() => {
        loadingTestData.value = false
      })
  } else {
    initForm.value.segmentsPlayersList = [{}, {}, {}, {}]
  }
}

function saveTest() {
  if (form.value.testType === 'ABTest' && form.value.status === 'Running' && form.value.abStatus === 'Active' && !form.value.dateStart) {
    ElMessage.error('You must provide start date')
    testData.value.testTabModel = 'ab-condition'
    return
  }
  if (form.value.testType !== 'ABTest' && form.value.dateStart === '' && (form.value.status === 'Running' || form.value.status === 'Success' || form.value.status === 'Failure')) {
    ElMessage.error('You must provide start date')
    testData.value.testTabModel = 'definition'
    return
  }
  const formPlayersList = [{}, {}, {}, {}]
  if (form.value.testType === 'ABTest' && form.value.status !== 'Idea' && form.value.status !== 'Voting' && form.value.status !== 'Canceled') {
    for (let i = 1; i <= 4; i++) {
      if (String(form.value.segmentPercentage[i - 1]).trim() === '') {
        continue
      }
      const percentage = Number.parseInt(String(form.value.segmentPercentage[i - 1]).trim())
      if (!percentage || percentage < 0 || percentage > 100) {
        ElMessage.error(`Invalid percentage in ${groupLabel} ${i}`)
        testData.value.testTabModel = `${groupLabel}-${i}`
        return
      }
      if (!String(form.value.segmentDescription[i - 1]).trim()) {
        ElMessage.error(`Missing description in ${groupLabel} ${i}`)
        testData.value.testTabModel = `${groupLabel}-${i}`
        return
      }
      updateSegmentsPlayersList(form.value.segmentPlayers[i - 1] || '', [i - 1], formPlayersList)
    }
  }

  elFormRef.value
    ?.validate?.()
    .then(() => {
      saving.value = true
      const post = { ...form.value }
      post.segments = []
      const allForcedPlayers = {}
      for (let i = 0; i <= 3; i++) {
        post.segments.push({
          percentage: form.value.segmentPercentage[i] ? Number.parseInt(form.value.segmentPercentage[i]) : 0,
          description: form.value.segmentDescription[i],
          condition: form.value.segmentCondition[i],
          players: form.value.segmentPlayers[i],
          screenShot1: form.value.segmentScreenShot1[i],
          screenShot2: form.value.segmentScreenShot2[i]
        })
        Object.keys({ ...formPlayersList[i], ...initForm.value.segmentsPlayersList[i] }).filter((playerId) => {
          if (formPlayersList[i][playerId] !== initForm.value.segmentsPlayersList[i][playerId]) {
            allForcedPlayers[playerId] = playerId
            return true
          }
          return false
        })
      }

      app.$axios
        .post(`${props.apiUrl}/admin/api/hipo/save/`, post)
        .then((data) => {
          ElMessage.success('Saved')
          const allForcedPlayersArr = Object.keys(allForcedPlayers)
          if (allForcedPlayersArr.length) {
            const requestsPlayers = {}
            allForcedPlayersArr.forEach((playerId) => {
              const envPrefix = playerId.slice(0, 3)
              if (envPrefix === 'dev') {
                requestsPlayers[playerId] = { url: props.apiUrl, id: playerId.slice(3) }
              } else if (envPrefix === 'pro') {
                requestsPlayers[playerId] = { url: props.apiUrlProd, id: playerId.slice(4) }
              } else {
                requestsPlayers[`dev${playerId}`] = { url: props.apiUrl, id: playerId }
                requestsPlayers[`prod${playerId}`] = { url: props.apiUrlProd, id: playerId }
              }
            })
            Promise.allSettled(Object.values(requestsPlayers).map((requestData) => {
              return app.$axios.get(`${requestData.url}/dev/resetabgroup/`, { params: { player_id: requestData.id, hipo_id: form.value.id } })
                .catch(app.$utils.catchError)
            })).then(() => {
              saving.value = false
            })
          } else {
            saving.value = false
          }
          if (data?.data?.details?.ID) {
            initHipoFormData(data)
          }
          initForm.value.segmentsPlayersList = [{ ...formPlayersList[0] }, { ...formPlayersList[1] }, { ...formPlayersList[2] }, { ...formPlayersList[3] }]
          initForm.value.Title = form.value.title
        })
        .catch((err) => {
          app.$utils.catchFormErrors(crudExternalErrors)(err)
          saving.value = false
        })
    }).catch((err) => {
      app.$utils.notifyNotAllValid(err)
    })
}

function deleteTest() {
  app.$axios
    .get(`${props.apiUrl}/admin/api/hipo/delete/`, { params: { id: form.value.id } })
    .then(() => {
      ElMessage.success('Deleted')
      showDrawerTestModel.value = false
    })
    .catch(app.$utils.catchError)
    .then(() => {
      saving.value = false
    })
}

function onTabsChange(tabName) {
  lazyInitTabsContent.value[tabName] = true
}

function onClose() {
  lazyInitTabsContent.value = {}
  testData.value = initTestDataStruct()
  form.value = initFormData()
}
</script>

<template>
  <el-drawer
    v-model="showDrawerTestModel"
    class="test-drawer"
    size="90%"
    @open="loadTest"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full">
        <div class="flex flex-wrap justify-start gap-2">
          <div>
            <b v-if="form?.id">{{ actualHipo?.Title || initForm?.Title }}</b>
            <b v-else>New HiPo</b>
          </div>
          <div v-if="form?.id">
            <icon-ify
              icon="ph:hash-light"
              class="inline-block text-xl"
            />
            <b>{{ form.id }}</b>
          </div>
        </div>
        <div
          class="flex justify-end"
        >
          <el-button
            type="primary"
            plain
            :loading="saving"
            @click="saveTest"
          >
            SAVE
          </el-button>
          <el-popconfirm
            v-if="form.id"
            title="Are you sure?"
            @confirm="deleteTest"
          >
            <template #reference>
              <el-button
                type="danger"
                plain
                :loading="saving"
              >
                DELETE
              </el-button>
            </template>
          </el-popconfirm>
          <el-button
            v-if="form.id"
            plain
            @click="showLogs"
          >
            LOGS
          </el-button>
        </div>
      </div>
    </template>
    <el-scrollbar class="max-w-full">
      <el-form
        v-if="showDrawerTestModel"
        ref="elFormRef"
        :model="form"
        label-position="top"
        class="px-1 sm:px-4 max-w-[100vw] sm:max-w-full sm:min-w-[1200px]"
      >
        <el-tabs
          v-model="testData.testTabModel"
          v-loading="loadingTestData"
          @tab-change="onTabsChange"
        >
          <DefinitionTab
            :api-url="props.apiUrl"
            :approved-by="approvedBy"
            :email="email"
          />
          <VoutingTab />
          <AbResultsTab
            :game="game"
            :api-url="props.apiUrl"
            :group-label="groupLabel"
          />
          <AbConditionsTab :group-label="groupLabel" />
          <AbGroupTab :group-label="groupLabel" />
          <UsageTab
            :current-tab="testData.testTabModel"
            :api-url="apiUrl"
          />
        </el-tabs>
      </el-form>
    </el-scrollbar>
  </el-drawer>
</template>

<style lang="postcss">
.test-drawer {
  .el-drawer__header {
    margin-bottom: 5px;
    align-items: flex-start;
  }
  td.docCol1 {
    @apply font-bold pr-3
  }
  td.docCol2 {
    @apply font-light italic
  }
  .hide-tabs {
    .el-tabs__header  .el-tabs__item:not(.is-disabled), .el-tabs__active_bar {
      display: none !important;
    }
    .el-tabs__item.is-disabled {
      cursor: default !important;
      padding-left: 5px !important;
    }
  }
  .grid-slide {
    display: grid;
    grid-template-columns: 0fr;
    transition: grid-template-columns 0.4s ease-in-out;
    white-space: nowrap;
    padding-right: 5px;
  }
  button:hover .grid-slide {
    grid-template-columns: 1fr;
  }
}
</style>

<script>
import { Edit, Warning } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import LogsDialog from '!/components/crud/sub/LogsDialog.vue'
import { gamesEnvs, gamesEnvsProd, gamesOptions } from '@/settings/gamesEnvs.js'
// tmp comment
export default {
  components: { LogsDialog },
  provide() {
    return {
      crudDetails: this.crudDetails
    }
  },
  setup() {
    return {
      gamesEnvs,
      gamesOptions,
      gamesEnvsProd,
      priorityOptions: [
        { value: 'Low', label: 'Low' },
        { value: 'Normal', label: 'Normal' },
        { value: 'High', label: 'High' },
        { value: 'Highest', label: 'Highest' }
      ],
      statusOptions: [
        { value: 'Idea', label: 'Idea' },
        { value: 'Voting', label: 'Voting' },
        { value: 'ToImplement', label: 'To implement' },
        { value: 'Implementing', label: 'Development' },
        { value: 'Ready', label: 'Ready' },
        { value: 'Running', label: 'Running' },
        { value: 'Success', label: 'Success' },
        { value: 'Failure', label: 'Failure' },
        { value: 'Canceled', label: 'Canceled' }
      ],
      approveOptions: [
        { value: 'ToApprove', label: 'To Approve' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Queued', label: 'Queued' }
      ],
      abStatusOptions: [
        { value: 'Draft', label: 'Draft' },
        { value: 'Active', label: 'Active' },
        { value: 'Disabled', label: 'Disabled' },
        { value: 'Archived', label: 'Finished' }
      ],
      Warning,
      Edit,
      dayjs
    }
  },
  data() {
    return {
      apiData: {},
      actualTest: null,
      showDrawerTest: false,
      searchFields: { env: 'snr', smartSearch: '', ab_status: [], priority: [], status: [], approve: [] },
      crudDetails: {
        logsDialog: false,
        metaData: {
          subtitle: 'HiPo'
        },
        apiPrefix: '',
        proxy: false,
        crudEntity: 'game.HiPoEntity',
        form: {
          ID: null
        }
      }
    }
  },
  computed: {},
  watch: {
    showDrawerTest(v) {
      const routeQuery = Object.assign({}, this.$route?.query || {})
      if (v) {
        routeQuery.hipoID = this.actualTest?.id
      } else {
        delete routeQuery.hipoID
      }
      this.$router.replace({
        name: `${this.$route.name}`,
        query: { ...routeQuery }
      })
    }
  },
  mounted() {
    const hipoGameSelect = localStorage.getItem('hipoGameSelect')
    if (hipoGameSelect) {
      this.searchFields.env = hipoGameSelect
    }
    if (this.$route?.query?.hipoID) {
      const id = Number.parseInt(this.$route.query.hipoID)
      if (id) {
        this.showHiPoDrawer({ ID: id })
      }
    }
  },
  methods: {
    onLoadData(response) {
      this.apiData = this.$utils.cloneDeep(response)
      this.apiData.totals = this.apiData.totals ?? []
      this.apiData.totalsApprove = this.apiData.totalsApprove ?? []
      if (this.$route?.query?.c_sea?.status?.length) {
        this.$route.query.c_sea.status.forEach((fStatus) => {
          if (this.apiData.totals[fStatus] === undefined) {
            this.apiData.totals[fStatus] = 0
          }
        })
      }
      if (this.$route?.query?.c_sea?.approve?.length) {
        this.$route.query.c_sea.approve.forEach((fStatus) => {
          if (this.apiData.totalsApprove[fStatus] === undefined) {
            this.apiData.totalsApprove[fStatus] = 0
          }
        })
      }
      if (this.apiData.totals) {
        const orderedStatus = []
        this.statusOptions.forEach((status) => {
          if (this.apiData.totals?.[status.value] !== undefined) {
            orderedStatus.push({ ...status, qty: this.apiData.totals?.[status.value] })
          }
        })
        this.apiData.totals = orderedStatus
      }
      if (this.apiData.totalsApprove) {
        const orderedStatus = []
        this.approveOptions.forEach((status) => {
          if (this.apiData.totalsApprove?.[status.value] !== undefined) {
            orderedStatus.push({ ...status, qty: this.apiData.totalsApprove?.[status.value] })
          }
        })
        this.apiData.totalsApprove = orderedStatus
      }

      const rows = []
      if (this.apiData.rows) {
        for (const row of this.apiData.rows) {
          const data = {
            ID: row.ID,
            Priority: row.Priority,
            Start: row.Start,
            End: row.End,
            Prod: row.Prod,
            TestType: row.TestType,
            Status: row.Status,
            ApproveStatus: row.ReadyStatus,
            Title: row.Title,
            Description: row.Description,
            VotesYes: [],
            VotesMaybe: [],
            VotesNo: [],
            ABStatus: row.ABStatus
          }
          for (const vote of row.Votes) {
            if (vote.Status === 'GreatIdea') {
              data.VotesYes.push(vote.User)
            } else if (vote.Status === 'BadIdea') {
              data.VotesNo.push(vote.User)
            } else if (vote.Status === 'NotSure') {
              data.VotesMaybe.push(vote.User)
            }
          }
          rows.push(data)
        }
      }

      return {
        items: rows,
        total: this.apiData?.total || 0
      }
    },
    showHiPoDrawer(data, ev = null) {
      if (ev?.metaKey || ev?.ctrlKey) {
        return false
      }
      this.actualTest = {
        ...data,
        id: data?.ID
      }
      this.showDrawerTest = true
    },
    rememberEnv() {
      localStorage?.setItem('hipoGameSelect', this.searchFields.env)
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    :proxy-request-params="{
      uri: '/admin/api/hipo/list/',
      method: 'GET',
      env: 'snr',
      envsUrls: gamesEnvs
    }"
    :columns="{
      Priority: 'string',
      CustomID: 'uint',
      Status: 'string',
      Idea: 'string',
      Votes: 'array',
      Duration: 'string'
    }"
    :default-columns-settings="{
      'filterField': false,
      'cellEdit': false,
      'class-name': 'gs-col-padding-mini'
    }"
    :columns-settings="{
      ID: false,
      Priority: { header: ' ', width: '20px' },
      CustomID: { header: 'ID', width: '50px', elAttr: { 'class-name': 'pl-0.5' } },
      Status: { width: '90px' },
      Votes: { width: '110px' },
      Duration: { width: '300px' }
    }"
    :render-loaded-data="onLoadData"
    hide-columns-selector
    hide-clear-btn
    disable-multi-select-rows
    :search-fields="searchFields"
    hide-settings-selector
    hide-pagination-size
  >
    <template #topRight>
      <div class="flex justify-end">
        <el-button
          type="primary"
          class="mr-3"
          @click="showHiPoDrawer(null)"
        >
          ADD
        </el-button>
      </div>
    </template>
    <template #topLeft>
      <div class="flex items-center justify-between gap-4 flex-wrap w-full">
        <div class="flex gap-4 flex-grow">
          <div class="w-32 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.env"
              type-value-field="select"
              full-width
              :options="gamesOptions"
              label="Game"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              :clearable="false"
              @update:value-field="rememberEnv"
            />
          </div>
          <div class="w-48 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.smartSearch"
              full-width
              label="Search"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
            />
          </div>
          <div class="w-32 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.priority"
              type-value-field="select"
              full-width
              :options="priorityOptions"
              label="Priority"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              multiple
            />
          </div>
          <div class="w-32 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.ab_status"
              type-value-field="select"
              full-width
              :options="abStatusOptions"
              label="AB status"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              multiple
            />
          </div>
          <div
            v-if="apiData?.totals?.length"
            class="flex items-center"
          >
            <crud-type-fields
              full-width
              label="Status"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
            >
              <template #default>
                <el-checkbox-group
                  v-model="searchFields.status"
                  class="flex flex-row"
                >
                  <el-checkbox
                    v-for="filterStatus in apiData.totals"
                    :key="filterStatus.value"
                    :label="`${filterStatus.label} (${filterStatus.qty})`"
                    :value="filterStatus.value"
                    class="mr-2"
                    size="small"
                  />
                </el-checkbox-group>
              </template>
            </crud-type-fields>
          </div>
          <div
            v-if="apiData?.totalsApprove?.length"
            class="flex items-center"
          >
            <crud-type-fields
              full-width
              label="Approve"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
            >
              <template #default>
                <el-checkbox-group
                  v-model="searchFields.approve"
                  class="flex flex-row"
                >
                  <el-checkbox
                    v-for="filterStatus in apiData.totalsApprove"
                    :key="filterStatus.value"
                    :label="`${filterStatus.label} (${filterStatus.qty})`"
                    :value="filterStatus.value"
                    class="mr-2"
                    size="small"
                  />
                </el-checkbox-group>
              </template>
            </crud-type-fields>
          </div>
        </div>
        <div
          v-if="apiData?.error"
          class="red"
        >
          {{ apiData.error }}
        </div>
      </div>
    </template>
    <template #cell_Priority="{ row }">
      <el-tooltip
        v-if="row.Status !== 'Idea' && row.Status !== 'Voting'"
        :content="`priority: ${row.Priority}`"
        :show-after="300"
      >
        <div
          class="h-full"
          :class="{
            'bg-gray-500': row.Priority === 'Low',
            'bg-green-800': row.Priority === 'Normal',
            'bg-red-300': row.Priority === 'High',
            'bg-red-700': row.Priority === 'Highest' }"
          style="width: 10px"
        >
          &nbsp;
        </div>
      </el-tooltip>
    </template>
    <template #cell_CustomID="{ row }">
      <button
        :key="`ID_${row.ID}`"
        class="gs-btn-outlined-primary-neutral"
        @click="showHiPoDrawer(row, $event)"
      >
        <router-link :to="`${$route.fullPath}&hipoID=${row.ID}`">
          {{ row.ID }}
        </router-link>
      </button>
    </template>
    <template #cell_Status="{ row }">
      <el-tooltip
        :content="row.Status"
        :show-after="300"
      >
        <icon-ify
          v-if="row.Status === 'Idea'"
          icon="icons8:idea"
          class="inline-block text-blue-600"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Voting'"
          icon="vaadin:thumbs-up-o"
          class="inline-block text-blue-800"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'ToImplement'"
          icon="ion:build-outline"
          class="inline-block text-blue-800"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Implementing'"
          icon="ion:build-outline"
          class="inline-block text-blue-800 animate-spin"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Running'"
          icon="icomoon-free:spinner9"
          class="inline-block text-green-800 animate-spin"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Ready'"
          icon="octicon:rocket-24"
          class="inline-block text-green-800"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Success'"
          icon="ion:thumbs-up"
          class="inline-block text-green-800"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Failure'"
          icon="ion:thumbs-down"
          class="inline-block text-red-800"
          style="width: 17px; height: 17px"
        />
        <icon-ify
          v-else-if="row.Status === 'Canceled'"
          icon="ic:outline-cancel"
          class="inline-block text-gray-500"
          style="width: 17px; height: 17px"
        />
      </el-tooltip>
      <template v-if="row.Status !== 'Idea' && row.Status !== 'Voting'">
        <el-tooltip :show-after="300">
          <template #content>
            {{ row.TestType }}
            <span
              v-if="row.ABStatus"
              class="font-related-xs"
            >
              <b
                :class="{
                  'text-blue-400': row.ABStatus === 'Draft',
                  'text-green-400': row.ABStatus === 'Active',
                  'text-orange-400': row.ABStatus === 'Disabled',
                  'text-neutral-400': row.ABStatus === 'Archived'
                }"
              >({{ row.ABStatus }})</b>
            </span>
          </template>
          <icon-ify
            v-if="row.TestType === 'ABTest'"
            icon="teenyicons:ab-testing-outline"
            class="inline-block  ml-1 border-b-4"
            :class="{
              'text-blue-400 border-blue-400': row.ABStatus === 'Draft',
              'text-green-400 border-green-400': row.ABStatus === 'Active',
              'text-orange-400 border-orange-400': row.ABStatus === 'Disabled',
              'text-neutral-400 border-neutral-400': row.ABStatus === 'Archived'
            }"
            style="width: 17px; height: 17px"
          />
          <icon-ify
            v-else-if="row.TestType === 'Flag'"
            icon="icon-park-outline:flag"
            class="inline-block text-blue-800 ml-1"
            style="width: 17px; height: 17px"
          />
          <icon-ify
            v-else-if="row.TestType === 'Global'"
            icon="uiw:global"
            class="inline-block text-blue-800 ml-1"
            style="width: 17px; height: 17px"
          />
        </el-tooltip>
      </template>
      <template v-if="row.Status === 'Ready'">
        <el-tooltip
          :show-after="300"
          :content="row.ApproveStatus"
        >
          <icon-ify
            v-if="row.ApproveStatus === 'ToApprove'"
            icon="mdi:approve"
            class="inline-block text-red-800 animate-spin"
            style="width: 17px; height: 17px"
          />
          <icon-ify
            v-if="row.ApproveStatus === 'Approved'"
            icon="mdi:approve"
            class="inline-block text-green-800"
            style="width: 17px; height: 17px"
          />
          <icon-ify
            v-if="row.ApproveStatus === 'Queued'"
            icon="vaadin:hourglass"
            class="inline-block text-gray-500"
            style="width: 17px; height: 17px"
          />
        </el-tooltip>
      </template>
    </template>
    <template #cell_Idea="{ row }">
      <el-tooltip
        :show-after="300"
        :content="row.Description"
      >
        {{ row.Title }}
      </el-tooltip><br>
    </template>
    <template #cell_Votes="{ row }">
      <div class="mt-2 flex fle-row w-full gap-2">
        <el-tooltip
          v-if="row.VotesYes.length"
          :show-after="300"
        >
          <template #content>
            <div
              v-for="(user, key) in row.VotesYes"
              :key="key"
            >
              {{ user }}
            </div>
          </template>
          <el-badge
            type="primary"
            :value="row.VotesYes.length"
          >
            <icon-ify
              icon="uiw:smile"
              class="inline-block text-green-800"
              style="width: 17px; height: 17px"
            />
          </el-badge>
        </el-tooltip>
        <el-tooltip
          v-if="row.VotesMaybe.length"
          :show-after="300"
        >
          <template #content>
            <div
              v-for="(user, key) in row.VotesMaybe"
              :key="key"
            >
              {{ user }}
            </div>
          </template>
          <el-badge
            type="primary"
            :value="row.VotesMaybe.length"
          >
            <icon-ify
              icon="emojione-monotone:thinking-face"
              class="inline-block text-gray-700"
              style="width: 17px; height: 17px"
            />
          </el-badge>
        </el-tooltip>
        <el-tooltip
          v-if="row.VotesNo.length"
          :show-after="300"
        >
          <template #content>
            <div
              v-for="(user, key) in row.VotesNo"
              :key="key"
            >
              {{ user }}
            </div>
          </template>
          <el-badge
            type="primary"
            :value="row.VotesNo.length"
          >
            <icon-ify
              icon="rivet-icons:sad-solid"
              class="inline-block text-red-700"
              style="width: 17px; height: 17px"
            />
          </el-badge>
        </el-tooltip>
      </div>
    </template>
    <template #cell_Duration="{ row }">
      <template v-if="row.TestType === 'ABTest' && row.Prod && row.Start">
        <span>
          from {{ dayjs(row.Start).format('DD/MM/YYYY') }}
        </span>
        <span v-if="row.End"> to {{ dayjs(row.End).format('DD/MM/YYYY') }}</span>
        <span v-else> without deadline</span>
      </template>
      <template v-if="row.TestType !== 'ABTest' && row.Start">
        <span>
          from {{ dayjs(row.Start).format('DD/MM/YYYY') }}
        </span>
        <span v-if="row.End"> to {{ dayjs(row.End).format('DD/MM/YYYY') }}</span>
      </template>
    </template>
    <template #cell_action="{ row }">
      <el-tooltip
        content="Edit"
        effect="light"
        placement="top"
        :show-after="600"
      >
        <router-link :to="`${$route.fullPath}&hipoID=${row.ID}`">
          <el-button
            class="gs-height-related-sm font-related-xl px-1 gs-btn-outlined-primary-light ml-0.5"
            tabindex="-1"
            :icon="Edit"
            @click="showHiPoDrawer(row, $event)"
          />
        </router-link>
      </el-tooltip>
    </template>
    <template #drawers="{ refreshData }">
      <HiPoDrawer
        v-model:show-drawer-test="showDrawerTest"
        :actual-hipo="actualTest"
        :game="searchFields.env"
        :api-url="gamesEnvs?.[searchFields?.env] || ''"
        :api-url-prod="gamesEnvsProd?.[searchFields?.env] || ''"
        @update:show-drawer-test="refreshData()"
      />
      <LogsDialog />
    </template>
  </crud-table>
</template>

<script>
import iso from 'iso-3166-1'
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import { isNumber, mergeWith } from 'lodash'
import AmChartWorldMap from '!/components/shared/AmChartWorldMap.vue'

const before = {}
const stats = ['ActiveUsersLast1min', 'ActiveUsersLast24', 'NewUsersLast24h', 'PaidLast24h', 'PaidLast24hStore', 'PayingUsers', 'PayingUsersStore', 'PayingUsersLast24h']
const dollar = 4.05
const dollarOld = 4
const dollarMap = {
  '01/05/2024': 3.96,
  '01/08/2024': 3.87,
  '01/09/2024': 3.87,
  '01/10/2024': 3.95
}

export default {
  name: 'UserStatics',
  components: { AmChartWorldMap },
  props: {
    cols: {
      type: Array,
      default: () => ['Country', 'ActiveUsersLast24', 'ActiveUsersLast1min', 'PayingUsers', 'PayingUsersStore', 'PayingUsersLast24h', 'NewUsersLast24h', 'PaidLast24h', 'PaidLast24hStore']
    },
    sortCol: {
      type: String,
      default: 'ActiveUsersLast24'
    },
    envs: {
      type: Array,
      default: () => ['prod']
    }
  },
  setup() {
    return {
      dollar,
      chart: null,
      chartSeasons: null,
      chartMonths: null,
      chartPayers: null,
      goal: null,
      monthsY: null,
      payerY: null,
      seasonsY: null,
      seasonsX: null,
      goalGames: {
        All: [1864000, '1 864 000'],
        TheGang: [728000, '728 000'],
        SlashRoll: [460000, '460 000'],
        Questland: [118000, '118 000'],
        FableWars: [2000, '2'],
        SoccerClash: [511000, '511 000'],
        StickTogether: [3000, '3'],
        MinersBrawl: [3000, '3'],
        MafiaRivals: [0, '0'],
        HeroLegacy: [0, '0']
      }
    }
  },
  data() {
    return {
      loadingChart: true,
      loadingChartMonths: true,
      loadingChartPayers: true,
      loadingChartSeasons: true,
      stats: [],
      monthStats: [],
      paidAgeStats: [],
      seasonsStats: [],
      tab: 0,
      game: 'All',
      seasonGroup: 'day',
      showMap: false,
      items: [],
      polling: null,
      defSort: { prop: this.sortCol, order: 'descending' },
      envsUrls: {},
      loading: {},
      games: ['All', 'TheGang', 'SlashRoll', 'SoccerClash', 'MafiaRivals', 'Questland', 'FableWars', 'StickTogether', 'MinersBrawl', 'HeroLegacy'],
      gamesShort: ['All', 'TG', 'SR', 'SC', 'MR', 'QL', 'FW', 'ST', 'MB', 'HL']
    }
  },
  computed: {
    chartData() {
      const data = []
      let before = null
      this.stats.forEach((row) => {
        if (this.game !== 'All' && row.game !== this.game) {
          return
        }
        if (this.tab === 1 && row.platform !== 'Android') {
          return
        }
        if (this.tab === 2 && row.platform !== 'Ios') {
          return
        }
        if (before === null || before.date !== row.date) {
          before = {
            date: row.date
          }
          for (const game of this.games) {
            before[game] = 0
            before[`${game}_store`] = 0
          }
          before.soldPercent = 0
          data.push(before)
        }
        before[row.game] += row.total
        before[`${row.game}_store`] += row.store
      })
      for (const i in data) {
        let total = 0
        let totalStore = 0
        for (const game of this.games) {
          total += data[i][game]
          totalStore += data[i][`${game}_store`]
        }
        if (total > 0) {
          data[i].soldPercent = Number.parseInt(Math.round((totalStore / total) * 100))
        }
      }
      return data
    },
    chartDataSeasons() {
      const data = []
      if (this.game === 'All') {
        return data
      }
      let maxSeason = 0
      let currentRow = null
      this.seasonsStats.forEach((row) => {
        if (row.game !== this.game) {
          return
        }
        if (this.seasonGroup === 'day') {
          data.push({
            day: row.d,
            season: row.s,
            value: row.p,
            showBullets: false
          })
        } else if (this.seasonGroup === 'promotions') {
          let key = '1'
          if (row.d > 1 && row.d <= 6) {
            key = '2-6'
          } else if (row.d >= 7 && row.d <= 9) {
            key = 'Promo 7-9'
          } else if (row.d >= 10 && row.d <= 13) {
            key = '10-13'
          } else if (row.d >= 14 && row.d <= 15) {
            key = 'Rush 14-15'
          } else if (row.d >= 16 && row.d <= 20) {
            key = '16-20'
          } else if (row.d >= 21 && row.d <= 23) {
            key = 'Promo 21-23'
          } else if (row.d >= 24 && row.d <= 27) {
            key = '24-27'
          } else if (row.d >= 28 && row.d <= 29) {
            key = 'Rush 28-29'
          } else if (row.d === 30) {
            key = '30'
          }
          if (currentRow === null || currentRow.day !== key) {
            currentRow = {
              day: key,
              season: row.s,
              value: row.p,
              showBullets: false
            }
            data.push(currentRow)
          } else {
            currentRow.value += row.p
          }
        }
        maxSeason = Math.max(maxSeason, row.s)
      })
      const before = {}
      if (maxSeason > 1) {
        data.forEach((row) => {
          if (row.season === maxSeason - 1) {
            before[row.day] = row.value
          } else if (row.season === maxSeason) {
            const previous = before[row.day]
            if (previous > 0) {
              row.showBullets = Math.round(((row.value - previous) / previous) * 100)
            }
          }
        })
      }
      return data
    },
    seasonsFinalStats() {
      const stats = [0, 0]
      let maxSeason = 0
      this.chartDataSeasons.forEach((row) => {
        maxSeason = Math.max(maxSeason, row.season)
      })
      if (maxSeason <= 1) {
        return stats
      }
      const before = {}
      let beforeTotal = 0
      let nowTotal = 0
      this.chartDataSeasons.forEach((row) => {
        if (row.season === maxSeason - 1) {
          before[row.day] = row.value
        } else if (row.season === maxSeason) {
          const previous = before[row.day]
          if (previous > 0) {
            beforeTotal += previous
            nowTotal += row.value
          }
        }
      })
      let diff = 0
      if (beforeTotal > 0) {
        diff = Math.round(((nowTotal - beforeTotal) / beforeTotal) * 100)
      }
      stats[0] = nowTotal - beforeTotal
      stats[1] = diff
      return stats
    },
    chartDataMonths() {
      const data = []
      let before = null
      this.monthStats.forEach((row) => {
        if (this.game !== 'All' && row.game !== this.game) {
          return
        }
        if (this.tab === 1 && row.platform !== 'Android') {
          return
        }
        if (this.tab === 2 && row.platform !== 'Ios') {
          return
        }
        if (before === null || before.date !== row.date) {
          before = {
            date: row.date,
            soldPercent: 0
          }
          for (const game of this.games) {
            before[game] = 0
            before[`${game}_estimate`] = 0
            before[`${game}_store`] = 0
          }
          data.push(before)
        }
        const d = new Date(row.date).toLocaleDateString()
        let currency = dollarMap[d]
        if (currency === undefined) {
          const now = `01${new Date().toLocaleDateString().substring(2)}`
          if (now === d) {
            currency = dollar
          } else {
            currency = dollarOld
          }
        }
        before[row.game] += Math.round(row.paid * currency)
        before[`${row.game}_store`] += Math.round(row.store * currency)
        before[`${row.game}_estimate`] += Math.round(row.estimate * currency)
      })
      for (const row of data) {
        let sold = 0
        let soldStore = 0
        for (const game of this.games) {
          sold += row[game]
          soldStore += row[`${game}_store`]
        }
        if (sold > 0) {
          row.soldPercent = Number.parseInt(Math.round((soldStore / sold) * 100))
        }
      }
      return data
    },
    chartDataPayers() {
      const data = []
      let before = null
      this.stats.forEach((row) => {
        if (this.game !== 'All' && row.game !== this.game) {
          return
        }
        if (this.tab === 1 && row.platform !== 'Android') {
          return
        }
        if (this.tab === 2 && row.platform !== 'Ios') {
          return
        }
        if (before === null || before.date !== row.date) {
          before = {
            date: row.date
          }
          for (const game of this.games) {
            before[game] = 0
          }
          data.push(before)
        }
        before[row.game] += row.payers
      })
      return data
    },
    mapData() {
      const data = {
        maxVal: 1
      }
      const mapping = {}
      this.items.forEach((v) => {
        if (this.game !== 'All' && v.Game !== this.game) {
          return
        }
        if (this.tab === 1 && v.Platform !== 'Android') {
          return
        }
        if (this.tab === 2 && v.Platform !== 'Ios') {
          return
        }
        if (!mapping[v.Country]) {
          mapping[v.Country] = 0
        }
        mapping[v.Country] += v.ActiveUsersLast24
      })
      for (const val in mapping) {
        data[val] = mapping[val]
        if (mapping[val] > data.maxVal) {
          data.maxVal = mapping[val]
        }
      }
      return data
    },
    finalItems() {
      const data = []
      const dataSummary = {
        country: 'Total',
        class: 'font-black'
      }
      for (const code of stats) {
        dataSummary[code] = 0
      }
      const mapping = {}
      this.items.forEach((v) => {
        if (this.game !== 'All' && v.Game !== this.game) {
          return
        }
        if (this.tab === 1 && v.Platform !== 'Android') {
          return
        }
        if (this.tab === 2 && v.Platform !== 'Ios') {
          return
        }
        const countryData = iso.whereAlpha2(v.Country)
        let name = countryData === undefined ? v.Country : countryData.country
        if (name === 'United Kingdom of Great Britain and Northern Ireland') {
          name = 'United Kingdom'
        }
        let row = mapping[v.Country]
        if (!row) {
          row = { country: name }
          for (const code of stats) {
            row[code] = 0
          }
          mapping[v.Country] = row
          data.push(row)
        }
        for (const code of stats) {
          row[code] += v[code]
          dataSummary[code] += v[code]
          if (before[v.Country] !== undefined) {
            const diff = row[code] - before[v.Country][code]
            row[`${code}New`] += diff
            dataSummary[`${code}New`] += diff
          }
        }
      })
      data.push(dataSummary)
      return data.sort((a, b) => {
        if (this.defSort.order === 'descending') {
          return b?.[this.defSort.prop] - a?.[this.defSort.prop]
        } else {
          return a?.[this.defSort.prop] - b?.[this.defSort.prop]
        }
      })
    }
  },
  created() {
    this.pollData()
  },
  mounted() {
    this.$axios.get(`/admin/api/mainpage/globalstats-history/`).then((data) => {
      this.stats = data.data
      this.initChart()
      this.initChartPayers()
      this.loadingChart = false
      this.loadingChartPayers = false
    })
    this.$axios.get(`/admin/api/mainpage/globalstats-months/`).then((data) => {
      this.monthStats = data.data
      this.initChartMonths()
      this.loadingChartMonths = false
    })
    this.$axios.get(`/admin/api/mainpage/globalstats-seasons/`).then((data) => {
      this.seasonsStats = data.data
      this.initChartSeasons()
      this.loadingChartSeasons = false
    })
  },
  beforeUnmount() {
    this.$store.commit('auth/userLocalSettings', { ...this.$store.getters['auth/userLocalSettings'], dash: { tab: this.tab, showMap: this.showMap } })
    clearInterval(this.polling)
  },
  methods: {
    loadChartData() {
      this.$axios.get(`/admin/api/mainpage/globalstats-history/`).then((data) => {
        this.stats = data.data
        this.updateChart()
        this.updateChartPayers()
      })
      this.$axios.get(`/admin/api/mainpage/globalstats-months/`).then((data) => {
        this.monthStats = data.data
        this.updateChartMonths()
      })
      this.$axios.get(`/admin/api/mainpage/globalstats-seasons/`).then((data) => {
        this.seasonsStats = data.data
        this.updateChartSeasons()
      })
    },
    updateChart() {
      for (const x of this.chart.series.values) {
        x.data.setAll(this.chartData)
      }
    },
    updateChartSeasons() {
      for (const x of this.chartSeasons.series.values) {
        x.data.setAll(this.chartDataSeasons)
      }
      let maxSeason = 0
      let minSeason = 0
      if (this.chartDataSeasons.length > 0) {
        minSeason = this.chartDataSeasons[0].season
        maxSeason = this.chartDataSeasons[this.chartDataSeasons.length - 1].season
      }
      const data = []
      let max = 7
      for (let i = maxSeason; i >= minSeason; i--) {
        if (!max) {
          break
        }
        max--
        data.push({ season: i })
      }
      this.seasonsY.data.setAll(data)

      if (this.seasonGroup === 'day') {
        const days = []
        for (let i = 1; i <= 30; i++) {
          days.push({ day: i })
        }
        this.seasonsX.data.setAll(days)
      } else if (this.seasonGroup === 'promotions') {
        const days = [
          { day: '1' },
          { day: '2-6' },
          { day: 'Promo 7-9' },
          { day: '10-13' },
          { day: 'Rush 14-15' },
          { day: '16-20' },
          { day: 'Promo 21-23' },
          { day: '24-27' },
          { day: 'Rush 28-29' },
          { day: '30' }
        ]
        this.seasonsX.data.setAll(days)
      }
    },
    updateChartMonths() {
      for (const x of this.chartMonths.series.values) {
        x.data.setAll(this.chartDataMonths)
      }
      const goalFinal = this.goalGames[this.game]
      this.goal.set('value', goalFinal[0])
      this.goal.set('text', goalFinal[1])

      this.goal.get('label').setAll({
        text: `Goal ${goalFinal[1]} PLN`
      })
      let totalMax = 0
      for (const row of this.chartDataMonths) {
        let total = 0
        if (this.game === 'All') {
          for (const name of this.games) {
            total += row[name] + row[`${name}_estimate`]
          }
        } else {
          total += row[this.game] + row[`${this.game}_estimate`]
        }
        totalMax = Math.max(totalMax, total)
      }
      const max = Math.max(goalFinal[0] + goalFinal[0] / 8, totalMax + totalMax / 8)
      this.monthsY.setAll({
        max
      })
    },
    updateChartPayers() {
      for (const x of this.chartPayers.series.values) {
        x.data.setAll(this.chartDataPayers)
      }
      let min = 0
      if (this.game !== 'All') {
        min = 999999999
        for (const row of this.chartDataPayers) {
          min = Math.min(min, row[this.game])
        }
      }
      this.payerY.setAll({
        min
      })
    },
    initChart() {
      const root = am5.Root.new('historyChart')

      root.setThemes([
        am5themes_Animated.new(root)
      ])
      root.numberFormatter.set('numberFormat', '#')

      this.chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout
      }))
      this.chart.chartContainer.wheelable = false

      const xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        forceHidden: true,
        tooltip: am5.Tooltip.new(root, {})
      }))

      const yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        numberFormat: '#\'$\'',
        extraMax: 0.1,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }))
      const yRenderer = yAxis.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 9
      })

      yAxis.set('tooltip', am5.Tooltip.new(root, {}))

      let goal = 0
      this.chartData.forEach((v) => {
        let total = 0
        for (const game of this.games) {
          total += v[game]
        }
        if (total > goal) {
          goal = total
        }
      })

      const goalRange = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: goal
        })
      )

      goalRange.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 1,
        strokeWidth: 3,
        stroke: am5.Color.fromString('#67c23a')
      })

      const goalLabel = goalRange.get('label')

      goalLabel.setAll({
        centerY: am5.p100,
        centerX: am5.p100,
        text: 'Record',
        fontWeight: 'bold',
        fill: am5.Color.fromString('#67c23a')
      })

      goalLabel.adapters.add('x', () => {
        return 45
      })
      this.chart.plotContainer.onPrivate('width', () => {
        goalLabel.markDirtyPosition()
      })

      const makeSeries = (name, showLabel) => {
        const series = this.chart.series.push(am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis,
          yAxis,
          valueYField: name,
          valueXField: 'date',
          maskBullets: false
        }))

        series.columns.template.setAll({
          tooltipText: '{name}\n{valueX.formatDate()}: [bold]{valueY}[/]$',
          tooltipY: am5.percent(10)
        })

        series.data.setAll(this.chartData)

        if (showLabel) {
          series.bullets.push(() => {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: '{valueYTotal}$',
                fontSize: 10,
                fill: am5.color(0x000000),
                centerY: am5.p100,
                centerX: am5.p50,
                populateText: true
              })
            })
          })
        }
        series.appear()
      }

      for (const game of this.games) {
        if (game === 'All') {
          continue
        }
        makeSeries(game, false)
      }
      makeSeries('All', true)

      // Start
      const paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true })
      paretoAxisRenderer.labels.template.setAll({
        fontSize: 9
      })
      const paretoAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
        min: 0,
        max: 100,
        strictMinMax: true
      }))

      paretoAxisRenderer.grid.template.set('forceHidden', true)
      paretoAxis.set('numberFormat', '#\'%')
      // End

      const paretoSeries = this.chart.series.push(am5xy.LineSeries.new(root, {
        name: 'soldPercent',
        xAxis,
        yAxis: paretoAxis,
        valueYField: 'soldPercent',
        valueXField: 'date',
        stroke: root.interfaceColors.get('alternativeBackground'),
        maskBullets: false
      }))
      paretoSeries.strokes.template.setAll({
        strokeWidth: 1,
        templateField: 'strokeSettings'
      })
      paretoSeries.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 1,
            stroke: paretoSeries.get('stroke'),
            radius: 2,
            fill: root.interfaceColors.get('background')
          }),
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '{name} in {categoryX}: {valueY} {info}'
          })
        })
      })
      paretoSeries.bullets.push(() => {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: '{valueY}%',
            fontSize: 9,
            fill: am5.color(0x000000),
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true
          })
        })
      })

      paretoSeries.data.setAll(this.chartData)
      paretoSeries.appear()

      this.chart.appear(1000, 100)
    },
    initChartSeasons() {
      const root = am5.Root.new('seasonsChart')
      root.setThemes([
        am5themes_Animated.new(root)
      ])
      root.numberFormatter.set('numberFormat', '#')
      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
        layout: root.verticalLayout
      }))
      this.chartSeasons = chart

      const yRenderer = am5xy.AxisRendererY.new(root, {
        visible: false,
        minGridDistance: 20,
        inversed: true,
        minorGridEnabled: true
      })

      yRenderer.grid.template.set('visible', false)

      const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        renderer: yRenderer,
        categoryField: 'season'
      }))
      this.seasonsY = yAxis

      const xRenderer = am5xy.AxisRendererX.new(root, {
        visible: false,
        minGridDistance: 30,
        opposite: true,
        minorGridEnabled: true
      })

      xRenderer.grid.template.set('visible', false)

      const xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: 'day'
      }))
      this.seasonsX = xAxis

      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        calculateAggregates: true,
        stroke: am5.color(0xFFFFFF),
        clustered: false,
        xAxis,
        yAxis,
        categoryXField: 'day',
        categoryYField: 'season',
        valueField: 'value'
      }))

      series.columns.template.setAll({
        tooltipText: '{value}$',
        strokeOpacity: 1,
        strokeWidth: 2,
        width: am5.percent(100),
        height: am5.percent(100)
      })

      series.set('heatRules', [{
        target: series.columns.template,
        min: am5.color(0xFFFB77),
        max: am5.color(0x20631B),
        dataField: 'value',
        key: 'fill'
      }])

      // START
      series.bullets.push((root, series, dataItem) => {
        if (dataItem.dataContext.showBullets !== false) {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              fill: dataItem.dataContext.showBullets >= 0 ? am5.color(0x053801) : am5.color(0xCF1D1D),
              populateText: true,
              centerX: am5.p50,
              centerY: am5.p50,
              fontSize: 10,
              text: `${(dataItem.dataContext.showBullets > 0 ? '+' : '') + dataItem.dataContext.showBullets}%`
            })
          })
        }
      })
      // END

      series.data.setAll(this.chartDataSeasons)

      yAxis.data.setAll([])

      const days = []
      for (let i = 1; i <= 30; i++) {
        days.push({ day: i })
      }
      xAxis.data.setAll(days)

      chart.appear(1000, 100)
    },
    initChartMonths() {
      const root = am5.Root.new('monthsChart')

      root.setThemes([
        am5themes_Animated.new(root)
      ])
      root.numberFormatter.set('numberFormat', '#,###')

      this.chartMonths = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout
      }))
      this.chartMonths.chartContainer.wheelable = false

      const xAxis = this.chartMonths.xAxes.push(am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'month',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        forceHidden: true,
        tooltip: am5.Tooltip.new(root, {})
      }))

      let totalMax = 0
      for (const row of this.chartDataMonths) {
        let total = 0
        for (const name of this.games) {
          total += row[name] + row[`${name}_estimate`]
        }
        totalMax = Math.max(totalMax, total)
      }
      const goalFinal = this.goalGames[this.game]
      const max = Math.max(goalFinal[0] + goalFinal[0] / 8, totalMax + totalMax / 8)
      const yAxis = this.chartMonths.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        max,
        strictMinMax: true,
        numberFormat: '#,###\' PLN\'',
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }))
      this.monthsY = yAxis
      const yRenderer = yAxis.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 9,
        fill: am5.Color.fromString('#ffffff')
      })

      yAxis.set('tooltip', am5.Tooltip.new(root, {}))

      const paretoAxisRenderer = am5xy.AxisRendererY.new(root, { opposite: true })
      paretoAxisRenderer.labels.template.setAll({
        fontSize: 9
      })
      const paretoAxis = this.chartMonths.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: paretoAxisRenderer,
        min: 0,
        max: 100,
        strictMinMax: true
      }))

      paretoAxisRenderer.grid.template.set('forceHidden', true)
      paretoAxis.set('numberFormat', '#\'%')

      const goalRange = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: goalFinal[0]
        })
      )
      this.goal = goalRange

      goalRange.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 1,
        strokeWidth: 3,
        stroke: am5.Color.fromString('#a6161f')
      })

      const goalLabel = goalRange.get('label')

      goalLabel.setAll({
        centerY: am5.p100,
        centerX: am5.p100,
        text: `Goal ${goalFinal[1]} PLN`,
        fontWeight: 'bold',
        fill: am5.Color.fromString('#a6161f')
      })

      goalLabel.adapters.add('x', () => {
        return 45
      })
      this.chartMonths.plotContainer.onPrivate('width', () => {
        goalLabel.markDirtyPosition()
      })

      const makeSeries = (name, showLabel, key) => {
        const series = this.chartMonths.series.push(am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis,
          yAxis,
          valueYField: key,
          valueXField: 'date',
          maskBullets: false
        }))

        series.columns.template.setAll({
          tooltipText: '{name}\n{valueX.formatDate()}: [bold]{valueY}[/] PLN',
          tooltipY: am5.percent(10)
        })
        series.data.setAll(this.chartDataMonths)

        if (showLabel) {
          series.bullets.push(() => {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: '{valueYTotal} PLN',
                fontSize: 10,
                fill: am5.color(0x000000),
                centerY: am5.p100,
                centerX: am5.p50,
                populateText: true
              })
            })
          })
        }
        series.appear()
      }

      for (const game of this.games) {
        if (game === 'All') {
          continue
        }
        makeSeries(game, false, game)
        makeSeries(`${game} estimation`, false, `${game}_estimate`)
      }
      makeSeries('All', true, 'All')

      const paretoSeries = this.chartMonths.series.push(am5xy.LineSeries.new(root, {
        name: 'soldPercent',
        xAxis,
        yAxis: paretoAxis,
        valueYField: 'soldPercent',
        valueXField: 'date',
        stroke: root.interfaceColors.get('alternativeBackground'),
        maskBullets: false
      }))
      paretoSeries.strokes.template.setAll({
        strokeWidth: 3,
        templateField: 'strokeSettings'
      })
      paretoSeries.bullets.push(() => {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 1,
            stroke: paretoSeries.get('stroke'),
            radius: 2,
            fill: root.interfaceColors.get('background')
          }),
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '{name} in {categoryX}: {valueY} {info}'
          })
        })
      })
      paretoSeries.bullets.push(() => {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: '{valueY}%',
            fontSize: 9,
            fill: am5.color(0x000000),
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true
          })
        })
      })

      paretoSeries.data.setAll(this.chartDataMonths)
      paretoSeries.appear()

      this.chartMonths.appear(1000, 100)
    },
    initChartPayers() {
      const root = am5.Root.new('payersChart')

      root.setThemes([
        am5themes_Animated.new(root)
      ])
      root.numberFormatter.set('numberFormat', '#')

      this.chartPayers = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        layout: root.verticalLayout
      }))
      this.chartPayers.chartContainer.wheelable = false

      const xAxis = this.chartPayers.xAxes.push(am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        forceHidden: true,
        tooltip: am5.Tooltip.new(root, {})
      }))

      const yAxis = this.chartPayers.yAxes.push(am5xy.ValueAxis.new(root, {
        numberFormat: '#',
        min: 0,
        strictMinMax: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      }))
      this.payerY = yAxis
      const yRenderer = yAxis.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 9
      })

      yAxis.set('tooltip', am5.Tooltip.new(root, {}))

      let goal = 0
      this.chartDataPayers.forEach((v) => {
        let total = 0
        for (const game of this.games) {
          total += v[game]
        }
        if (total > goal) {
          goal = total
        }
      })

      const goalRange = yAxis.createAxisRange(
        yAxis.makeDataItem({
          value: goal
        })
      )

      goalRange.get('grid').setAll({
        forceHidden: false,
        strokeOpacity: 1,
        strokeWidth: 3,
        stroke: am5.Color.fromString('#67c23a')
      })

      const goalLabel = goalRange.get('label')

      goalLabel.setAll({
        centerY: am5.p100,
        centerX: am5.p100,
        text: 'Record',
        fontWeight: 'bold',
        fill: am5.Color.fromString('#67c23a')
      })

      goalLabel.adapters.add('x', () => {
        return 45
      })
      this.chartPayers.plotContainer.onPrivate('width', () => {
        goalLabel.markDirtyPosition()
      })

      const makeSeries = (name) => {
        const series = this.chartPayers.series.push(am5xy.ColumnSeries.new(root, {
          name,
          stacked: true,
          xAxis,
          yAxis,
          valueYField: name,
          valueXField: 'date',
          maskBullets: false
        }))

        series.columns.template.setAll({
          tooltipText: '{name}\n{valueX.formatDate()}: [bold]{valueY}[/]',
          tooltipY: am5.percent(10)
        })

        series.data.setAll(this.chartDataPayers)
        series.appear()
      }

      for (const game of this.games) {
        if (game === 'All') {
          continue
        }
        makeSeries(game, false)
      }
      this.chartPayers.appear(1000, 100)
    },
    pollData() {
      this.loadData()
      this.polling = setInterval(() => {
        this.loadData(false)
        this.loadChartData()
      }, 5000)
    },
    loadData(showLoader = true) {
      if (showLoader) {
        this.loading.allData = true
      }
      this.items.forEach((v) => {
        if (!before[v.Country]) {
          before[v.Country] = {}
          for (const code of stats) {
            before[v.Country][code] = 0
          }
        }
        for (const code of stats) {
          before[v.Country] += Number.parseInt(v[code])
        }
      })
      const requests = []
      requests.push(this.$axios.get(`/admin/api/mainpage/globalstats-all/`))
      Promise.allSettled(requests).then((results) => {
        const data = []
        results.forEach((result) => {
          if (result.status === 'rejected') {
            this.$message({
              message: result?.reason || 'connection failed',
              type: 'warning',
              offset: 40
            })
          } else {
            result.value.data.forEach((el) => {
              data.push(el)
            })
          }
        })
        this.items = Object.values(data)
        if (showLoader) {
          this.loading.allData = false
        }
      })
    },
    changeTab(tab) {
      this.tab = tab
      this.updateChart()
      this.updateChartMonths()
      this.updateChartPayers()
    },
    changeGame(game) {
      this.game = game
      this.updateChart()
      this.updateChartMonths()
      this.updateChartPayers()
      this.updateChartSeasons()
    },
    changeSeasonGroup(group) {
      this.seasonGroup = group
      this.updateChartSeasons()
    },
    onSortChange(ev) {
      if (ev.order === null) {
        this.defSort = { prop: this.sortCol, order: 'descending' }
      } else {
        this.defSort.prop = ev.prop
        this.defSort.order = ev.order
      }
    },
    mergeWithSum: (arr1, arr2) => {
      return mergeWith({}, arr1, arr2, (objValue, srcValue) => {
        if (isNumber(srcValue) && isNumber(objValue)) {
          return srcValue + objValue
        }
        return undefined
      })
    }
  }
}
</script>

<template>
  <div
    class="gs-related-py-lg gs-related-px-lg"
    style="min-width: 1000px"
  >
    <div>
      <el-button-group class="ml-4">
        <el-button
          :class="[tab === 0 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(0)"
        >
          <icon-ify
            icon="mdi:earth"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
        <el-button
          :class="[tab === 1 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(1)"
        >
          <icon-ify
            icon="material-symbols:android"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
        <el-button
          :class="[tab === 2 ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeTab(2)"
        >
          <icon-ify
            icon="mdi:apple"
            class="gs-scaled-icon-md text-black"
          />
        </el-button>
      </el-button-group>
      <el-button-group class="ml-4">
        <el-tooltip
          v-for="(gameName, k) in games"
          :key="gameName"
          :content="gameName"
        >
          <template #default>
            <el-button
              :class="[game === gameName ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
              @click="changeGame(gameName)"
            >
              {{ gamesShort[k] }}
            </el-button>
          </template>
        </el-tooltip>
      </el-button-group>
      <el-button
        class="ml-8"
        :class="[showMap ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
        @click="showMap = !showMap"
      >
        <icon-ify
          icon="mdi:map-marker"
          class="gs-scaled-icon-md text-black"
        />
      </el-button>
      <span
        v-show="seasonsFinalStats[0]"
        class="ml-5 text-sm"
      >
        Season
        <span
          v-if="seasonsFinalStats[0] > 0"
          class="text-green-800"
        >{{ Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumFractionDigits: 0 }).format(seasonsFinalStats[0] * dollar) }} more ({{ seasonsFinalStats[1] }}%)</span>
        <span
          v-if="seasonsFinalStats[0] < 0"
          class="text-red-700"
        >{{ Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN', maximumFractionDigits: 0 }).format(seasonsFinalStats[0] * -dollar) }} less ({{ seasonsFinalStats[1] }}%)</span>
      </span>
    </div>
    <div
      id="historyChart"
      v-loading="loadingChart"
      class="gs-related-py-lg gs-related-px-lg"
      style="height: 200px"
    />
    <div
      id="monthsChart"
      v-loading="loadingChartMonths"
      class="gs-related-py-lg gs-related-px-lg"
      style="height: 200px"
    />
    <div v-show="game === 'TheGang' || game === 'SlashRoll' || game === 'SoccerClash' || game === 'StickTogether' || game === 'MinersBrawl' || game === 'MafiaRivals'">
      <el-button-group
        size="small"
        class="mt-10"
      >
        <el-button
          :class="[seasonGroup === 'day' ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeSeasonGroup('day')"
        >
          day
        </el-button>
        <el-button
          :class="[seasonGroup === 'promotions' ? 'bg-sky-50' : 'bg-neutral-50 focus:border-neutral-200']"
          @click="changeSeasonGroup('promotions')"
        >
          promotions
        </el-button>
      </el-button-group>
      <div
        id="seasonsChart"
        v-loading="loadingChartSeasons"
        style="height: 260px"
      />
    </div>
    <div
      id="payersChart"
      v-loading="loadingChartPayers"
      class="gs-related-py-lg gs-related-px-lg"
      style="height: 200px"
    />
    <el-collapse-transition>
      <div
        v-if="showMap"
        v-loading="!!loading.allData"
        class="gs-related-py-lg gs-related-px-lg"
      >
        <AmChartWorldMap
          class="h-[600px] min-h-[80vh] w-full"
          :map-data="mapData"
        />
      </div>
    </el-collapse-transition>
    <div class="gs-related-py-lg gs-related-px-lg">
      <transition
        name="el-fade-in"
        mode="out-in"
      >
        <div v-if="tab >= 0">
          <el-table
            v-loading="!!loading.allData"
            :data="finalItems"
            class="gs-scaled gs-v-loading"
            :style="[{ fontSize: $store.getters['auth/userScaledFontSize'] }]"
            style="min-width: 100%"
            :height="$windowHeight * 0.85"
            header-cell-class-name="border-b-4 border-neutral-50 px-0 gs-cell-padding-mini"
            :default-sort="defSort"
            size="small"
            @sort-change="onSortChange"
          >
            <template
              v-for="col in cols"
              :key="col"
            >
              <!-- Country -->
              <el-table-column
                v-if="col === 'Country'"
                prop="country"
                label="Country"
                class-name="whitespace-nowrap"
                :min-width="200"
                fixed
              >
                <template #default="{ row }">
                  <span
                    :class="row.class"
                    class="whitespace-nowrap"
                  >{{ row.country || 'Unknown' }}</span>
                </template>
              </el-table-column>
              <!-- ActiveUsersLast24 -->
              <el-table-column
                v-if="col === 'ActiveUsersLast24'"
                prop="ActiveUsersLast24"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Active Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.ActiveUsersLast24.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.ActiveUsersLast24New && row.ActiveUsersLast24"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.ActiveUsersLast24New > 0 ? 'success' : 'danger'"
                  >
                    {{ row.ActiveUsersLast24New }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- ActiveUsersLast1min -->
              <el-table-column
                v-if="col === 'ActiveUsersLast1min'"
                prop="ActiveUsersLast1min"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Active Users last 1min
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.ActiveUsersLast1min.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.ActiveUsersLast1minNew && row.ActiveUsersLast1min"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.ActiveUsersLast1minNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.ActiveUsersLast1minNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PayingUsers -->
              <el-table-column
                v-if="col === 'PayingUsers'"
                prop="PayingUsers"
                :min-width="40 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paying <br>Users
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PayingUsers.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PayingUsersNew && row.PayingUsers"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PayingUsersNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PayingUsersNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PayingUsersStore -->
              <el-table-column
                v-if="col === 'PayingUsersStore'"
                prop="PayingUsersStore"
                :min-width="40 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paying <br>Users Store
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >
                    {{ row.PayingUsersStore.toLocaleString('pl-PL') }}
                    <span v-if="row.PayingUsers && row.PayingUsersStore">
                      ({{ Math.round(row.PayingUsersStore / row.PayingUsers * 100, 1) }}%)
                    </span>
                  </span>
                  <el-tag
                    v-if="row.PayingUsersNew && row.PayingUsers"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PayingUsersStoreNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PayingUsersStoreNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PayingUsersLast24h -->
              <el-table-column
                v-if="col === 'PayingUsersLast24h'"
                prop="PayingUsersLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paying Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PayingUsersLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PayingUsersLast24hNew && row.PayingUsersLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PayingUsersLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PayingUsersLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- NewUsersLast24h -->
              <el-table-column
                v-if="col === 'NewUsersLast24h'"
                prop="NewUsersLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    New Users last 24h
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.NewUsersLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.NewUsersLast24hNew && row.NewUsersLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.NewUsersLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.NewUsersLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <!-- PaidLast24h -->
              <el-table-column
                v-if="col === 'PaidLast24h'"
                prop="PaidLast24h"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paid last<br>24h($)
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PaidLast24h.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PaidLast24hNew && row.PaidLast24h"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PaidLast24hNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PaidLast24hNew }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                v-if="col === 'PaidLast24hStore'"
                prop="PaidLast24hStore"
                :min-width="50 + 40 * $store.getters['auth/userScaledRatio']"
                sortable
                align="center"
              >
                <template #header>
                  <div class="inline-block max-w-[70%] break-normal leading-tight">
                    Paid Store last<br>24h($)
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    class="mr-1.5"
                    :class="row.class"
                  >{{ row.PaidLast24hStore.toLocaleString('pl-PL') }}</span>
                  <el-tag
                    v-if="row.PaidLast24hStoreNew && row.PaidLast24hStore"
                    effect="dark"
                    round
                    size="small"
                    class="gs-font-scaled"
                    :type="row.PaidLast24hStoreNew > 0 ? 'success' : 'danger'"
                  >
                    {{ row.PaidLast24hStoreNew }}
                  </el-tag>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="postcss"></style>

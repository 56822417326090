<script>
export default {
  name: 'PayersTooltip',
  props: {
    payers: {},
    showPlayerDrawer: {}
  }
}
</script>

<template>
  <div class="m-2">
    <table>
      <tr>
        <th>Name</th>
        <th>Total</th>
        <th>Before</th>
        <th>Now</th>
        <th>Impact</th>
      </tr>
      <tr
        v-for="p in payers"
        :key="p.ID"
      >
        <td>
          <span
            class="cursor-pointer"
            @click="showPlayerDrawer(p)"
          >{{ p.Name }}</span>
        </td>
        <td>{{ p.PaidTotal }}</td>
        <td>{{ p.PaidBefore }}</td>
        <td>{{ p.PaidNow }}</td>
        <td :class="p.Difference < 0 ? 'red' : 'green'">
          {{ p.Difference }}
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>

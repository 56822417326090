<script setup>
import { Delete } from '@element-plus/icons-vue'
import { computed, defineEmits, defineProps, ref } from 'vue'
import HipoQueryTooltip from '@/components/pages/gameplay/hipo/HipoQueryTooltip.vue'
import { globalProperties as app } from '!/plugins/utilities.js'
import CrudForm from '!/components/forms/CrudForm.vue'

const props = defineProps({
  showQueryDrawer: {
    type: Boolean,
    default: false
  },
  game: {
    type: String,
    default: ''
  },
  apiUrl: {
    type: String,
    default: ''
  },
  query: {
    type: Object,
    default: () => {}
  },
  queryList: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(['update:showQueryDrawer', 'refresh'])

const showQueryDrawerModel = computed({
  get() {
    return props.showQueryDrawer
  },
  set(newVal) {
    emit('update:showQueryDrawer', newVal)
  }
})

function initialState() {
  return {
    form: {
      id: 0,
      title: '',
      query: '',
      segment: 0
    },
    externalErrors: {},
    loading: {}
  }
}

const state = ref(initialState())

function onOpen() {
  state.value.form = {
    id: props.query?.ID ?? 0,
    title: props.query?.Title ?? '',
    query: props.query?.Query ?? ''
  }
}
function onClose() {
  state.value = initialState()
}

const uniqueQueriesTitles = computed(() => {
  return Object.fromEntries(Object.values(props.queryList ?? {}).map((segment) => {
    return [segment.Title, segment.ID]
  }))
})

function saveQueryData(formRef) {
  if (!formRef) {
    return
  }
  state.value.loading.savingQuery = true
  formRef.validate()
    .then(() => {
      app.$axios
        .post(`/admin/api/hipo/test-query/`, state.value.form)
        .then(() => {
          emit('refresh', {
            settings: {
              queryId: state.value.form.id || 0,
              queryTitle: state.value.form.title,
              removedQuery: false
            },
            callBack: () => {
              app.$notify({
                type: 'success',
                customClass: 'bg-teal-50 text-green-600 child-inherit-colors',
                message: `${state.value.form.id ? 'updated' : 'added new'} query`
              })
              state.value.loading.savingQuery = false
              showQueryDrawerModel.value = false
            }
          })
        })
        .catch((err) => {
          app.$utils.catchFormErrors(state.value.externalErrors)(err)
          state.value.loading.savingQuery = false
        })
    })
    .catch((err) => {
      state.value.loading.savingQuery = false
      app.$utils.notifyNotAllValid(err)
    })
}
function deleteQuery() {
  state.value.loading.deleteQuery = true
  const data = { ...state.value.form }
  data.query = ''
  app.$axios
    .post(`/admin/api/hipo/test-query/`, data)
    .then(() => {
      emit('refresh', {
        settings: {
          queryId: state.value.form.id || 0,
          queryTitle: state.value.form.title,
          removedQuery: true
        },
        callBack: () => {
          state.value.loading.deleteQuery = false
          app.$notify({
            type: 'success',
            customClass: 'bg-teal-50 text-green-600 child-inherit-colors',
            message: 'query removed'
          })
          showQueryDrawerModel.value = false
        }
      })
    })
    .catch((err) => {
      app.$utils.catchError(err)
      state.value.loading.deleteQuery = false
    })
}
</script>

<template>
  <el-drawer
    v-model="showQueryDrawerModel"
    class="segment-drawer"
    size="1400px"
    direction="ltr"
    :append-to-body="true"
    @open="onOpen"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full">
        {{ state.form.id ? `EDIT QUERY` : 'NEW QUERY' }}
      </div>
    </template>
    <template #default>
      <div>
        <CrudForm
          v-if="showQueryDrawerModel"
          :form="state.form"
          :crud-external-errors="state.externalErrors"
        >
          <template #default="{ formRef }">
            <crud-field-text
              api-field-name="title"
              :sm="12"
              required
              :rules="[
                {
                  type: 'string',
                  validator: (_, value) => {
                    if (state.form.id && value === query?.Title) {
                      return true
                    }
                    return !uniqueQueriesTitles?.[value]
                  },
                  trigger: 'change',
                  message: 'duplicate'
                }
              ]"
            />
            <crud-field-textarea
              api-field-name="query"
              :sm="24"
              :textarea-rows="10"
              required
            >
              <template #label>
                Query <HipoQueryTooltip />
              </template>
            </crud-field-textarea>
            <crud-field-slot>
              <el-button
                :loading="state.loading.savingQuery"
                class="gs-btn-outlined-primary gs-height-related-lg"
                @click="saveQueryData(formRef)"
              >
                {{ !state.form.id ? 'Add new query' : 'Save query data' }}
              </el-button>
              <el-button
                class="gs-btn-outlined-neutral gs-height-related-lg"
                @click="showQueryDrawerModel = false"
              >
                Cancel
              </el-button>
              <el-popconfirm
                v-if="!!state.form.id "
                :width="240"
                title="Are you sure to delete this?"
                @confirm="deleteQuery"
              >
                <template #reference>
                  <el-button
                    class="gs-btn-outlined-danger gs-height-related-lg"
                    :icon="Delete"
                  >
                    Delete query
                  </el-button>
                </template>
              </el-popconfirm>
            </crud-field-slot>
          </template>
        </CrudForm>
      </div>
    </template>
  </el-drawer>
</template>

<style scoped>

</style>

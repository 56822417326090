<script>
import {
  CaretBottom,
  CaretTop,
  SemiSelect
} from '@element-plus/icons-vue'

export default {
  name: 'ScoreComponent',
  components: {
    CaretBottom,
    CaretTop,
    SemiSelect
  },
  props: {
    stats: {
      type: Object,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    color(value) {
      if (value <= 30) {
        return '#ff0000'
      }
      return 'black'
    }
  }
}
</script>

<template>
  <div class="statistic-card">
    <el-statistic
      :value="stats.current[code]"
      :value-style="{ color: color(stats.current[code]) }"
    >
      <template #title>
        <div style="display: inline-flex; align-items: center">
          {{ title }}
        </div>
      </template>
    </el-statistic>
    <div class="statistic-footer">
      <div class="footer-item">
        <span>last week</span>
        <span
          v-if="stats.last_week[code] >= 0"
          class="green"
        >
          {{ stats.last_week[code] }}
          <el-icon>
            <CaretTop v-if="stats.last_week[code]" />
            <SemiSelect v-else />
          </el-icon>
        </span>
        <span
          v-else
          class="red"
        >
          {{ stats.last_week[code] * -1 }}
          <el-icon>
            <CaretBottom />
          </el-icon>
        </span>
      </div>
      <div class="footer-item">
        <span>last month</span>
        <span
          v-if="stats.last_month[code] >= 0"
          class="green"
        >
          {{ stats.last_month[code] }}
          <el-icon>
            <CaretTop v-if="stats.last_month[code]" />
            <SemiSelect v-else />
          </el-icon>
        </span>
        <span
          v-else
          class="red"
        >
          {{ stats.last_month[code] * -1 }}
          <el-icon>
            <CaretBottom />
          </el-icon>
        </span>
      </div>
      <div class="footer-item">
        <span>last half year</span>
        <span
          v-if="stats.last_half_year[code] >= 0"
          class="green"
        >
          {{ stats.last_half_year[code] }}
          <el-icon>
            <CaretTop v-if="stats.last_half_year[code]" />
            <SemiSelect v-else />
          </el-icon>
        </span>
        <span
          v-else
          class="red"
        >
          {{ stats.last_half_year[code] * -1 }}
          <el-icon>
            <CaretBottom />
          </el-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>

<script setup>
import { defineProps, inject, ref } from 'vue'
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'
import { globalProperties as app } from '!/plugins/utilities.js'

const props = defineProps({
  approvedBy: {
    type: String,
    default: ''
  },
  apiUrl: {
    type: String,
    default: ''
  },
  email: {
    type: String,
    default: ''
  }
})

const form = inject('crudForm', {})

const approveList = [
  'llato@gamesture.com',
  'mkrawczyk@gamesture.com',
  'pkozak@gamesture.com',
  'tdrozdzynski@gamesture.com'
]

const aiLoading = ref(false)
const aiVersion = ref('')

function improveWithAI() {
  aiLoading.value = true
  // http://localhost:8097
  const text = aiVersion.value ? aiVersion.value : form.value.description
  app.$axios
    .post(`${(props.apiUrl)}/admin/api/hipo/notes-ai/`, { text })
    .then((data) => {
      if (!aiVersion.value) {
        aiVersion.value = form.value.description
      }
      form.value.description = data.data.data
    })
    .catch(app.$utils.catchError)
    .then(() => {
      aiLoading.value = false
    })
}
function cancelAI() {
  form.value.description = aiVersion.value
  aiVersion.value = ''
}
</script>

<template>
  <el-tab-pane
    label="Definition"
    name="definition"
  >
    <template #default>
      <el-row
        :gutter="20"
      >
        <fields-col
          v-if="form.id"
          :sm="12"
        >
          <crud-field-slot
            label="Status"
          >
            <el-radio-group
              v-model="form.status"
              size="small"
            >
              <el-radio-button
                label="Idea"
                value="Idea"
              />
              <el-radio-button
                label="Voting"
                value="Voting"
              />
              <el-radio-button
                label="To implement"
                value="ToImplement"
              />
              <el-radio-button
                label="Development"
                value="Implementing"
              />
              <el-radio-button
                label="Ready"
                value="Ready"
              />
              <el-radio-button
                v-show="form.readyStatus === 'Approved'"
                label="Running"
                value="Running"
              />
              <el-radio-button
                v-show="form.readyStatus === 'Approved'"
                label="Success"
                value="Success"
              />
              <el-radio-button
                v-show="form.readyStatus === 'Approved'"
                label="Failure"
                value="Failure"
              />
              <el-radio-button
                label="Canceled"
                value="Canceled"
              />
            </el-radio-group>
          </crud-field-slot>
          <crud-field-slot
            v-if="form.status === 'Ready'"
            label="Approval"
          >
            <el-radio-group
              v-if="form.status === 'Ready' && approveList.includes(email)"
              v-model="form.readyStatus"
              size="small"
            >
              <el-radio-button
                label="To approve"
                value="ToApprove"
              />
              <el-radio-button
                label="Approved"
                value="Approved"
              />
              <el-radio-button
                label="Hold"
                value="Queued"
              />
            </el-radio-group>
            <template v-else-if="form.status === 'Ready' && !approveList.includes(email)">
              <div v-if="form.readyStatus === 'ToApprove'">
                waiting to be approved
                <el-tooltip>
                  <template #content>
                    <div>only these users can approve this HiPo:</div>
                    <div
                      v-for="user in approveList"
                      :key="user"
                    >
                      {{ user }}
                    </div>
                  </template>
                  <icon-ify
                    icon="bxs:help-circle"
                    class="inline-block"
                  />
                </el-tooltip>
              </div>
              <div v-if="form.readyStatus === 'Approved'">
                Approved by {{ approvedBy }}
              </div>
              <div v-if="form.readyStatus === 'Queued'">
                Queued by {{ approvedBy }}
              </div>
            </template>
          </crud-field-slot>
          <template v-if="form.status !== 'Idea' && form.status !== 'Voting' && form.status !== 'Canceled'">
            <crud-field-slot
              label="Priority"
            >
              <el-radio-group
                v-model="form.priority"
                size="small"
              >
                <el-radio-button
                  label="Low"
                  value="Low"
                />
                <el-radio-button
                  label="Normal"
                  value="Normal"
                />
                <el-radio-button
                  label="High"
                  value="High"
                />
                <el-radio-button
                  label="Urgent"
                  value="Highest"
                />
              </el-radio-group>
            </crud-field-slot>
            <crud-field-slot
              label="Implementation"
              :sm="10"
            >
              <el-radio-group
                v-model="form.testType"
                size="small"
              >
                <el-radio-button
                  label="ABTest"
                  value="ABTest"
                />
                <el-radio-button
                  label="Flag"
                  value="Flag"
                />
                <el-radio-button
                  label="Global"
                  value="Global"
                />
              </el-radio-group>
            </crud-field-slot>
            <crud-field-text
              v-if="form.testType === 'Flag'"
              api-field-name="flag"
              label="Flag name"
              :sm="14"
              required
            />
            <template v-if="form.testType !== 'ABTest' && (form.status === 'Running' || form.status === 'Success' || form.status === 'Failure')">
              <fields-col>
                <crud-field-date-time
                  api-field-name="dateStart"
                  label="Starts at"
                  :sm="12"
                  format="YYYY-MM-DD HH"
                  time-format="HH"
                  popper-class="only-hours"
                />
                <crud-field-date-time
                  api-field-name="dateEnd"
                  label="Ends at"
                  :sm="12"
                  format="YYYY-MM-DD HH"
                  time-format="HH"
                  popper-class="only-hours"
                />
              </fields-col>
            </template>
          </template>
        </fields-col>
        <fields-col :sm="12">
          <crud-field-text
            api-field-name="title"
            label="Idea"
            required
          />
          <crud-field-textarea
            api-field-name="description"
            label="Notes"
            :textarea-rows="10"
            required
          />
          <crud-field-textarea
            v-if="form.status === 'Success' || form.status === 'Failure' || form.status === 'Canceled'"
            api-field-name="closeComments"
            :textarea-rows="5"
          />
          <el-button
            v-show="form.description"
            size="small"
            class="ml-5"
            :loading="aiLoading"
            @click="improveWithAI"
          >
            Improve with AI
          </el-button>
          <el-button
            v-show="aiVersion"
            size="small"
            @click="cancelAI"
          >
            Cancel
          </el-button>
          <fields-col
            v-if="form.id"
            class="mt-3"
          >
            <CrudFieldFile
              v-for="n in 5"
              :key="n"
              :sm="5"
              :api-field-name="`ScreenShot${n}`"
              :label="`ScreenShot ${n}`"
              :upload-title="`Upload ScreenShot ${n}`"
            />
          </fields-col>
        </fields-col>
      </el-row>
    </template>
  </el-tab-pane>
</template>

<style scoped>

</style>

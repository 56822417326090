import Logo from '../../assets/logo-gs.svg'

const DefaultMeta = {
  Title: '', // if empty string ('') will be overwritten by $route.meta.Title or $route.name
  TitlePrefix: '',
  TitleSuffix: ' : Gamesture : Global Statistics',
  FaviconFile: '' // name file in public folder,
}

const RouterMeta = {
  ...DefaultMeta,
  PageHeaderTitle: '', // if empty string ('') will be overwritten by DefaultMeta.Title
  DisableLayoutPageHeader: false, // if $route.meta.DisableLayoutPageHeader - disable only that current rote;
  IdParentSubmenu: [], // array unique ids of parent submenus,
  Entity: null
}

const LoginPageSettings = {
  Logo,
  bgClass: 'bg-gradient-to-r from-gs-bg via-gs-bg/75 to-gs-bg text-white',
  btnClass: 'text-white hover:bg-cyan-500/[.1] border-cyan-500 hover:border-cyan-500 shadow-cyan-500/50',
  btnIcon: '',
  loadEnvs: false
}

const LayoutSettings = {
  pageHeader: {
    bgClass: 'bg-gradient-to-r from-gs-bg to-cyan-500 text-white font-extralight',
    btnClass: 'text-white hover:bg-cyan-500 active:bg-cyan-400'
  },
  menu: {
    bgClass: 'text-cyan-600',
    Logo,
    title: 'Gamesture',
    titleClass: 'text-transparent bg-gradient-to-r from-gs-bg to-cyan-500 font-black ',
    collapsedBtnClass: 'text-gs-bg hover:text-cyan-500 active:bg-gray-300',
    textColor: '#62656a',
    textActiveColor: '#2d7c8a',
    hoverBgColor: '#f5f5f5',
    submenuBgColor: '#f8f5f5'
  }
}

export { DefaultMeta, RouterMeta, LoginPageSettings, LayoutSettings }

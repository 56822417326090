<script setup>
import { ref } from 'vue'
import ScoreComponent from '@/pages/bee-keeper/components/ScoreComponent.vue'
import ProgressComponent from '@/pages/bee-keeper/components/ProgressComponent.vue'
import BarChartComponent from '@/pages/bee-keeper/components/BarChartComponent.vue'
import LinesChartComponent from '@/pages/bee-keeper/components/LinesChartComponent.vue'
import BeeKeeperSentryErrorLink from '@/pages/bee-keeper/components/BeeKeeperSentryErrorLink.vue'
import { games, gamesEnvs, gamesOptions } from '@/settings/gamesEnvs.js'

const stats = ref(null)
const searchFields = ref({ env: games.snr })
const loading = ref(false)

function onLoadedData(data) {
  stats.value = !data || !Object.keys(data).length ? null : data
}
</script>

<template>
  <crud-table
    :top-actions="false"
    :versioned="false"
    hide-columns-selector
    disable-pagination
    disable-multi-select-rows
    :loading="loading"
    api="proxy/request"
    :proxy-request-params="{
      uri: '/admin/api/bee-keeper/stats/',
      method: 'GET',
      env: 'snr',
      envsUrls: gamesEnvs
    }"
    :search-fields="searchFields"
    :render-loaded-data="onLoadedData"
  >
    <template #topLeft>
      <div class="flex items-center justify-between gap-4 flex-wrap w-full">
        <div class="flex gap-4 flex-grow">
          <div class="w-32 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.env"
              type-value-field="select"
              full-width
              :options="gamesOptions"
              label="Game"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              :clearable="false"
            />
          </div>
        </div>
      </div>
    </template>
    <template #table>
      <el-row
        v-if="!loading && stats"
        class="min-w-[1200px] max-w-full"
        :gutter="40"
      >
        <fields-col
          :xs="8"
          :sm="24"
          mobile-break
        >
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="global"
              :stats="stats"
              class="bg-indigo-100"
              title="Global score"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="anr"
              :stats="stats"
              title="ANRs"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="crashes"
              :stats="stats"
              title="Crashes"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="sentry_frontend_errors"
              :stats="stats"
              title="Sentry frontend errors"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="sentry_backend_errors"
              :stats="stats"
              title="Sentry backend errors"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="sentry_warnings"
              :stats="stats"
              title="Sentry warnings"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="memory_android"
              :stats="stats"
              title="Memory usage Android"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="memory_ios"
              :stats="stats"
              title="Memory usage iOS"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="fps_android"
              :stats="stats"
              title="FPS Android"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="fps_ios"
              :stats="stats"
              title="FPS iOS"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="loading_android"
              :stats="stats"
              title="Loading time Android"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="loading_ios"
              :stats="stats"
              title="Loading time iOS"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="network_waiting"
              :stats="stats"
              title="Network waiting errors"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="network_errors"
              :stats="stats"
              title="Network errors"
            />
          </el-col>
          <el-col
            :xs="12"
            :sm="4"
          >
            <ScoreComponent
              code="png"
              :stats="stats"
              title="Big PNG files"
            />
          </el-col>
        </fields-col>
        <fields-col
          :xs="8"
          :sm="24"
          mobile-break
        >
          <el-collapse
            accordion
            class="w-full gs-inverted mx-4 sm:mx-10 mb-10"
          >
            <el-collapse-item name="global">
              <template #title>
                <ProgressComponent
                  code="global"
                  title="Global"
                  :stats="stats"
                />
              </template>
              <BarChartComponent
                :stats="stats"
                chart-code="global"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
            </el-collapse-item>
            <el-collapse-item name="ANR">
              <template #title>
                <ProgressComponent
                  code="anr"
                  title="ANR"
                  :stats="stats"
                />
              </template>
              <BarChartComponent
                :stats="stats"
                chart-code="anr"
                :min="0"
                :max="100"
                :goals="[{ value: 100, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="anr_details"
                title="Google Play ANR rate - day by day"
                :min="0"
                :max="0.008"
                :goals="[
                  { value: 0.0025, label: 'target', color: '#67c23a' },
                  { value: 0.0049, label: 'crisis', color: '#fc3200' }
                ]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="anr_details_28"
                title="Google Play ANR rate - 28 days weighted"
                :min="0"
                :max="0.008"
                :goals="[
                  { value: 0.0025, label: 'target', color: '#67c23a' },
                  { value: 0.0049, label: 'crisis', color: '#fc3200' }
                ]"
              />

              <LinesChartComponent
                :stats="stats"
                chart-code="anr_dimensions_version"
                title="Google Play ANR rate - by app version"
                :min="0"
                :max="0.008"
                :goals="[
                  { value: 0.0025, label: 'target', color: '#67c23a' },
                  { value: 0.0049, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="crashes">
              <template #title>
                <ProgressComponent
                  code="crashes"
                  title="Crashes"
                  :stats="stats"
                />
              </template>
              <BarChartComponent
                :stats="stats"
                chart-code="crashes"
                :min="0"
                :max="100"
                :goals="[{ value: 100, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="crashes_details"
                title="Google Play crashes rate"
                :min="0"
                :max="0.018"
                :goals="[
                  { value: 0.005, label: 'target', color: '#67c23a' },
                  { value: 0.0109, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="sentry_frontend_errors">
              <template #title>
                <ProgressComponent
                  code="sentry_frontend_errors"
                  title="Sentry frontend errors"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.sentry_errors_frontend"
                class="external-link"
                target="_blank"
              >Link to sentry</a>
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_frontend_errors"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_errors_frontend_details"
                title="Errors Occurring 10+ Times in Last 12 Hours"
              />
              <BeeKeeperSentryErrorLink :data="stats?.history?.sentry_errors_frontend_details_list" />
            </el-collapse-item>
            <el-collapse-item name="sentry_backend_errors">
              <template #title>
                <ProgressComponent
                  code="sentry_backend_errors"
                  title="Sentry backend errors"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.sentry_errors_backend"
                class="external-link"
                target="_blank"
              >Link to sentry</a>
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_backend_errors"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_errors_backend_details"
                title="Errors Occurring 10+ Times in Last 12 Hours"
              />
              <BeeKeeperSentryErrorLink :data="stats?.history?.sentry_errors_backend_details_list" />
            </el-collapse-item>
            <el-collapse-item name="sentry_warnings">
              <template #title>
                <ProgressComponent
                  code="sentry_warnings"
                  title="Sentry warnings"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.sentry_warnings_frontend"
                class="external-link"
                target="_blank"
              >Link to sentry frontend</a>
              |
              <a
                :href="stats.urls.sentry_warnings_backend"
                class="external-link"
                target="_blank"
              >Link to sentry backend</a>
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_warnings"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_warnings_frontend_details"
                title="Errors Occurring 10+ Times in Last 12 Hours - frontend"
              />
              <BeeKeeperSentryErrorLink :data="stats?.history?.sentry_warnings_frontend_details_list" />
              <BarChartComponent
                :stats="stats"
                chart-code="sentry_warnings_backend_details"
                title="Errors Occurring 10+ Times in Last 12 Hours - backend"
              />
              <BeeKeeperSentryErrorLink :data="stats?.history?.sentry_warnings_backend_details_list" />
            </el-collapse-item>
            <el-collapse-item name="memory_android">
              <template #title>
                <ProgressComponent
                  code="memory_android"
                  title="Memory usage Android"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.memory_usage_android"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="memory_android"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="memory_android_details"
                title="Memory usage"
                :min="250000"
                :max="2000000"
                :goals="[
                  { value: 750000, label: 'target', color: '#67c23a' },
                  { value: 1200000, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="memory_ios">
              <template #title>
                <ProgressComponent
                  code="memory_ios"
                  title="Memory usage iOS"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.memory_usage_ios"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="memory_ios"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="memory_ios_details"
                title="Memory usage"
                :min="250000"
                :max="2000000"
                :goals="[
                  { value: 700000, label: 'target', color: '#67c23a' },
                  { value: 1100000, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="fps_android">
              <template #title>
                <ProgressComponent
                  code="fps_android"
                  title="FPS Android"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.fps_android"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="fps_android"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="fps_android_details"
                title="FPS"
                :min="10"
                :max="60"
                :goals="[
                  { value: 45, label: 'target', color: '#67c23a' },
                  { value: 15, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="fps_ios">
              <template #title>
                <ProgressComponent
                  code="fps_ios"
                  title="FPS iOS"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.fps_ios"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="fps_ios"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="fps_ios_details"
                title="FPS"
                :min="10"
                :max="60"
                :goals="[
                  { value: 45, label: 'target', color: '#67c23a' },
                  { value: 15, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="loading_android">
              <template #title>
                <ProgressComponent
                  code="loading_android"
                  title="Loading time Android"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.loading_android"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="loading_android"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="loading_android_details"
                title="Loading time ms"
                :min="50"
                :max="1500"
                :goals="[
                  { value: 450, label: 'target', color: '#67c23a' },
                  { value: 1000, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="loading_ios">
              <template #title>
                <ProgressComponent
                  code="loading_ios"
                  title="Loading time iOS"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.loading_ios"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="loading_ios"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="loading_ios_details"
                title="Loading time ms"
                :min="50"
                :max="1500"
                :goals="[
                  { value: 450, label: 'target', color: '#67c23a' },
                  { value: 1000, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="network_waiting">
              <template #title>
                <ProgressComponent
                  code="network_waiting"
                  title="Network waiting errors"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.network_errors"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="network_waiting"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="network_waiting_users"
                title="% of affected users"
                :min="0"
                :max="15"
                :goals="[
                  { value: 3, label: 'target', color: '#67c23a' },
                  { value: 5, label: 'crisis', color: '#fc3200' }
                ]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="network_waiting_sessions"
                title="% of affected sessions"
                :min="0"
                :max="3"
                :goals="[
                  { value: 0.5, label: 'target', color: '#67c23a' },
                  { value: 1, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="network_errors">
              <template #title>
                <ProgressComponent
                  code="network_errors"
                  title="Network errors"
                  :stats="stats"
                />
              </template>
              <a
                :href="stats.urls.network_errors"
                class="external-link"
                target="_blank"
              >Link to tableau</a>
              <BarChartComponent
                :stats="stats"
                chart-code="network_errors"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="network_errors_users"
                title="% of affected users"
                :min="0"
                :max="1"
                :goals="[
                  { value: 0.4, label: 'target', color: '#67c23a' },
                  { value: 0.6, label: 'crisis', color: '#fc3200' }
                ]"
              />
              <BarChartComponent
                :stats="stats"
                chart-code="network_errors_sessions"
                title="% of affected sessions"
                :min="0"
                :max="0.6"
                :goals="[
                  { value: 0.2, label: 'target', color: '#67c23a' },
                  { value: 0.4, label: 'crisis', color: '#fc3200' }
                ]"
              />
            </el-collapse-item>
            <el-collapse-item name="png">
              <template #title>
                <ProgressComponent
                  code="png"
                  title="Big PNG files"
                  :stats="stats"
                />
              </template>
              <a
                href="/files?c_sor%5Bsort%5D=SizePng&c_sor%5Bsort_desc%5D=true"
                class="external-link"
                target="_blank"
              >Link to files</a>
              <BarChartComponent
                :stats="stats"
                chart-code="png"
                :min="0"
                :max="100"
                :goals="[{ value: 90, label: 'target', color: '#67c23a' }]"
              />
            </el-collapse-item>
          </el-collapse>
        </fields-col>
      </el-row>
      <div
        v-if="!loading && !stats"
        class="fixed left-1/2 top-1/2 -translate-x-2/4"
      >
        no data
      </div>
    </template>
  </crud-table>
</template>

<style>
a.external-link {
  @apply m-3 font-bold text-sky-600;
}
</style>

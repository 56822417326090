<script>
export default {
  name: 'ProgressComponent',
  props: {
    title: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    stats: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      const val = this.stats.current[this.code]
      if (val >= 80) {
        return 'success'
      } else if (val >= 60) {
        return ''
      } else if (val >= 40) {
        return 'warning'
      }
      return 'exception'
    }
  }
}
</script>

<template>
  <div class="flex sm:justify-between sm:items-center flex-col sm:flex-row w-10/12 sm:w-full">
    <div class="shrink-0 leading-none pb-2 sm:pb-0">
      {{ title }}
    </div>
    <el-progress
      class="w-full sm:mx-5"
      :status="status"
      text-inside
      :stroke-width="15"
      :percentage="stats.current[code]"
    />
  </div>
</template>

const games = {
  snr: 'snr',
  gang: 'gang',
  soccer: 'soccer',
  mafia: 'mafia',
  questland: 'questland',
  minersbrawl: 'minersbrawl',
  sticktogether: 'sticktogether'
}

const gamesOptions = [
  { value: games.snr, label: 'Slash&Roll' },
  { value: games.gang, label: 'The Gang' },
  { value: games.soccer, label: 'Soccer Clash' },
  { value: games.mafia, label: 'Mafia Rivals' },
  { value: games.questland, label: 'Questland' },
  { value: games.minersbrawl, label: 'Miners Brawl' },
  { value: games.sticktogether, label: 'Stick Together' }
]

const gamesEnvs = {
  [games.snr]: 'https://dev.sw-api-gamesture.com',
  // [games.snr]: 'http://localhost:8097',
  [games.gang]: 'https://dev.gang-api-gamesture.com',
  [games.soccer]: 'https://soccer-dev.netto-api-gamesture.com',
  [games.mafia]: 'https://mafia-dev.netto-api-gamesture.com',
  [games.questland]: 'https://dev.ql-api-gamesture.com',
  [games.minersbrawl]: 'https://craft-dev.netto-api-gamesture.com',
  [games.sticktogether]: 'https://stick-dev.netto-api-gamesture.com'
}

const gamesEnvsProd = {
  [games.snr]: 'https://prod.sw-api-gamesture.com',
  // [games.snr]: 'http://localhost:8097',
  [games.gang]: 'https://prod.gang-api-gamesture.com',
  [games.soccer]: 'https://soccer-prod.netto-api-gamesture.com',
  [games.mafia]: 'https://mafia-prod.netto-api-gamesture.com',
  [games.questland]: 'https://prod.ql-api-gamesture.com',
  [games.minersbrawl]: 'https://craft-prod.netto-api-gamesture.com',
  [games.sticktogether]: 'https://stick-prod.netto-api-gamesture.com'
}

export { games, gamesEnvs, gamesEnvsProd, gamesOptions }

<script setup>

</script>

<template>
  <el-tooltip
    :show-after="300"
  >
    <template #content>
      Currently supported fields:<br>
      <b class="text-yellow-400">Traffic</b> which takes values: <b class="text-yellow-200">'paid', 'organic', 'other'</b><br>
      <b class="text-yellow-400">Tier1</b> which takes value: <b class="text-yellow-200">1</b> (for players Tier1)<br>
      <b class="text-yellow-400">Country</b> which takes as values the player's country code (eg <b class="text-yellow-200">'PL'</b>)
      <br>Examples:<br>
      Traffic = 'paid' AND Tier1 = 1 <br>
      Traffic IN ('paid', 'other') AND Country IN ('PL', 'DE')
    </template>
    <icon-ify
      icon="bxs:help-circle"
      class="inline-block text-base ml-1 -translate-y-1"
    />
  </el-tooltip>
</template>

<style scoped>

</style>

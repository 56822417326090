import { CrudRoute, MenuItem } from '!/router'

const menuRoutes = [
  CrudRoute.buildRoute('dashboard', () => import('@/pages/index/IndexPage.vue'), 'Dashboard', 'Dash', 'ep:data-board')
    .setCssClass('scale-90')
    .getData(),
  CrudRoute.buildRoute(
    'watcher',
    () => import('@/pages/watchers/overWatcher/IndexPage.vue'),
    'OverWatcher',
    'OverWatcher',
    'solar:eye-scan-linear'
  )
    .getData(),
  CrudRoute.buildRoute(
    'bee-keeper',
    () => import('@/pages/bee-keeper/IndexPage.vue'),
    'Bee Keeper',
    'Bee Keeper',
    'lucide-lab:bee'
  )
    .setPriority(-1)
    .getData()
]
export default () => {
  menuRoutes.push(CrudRoute.buildRoute(
    'hipo',
    () => import('@/pages/hipo/IndexPage.vue'),
    'HiPo',
    'HiPo',
    'icon-park-solid:hippo'
  )
    .getData())
  return [
    MenuItem.build('menuRoutes', menuRoutes).setIsSubMenu(false).getData()
  ]
}

<script setup>
import { computed, defineProps, inject, ref, watch } from 'vue'
import { globalProperties as app } from '!/plugins/utilities'

const props = defineProps({
  currentTab: {
    type: String,
    default: ''
  },
  apiUrl: {
    type: String,
    default: ''
  }
})

const form = inject('crudForm', {})

const loading = ref({ all: false, groups: false, entity: false })
const rows = ref([])

function fetchData(type = 'all') {
  if (loading.value.all || loading.value.groups || loading.value.entity || !form.value.id) {
    return false
  }
  loading.value[type] = true
  app.$axios
    .get(`${props.apiUrl}/admin/api/hipo/usage/`, { params: { id: form.value.id } })
    .then((response) => {
      rows.value = response?.data?.items || []
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loading.value[type] = false
    })
}

const unWatch = watch(() => props.currentTab, (currentTab) => {
  if (currentTab === 'usage') {
    fetchData()
    unWatch()
  }
})

const groups = computed(() => {
  const list = {}
  rows.value.forEach((v) => {
    if (!list[v.entity]) {
      const routeData = app.$utils.getRouteByEntity(v.entity)
      list[v.entity] = {
        name: v.entity,
        url: routeData ? { name: routeData.name, query: { c_sea: { ab: id } } } : null,
        total: 0
      }
    }
    list[v.entity].total++
  })
  const data = []
  for (const x in list) {
    data.push(list[x])
  }
  return data
})

const entities = computed(() => {
  rows.value.forEach((v) => {
    const routeData = app.$utils.getRouteByEntity(v.entity)
    v.url = routeData ? { name: routeData.name, query: { c_fil: { ID: v.id } } } : null
  })
  return rows.value
})
</script>

<template>
  <el-tab-pane
    v-if="form.id"
    name="usage"
    lazy
  >
    <template #label>
      Usage
    </template>
    <template #default>
      <el-row
        v-if="form.id"
        class="gs-font-scaled mx-auto max-w-screen-xl px-2"
      >
        <!-- Groups - list -->
        <!--        url: { elAttr: { width: 90 } } -->
        <el-col>
          <h3 class="font-related-xl text-neutral-700">
            Groups - list
          </h3>
          <filtering-table
            :rows="groups"
            :max-height="`${Math.max(500, $windowHeight * 0.7)}px`"
            class="mt-5"
            header-cell-class-name="font-medium gs-col-padding-mini"
            cell-class-name="gs-col-padding-mini"
            fit
            :columns="{
              name: { header: 'Entity', sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 220 } },
              total: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 100 } }
            }"
            :page-sizes="[50, 100, 200]"
            :loading="loading.all || loading.groups"
            @fetch-data="fetchData('groups')"
          >
            <!--            <template #cell_url="{ row }"> -->
            <!--              <router-link -->
            <!--                v-if="row.url" -->
            <!--                :to="$utils.bindStaticParams(row.url)" -->
            <!--                target="_blank" -->
            <!--              > -->
            <!--                <el-button -->
            <!--                  class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg px-1" -->
            <!--                  :icon="Edit" -->
            <!--                /> -->
            <!--              </router-link> -->
            <!--            </template> -->
          </filtering-table>
        </el-col>
        <!-- Entities - list -->
        <!--        url: { elAttr: { width: 90 } } -->
        <el-col>
          <h3 class="font-related-xl mt-10 text-neutral-700">
            Entities - list
          </h3>
          <filtering-table
            :rows="entities"
            :max-height="`${Math.max(500, $windowHeight * 0.7)}px`"
            class="mt-5"
            header-cell-class-name="font-medium gs-col-padding-mini"
            cell-class-name="gs-col-padding-mini"
            fit
            :columns="{
              entity: { sortable: true, filterField: true, type: 'string', elAttr: { minWidth: 220 } },
              id: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } },
              label: { sortable: true, filterField: true, type: 'string', elAttr: { width: 160 } },
              group: { sortable: true, filterField: true, type: 'uint', elAttr: { width: 90 } }
            }"
            :page-sizes="[50, 100, 200]"
            :loading="loading.all || loading.entity"
            @fetch-data="fetchData('entity')"
          >
            <!--            <template #cell_url="{ row }"> -->
            <!--              <router-link -->
            <!--                v-if="row.url" -->
            <!--                :to="$utils.bindStaticParams(row.url)" -->
            <!--                target="_blank" -->
            <!--              > -->
            <!--                ??? -->
            <!--                <el-button -->
            <!--                  class="gs-btn-outlined-primary-light gs-height-related-sm font-related-lg px-1" -->
            <!--                  :icon="Edit" -->
            <!--                /> -->
            <!--              </router-link> -->
            <!--            </template> -->
          </filtering-table>
        </el-col>
      </el-row>
    </template>
  </el-tab-pane>
</template>

<style scoped>

</style>

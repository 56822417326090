<script setup>
import { inject } from 'vue'

const form = inject('crudForm', {})
</script>

<template>
  <el-tab-pane
    v-if="form.status !== 'Idea'"
    name="voting"
  >
    <template #label>
      Votes
    </template>
    <template #default>
      <el-row
        :gutter="40"
      >
        <crud-field-slot
          label="My vote status"
          :sm="8"
        >
          <el-radio-group
            v-model="form.myVoteStatus"
            size="small"
          >
            <el-radio-button
              label="Not sure"
              value="NotSure"
            />
            <el-radio-button
              label="Great idea"
              value="GreatIdea"
            />
            <el-radio-button
              label="Bad idea"
              value="BadIdea"
            />
          </el-radio-group>
        </crud-field-slot>
        <crud-field-textarea
          api-field-name="myVoteComment"
          :textarea-rows="5"
          label="My vote comment"
          :sm="16"
          placeholder="Place your comment"
        />
        <crud-field-slot label="Other users' votes">
          <table class="text-sm">
            <tr
              v-for="(vote, key) in form.votes"
              :key="key"
            >
              <td class="p-2">
                {{ vote.User }}
              </td>
              <td class="p-2">
                <icon-ify
                  v-if="vote.Status === 'GreatIdea'"
                  icon="uiw:smile"
                  class="inline-block text-green-800"
                  style="width: 20px; height: 20px"
                /><icon-ify
                  v-if="vote.Status === 'NotSure'"
                  icon="emojione-monotone:thinking-face"
                  class="inline-block text-gray-700"
                  style="width: 20px; height: 20px"
                />
                <icon-ify
                  v-if="vote.Status === 'BadIdea'"
                  icon="rivet-icons:sad-solid"
                  class="inline-block text-red-700"
                  style="width: 20px; height: 20px"
                />
              </td>
              <td class="p-2">
                <div class="whitespace-break-spaces">
                  {{ vote.Comment }}
                </div>
              </td>
            </tr>
          </table>
        </crud-field-slot>
      </el-row>
    </template>
  </el-tab-pane>
</template>

<style scoped>

</style>

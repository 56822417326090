<script setup>
import { defineProps, inject, ref } from 'vue'

defineProps({
  groupLabel: {
    type: String,
    default: ''
  }
})

const form = inject('crudForm', {})
const docElementsPlayer = ref([
  ['player.id', 'ID of player'],
  ['player.country', 'ISO Country code of player e.g. PL, DE'],
  ['player.zone', 'Zone code of player e.g. Asia, Europe, America'],
  ['player.created_days', 'How many days ago player was created (0 for today)'],
  ['player.created_hours', 'How many hours ago player was created (0 for last 60 minutes)'],
  ['player.new', 'true for players that just created new account'],
  ['player.total_spend', 'How many dollars player spent in game'],
  ['player.last_payment_days', 'How many days ago player bought something'],
  ['player.abtest12S2', 'true for players assigned to AB test with ID 12 and segment 2'],
  ['player.tier1', 'true for players from tier 1 countries'],
  ['player.watcher_segment', 'one of: new,churned,new_zombie,zombie,recovered,improved,similar,worse'],
  ['player.division', 'division number, 1 is lowest, 2 is higher and so on'],
  ['season.number', 'number of season'],
  ['client_version', 'accepts int64, example: 1.1.4.299 provide as 1000010000400299, sample condition: client_version >= 1000010000400299']
])
</script>

<template>
  <el-tab-pane
    v-if="form.testType === 'ABTest' && !['Idea', 'Voting', 'Canceled'].includes(form.status)"
    name="ab-condition"
  >
    <template #label>
      AB test conditions
    </template>
    <template #default>
      <el-row
        :gutter="40"
      >
        <fields-col :sm="8">
          <crud-field-slot label="Status">
            <el-radio-group
              v-model="form.abStatus"
              size="small"
            >
              <el-radio-button
                label="Draft"
                value="Draft"
              />
              <el-radio-button
                label="Active"
                value="Active"
              />
              <el-radio-button
                label="Disabled"
                value="Disabled"
              />
              <el-radio-button
                label="Finished"
                value="Archived"
              />
            </el-radio-group>
          </crud-field-slot>
          <crud-field-slot
            :label="`Control ${groupLabel}`"
            :sm="12"
          >
            <el-radio-group
              v-model="form.control"
              size="small"
            >
              <el-radio-button
                v-for="i in 4"
                :key="i"
                :label="i"
                :value="i"
              />
            </el-radio-group>
          </crud-field-slot>
          <crud-field-switcher
            api-field-name="isFinishedTutorial"
            label="Finished tutorial"
            :sm="12"
          />
          <fields-col>
            <crud-field-date-time
              api-field-name="dateStart"
              label="Starts at"
              :sm="12"
              format="YYYY-MM-DD HH"
              time-format="HH"
              popper-class="only-hours"
            />
            <crud-field-date-time
              api-field-name="dateEnd"
              label="Ends at"
              :sm="12"
              format="YYYY-MM-DD HH"
              time-format="HH"
              popper-class="only-hours"
            />
          </fields-col>
        </fields-col>
        <fields-col :sm="16">
          <crud-field-textarea
            api-field-name="condition"
            :textarea-rows="5"
          >
            <template #label>
              Condition
              <el-tooltip>
                <template #content>
                  Example:<br>(player.country in ["PL", "EN"] and player.created_days &lt;= 7) <br>or (player.new and player.total_spend > 1000)<br>
                  <a
                    href="https://expr-lang.org/docs/language-definition"
                    target="_blank"
                    class="text-blue-600"
                  >Click here to learn language definition.</a>
                </template>
                <icon-ify
                  icon="bxs:help-circle"
                  class="inline-block"
                />
              </el-tooltip>
            </template>
          </crud-field-textarea>
          <crud-field-slot>
            <table>
              <tr
                v-for="row in docElementsPlayer"
                :key="row[0]"
              >
                <td class="docCol1">
                  {{ row[0] }}
                </td>
                <td class="docCol2">
                  {{ row[1] }}
                </td>
              </tr>
            </table>
          </crud-field-slot>
        </fields-col>
      </el-row>
    </template>
  </el-tab-pane>
</template>

<style scoped>

</style>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import {
  Delete,
  Edit,
  Plus
} from '@element-plus/icons-vue'
import HipoQueryTooltip from '@/components/pages/gameplay/hipo/HipoQueryTooltip.vue'
import CrudForm from '!/components/forms/CrudForm.vue'
import { globalProperties as app } from '!/plugins/utilities'

const props = defineProps({
  showSegmentsDrawer: {
    type: Boolean,
    default: false
  },
  apiUrl: {
    type: String,
    required: true
  },
  segmentsList: {
    type: Array,
    default: () => []
  },
  segmentsConnected: {
    type: Array,
    default: () => []
  },
  game: {
    type: String,
    default: ''
  },
  testId: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:showSegmentsDrawer', 'refresh'])

const showSegmentsDrawerModel = computed({
  get() {
    return props.showSegmentsDrawer
  },
  set(newVal) {
    emit('update:showSegmentsDrawer', newVal)
  }
})

const relatedSegments = ref([])

const listSegments = computed(() => {
  const data = []
  props.segmentsList.forEach((segment) => {
    data.push({
      key: segment.ID,
      label: segment.Title,
      query: segment.Query,
      disabled: false
    })
  })
  return data.sort((a, b) => {
    const aTitle = a.label.toUpperCase()
    const bTitle = b.label.toUpperCase()
    if (aTitle < bTitle)
      return -1
    if (aTitle > bTitle)
      return 1
    return 0
  })
})

function initForm() {
  return {
    id: 0,
    title: '',
    query: '',
    segment: 1
  }
}
const settings = ref({
  editMode: false,
  editForm: initForm(),
  externalErrors: {},
  loading: {
    savingSegment: false,
    savingConnections: false
  },
  rulesData: {
    label: ''
  }
})
function showAddNewForm() {
  settings.value.editMode = true
}
function edit(title = '', query = '', id = 0) {
  settings.value.editForm.title = title
  settings.value.rulesData.label = title
  settings.value.editForm.query = query
  settings.value.editForm.id = id
  settings.value.editMode = true
}

function onOpen() {
  const connectedSegments = []
  props.segmentsConnected.forEach((seg) => {
    if (seg.key) {
      connectedSegments.push(seg.key)
    }
  })
  relatedSegments.value = connectedSegments
}

function onClose() {
  settings.value.editMode = false
  settings.value.editForm = initForm()
  relatedSegments.value = []
}
function closeEditMode() {
  settings.value.editMode = false
  settings.value.editForm = initForm()
}
function saveSegmentData(formRef) {
  if (!formRef) {
    return
  }
  settings.value.loading.savingSegment = true
  formRef.validate()
    .then(() => {
      app.$axios
        .post(`/admin/api/hipo/test-query/`, settings.value.editForm)
        .then(() => {
          emit('refresh', () => {
            app.$notify({
              type: 'success',
              customClass: 'bg-teal-50 text-green-600 child-inherit-colors',
              message: `${settings.value.editForm.id ? 'updated' : 'added new'} segment`
            })
            settings.value.loading.savingSegment = false
            closeEditMode()
          })
        })
        .catch((err) => {
          app.$utils.catchFormErrors(settings.value.externalErrors)(err)
          settings.value.loading.savingSegment = false
        })
    })
    .catch((err) => {
      settings.value.loading.savingSegment = false
      app.$utils.notifyNotAllValid(err)
    })
}
function deleteSegment() {
  settings.value.loading.deleteSegment = true
  const data = { ...settings.value.editForm }
  data.query = ''
  app.$axios
    .post(`/admin/api/hipo/test-query/`, data)
    .then(() => {
      emit('refresh', () => {
        settings.value.loading.deleteSegment = false
        app.$notify({
          type: 'success',
          customClass: 'bg-teal-50 text-green-600 child-inherit-colors',
          message: 'segment removed'
        })
        closeEditMode()
      })
    })
    .catch((err) => {
      app.$utils.catchError(err)
      settings.value.loading.deleteSegment = false
    })
}
function saveConnections() {
  settings.value.loading.savingConnections = true
  app.$axios
    .post(`/admin/api/hipo/test-settings/`, {
      id: props.testId,
      game: props.game,
      segments: relatedSegments.value
    })
    .then(() => {
      emit('refresh', () => {
        app.$notify({
          type: 'success',
          customClass: 'bg-teal-50 text-green-600 child-inherit-colors',
          message: 'saved'
        })
        settings.value.loading.savingConnections = false
        showSegmentsDrawerModel.value = false
      })
    })
    .catch((err) => {
      app.$utils.catchError(err)
      settings.value.loading.savingConnections = false
    })
}

const uniqueSegmentsTitles = computed(() => {
  return Object.fromEntries(Object.values(props.segmentsList ?? {}).map((segment) => {
    return [segment.Title, segment.ID]
  }))
})
</script>

<template>
  <el-drawer
    v-model="showSegmentsDrawerModel"
    class="segment-drawer"
    size="1400px"
    direction="ltr"
    :append-to-body="true"
    @open="onOpen"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full">
        Segments management
      </div>
    </template>

    <div class="segment-drawer-body">
      <!-- form section -->
      <section
        class="slide-section"
        :class="{ 'edit-mode': !settings.editMode }"
      >
        <div class="overflow-hidden">
          <div>
            <CrudForm
              v-if="settings.editMode"
              :form="settings.editForm"
              :crud-external-errors="settings.externalErrors"
            >
              <template #default="{ formRef }">
                <crud-field-text
                  api-field-name="title"
                  :sm="12"
                  required
                  :rules="[
                    {
                      type: 'string',
                      validator: (_, value) => {
                        if (settings.editForm.id && value === settings.rulesData.label) {
                          return true
                        }
                        return !uniqueSegmentsTitles?.[value]
                      },
                      trigger: 'change',
                      message: 'duplicate'
                    }
                  ]"
                />
                <crud-field-textarea
                  api-field-name="query"
                  :sm="24"
                  :textarea-rows="10"
                  required
                >
                  <template #label>
                    Query <HipoQueryTooltip />
                  </template>
                </crud-field-textarea>
                <crud-field-slot>
                  <el-button
                    :loading="settings.loading.savingSegment"
                    class="gs-btn-outlined-primary gs-height-related-lg"
                    @click="saveSegmentData(formRef)"
                  >
                    {{ !settings.editForm.id ? 'Add new segment' : 'Save segment data' }}
                  </el-button>
                  <el-button
                    class="gs-btn-outlined-neutral gs-height-related-lg"
                    @click="closeEditMode"
                  >
                    Cancel
                  </el-button>
                  <el-popconfirm
                    v-if="!!settings.editForm.id "
                    :width="240"
                    title="Are you sure to delete this?"
                    @confirm="deleteSegment"
                  >
                    <template #reference>
                      <el-button
                        class="gs-btn-outlined-danger gs-height-related-lg"
                        :icon="Delete"
                        :loading="settings.loading.deleteSegment"
                      >
                        Delete segment
                      </el-button>
                    </template>
                  </el-popconfirm>
                </crud-field-slot>
              </template>
            </CrudForm>
          </div>
        </div>
      </section>
      <!-- Connections -->
      <section class="flex gap-2 min-h-full">
        <div
          class="slide-section"
          :class="{ 'edit-mode': settings.editMode }"
        >
          <div class="overflow-hidden">
            <div class="grid grid-cols-1 gap-2 min-h-full grid-rows-1">
              <el-transfer
                v-if="showSegmentsDrawer"
                v-model="relatedSegments"
                filterable
                :titles="['Disconnected', 'Connected']"
                :button-texts="['Disconnect', 'Connect']"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}'
                }"
                :data="listSegments"
              >
                <template #default="{ option }">
                  <div class="flex justify-between gap-x-2">
                    <el-tooltip
                      :show-after="300"
                      placement="bottom-start"
                      :show-arrow="false"
                      :offset="0"
                    >
                      <template #content>
                        Query: {{ option.query }}
                      </template>
                      <div class="flex-grow">
                        {{ option.label }}
                      </div>
                    </el-tooltip>
                    <div @click.stop>
                      <el-button
                        class="gs-btn-outlined-primary-light gs-height-related-sm font-related-sm"
                        :icon="Edit"
                        @click="edit(option.label, option.query, option.key)"
                      />
                    </div>
                  </div>
                </template>
                <template #left-footer>
                  <el-button
                    class="gs-btn-outlined-primary-light gs-height-related-lg font-related-sm"
                    :icon="Plus"
                    @click="showAddNewForm"
                  >
                    Add new segment
                  </el-button>
                </template>
              </el-transfer>
              <div class="pb-4">
                <el-button
                  :loading="settings.loading.savingSegment"
                  class="gs-btn-outlined-primary gs-height-related-lg"
                  @click="saveConnections"
                >
                  Save connections
                </el-button>
                <el-button
                  class="gs-btn-outlined-neutral gs-height-related-lg"
                  @click="showSegmentsDrawerModel = false"
                >
                  Cancel
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </el-drawer>
</template>

<style scoped>
.segment-drawer-body {
  height: 100%;
  &:deep(.el-transfer ) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    row-gap: 2em;
    width: 100%;
    --el-transfer-panel-width: 100%;
    --el-transfer-panel-body-height: 40%;

    .el-transfer {
      &-panel {
        height: 44%;
        display: flex;
        flex-direction: column;

        &__body {
          flex-grow: 1;
        }

        &__item {
          margin-right: 30px !important;
        }
      }

      &__buttons {
        padding: 0 10px;

        button > span {
          gap: 5px;
        }

        .el-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
  &:deep(.slide-section) {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.4s ease-in-out;
    &.edit-mode {
      grid-template-rows: 0fr;
    }
  }
}
</style>

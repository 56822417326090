<script setup>
import { Edit, Filter, Plus, Setting } from '@element-plus/icons-vue'
import { computed, defineProps, inject, ref, watch } from 'vue'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import HipoQueryTooltip from '@/components/pages/gameplay/hipo/HipoQueryTooltip.vue'
import HiPoSegmentsDrawer from '@/components/pages/gameplay/hipo/HipoSegmentsDrawer.vue'
import HiPoQueryDrawer from '@/components/pages/gameplay/hipo/HipoQueryDrawer.vue'
import { globalProperties as app } from '!/plugins/utilities.js'

const props = defineProps({
  game: {
    type: String,
    default: ''
  },
  apiUrl: {
    type: String,
    default: ''
  },
  groupLabel: {
    type: String,
    default: ''
  }
})

const form = inject('crudForm', {})

const charts = {}

function initDataStruct() {
  return {
    abResultsData: {
      segments: [],
      settings: {},
      segmentsList: [],
      queriesList: [],
      campaigns: []
    },
    segmentTabsModel: 0,
    chartKPI: 'd0',
    abFilters: {
      start: undefined,
      end: undefined,
      query: undefined,
      campaign: undefined
    },
    abFiltersHelpers: {
      selectorQuery: undefined
    },
    loading: {
      abResults: false
    },
    showSegmentsDrawer: false,
    showQueryDrawer: false,
    editQuery: {},
    retentionChartCreated: false,
    retentionChartData: {}
  }
}

const abData = ref(initDataStruct())
const delayDays = ref(0)

function loadAbResults(callBack, querySettings) {
  abData.value.loading.abResults = true
  const requestData = { id: form.value.id, game: props.game, control: form.value.control, ...abData.value.abFilters }
  if (querySettings) {
    delete requestData.query
  }

  if (form.value.dateStart) {
    requestData.test_start = form.value.dateStart
  }

  app.$axios
    .get('/admin/api/hipo/test-results/', { params: requestData })
    .then((data) => {
      const segmentsList = {}
      const queriesList = {}
      let referQuery
      Object.entries(data?.data?.queries ?? {}).forEach(([_, qData]) => {
        if (qData.Segment) {
          segmentsList[qData.ID] = qData
        } else {
          queriesList[qData.ID] = { ...qData, value: qData.ID, label: qData.Title }
          if (querySettings?.queryTitle?.length && qData.Title === querySettings.queryTitle) {
            referQuery = qData
          }
        }
      })
      delayDays.value = data?.data?.settings?.DaysDelay || 0
      abData.value.abResultsData.queriesList = Object.values(queriesList).sort((a, b) => {
        const aLabel = a.label.toUpperCase()
        const bLabel = b.label.toUpperCase()
        if (aLabel < bLabel)
          return -1
        if (aLabel > bLabel)
          return 1
        return 0
      })
      if (querySettings) {
        if (querySettings?.queryId && querySettings?.removedQuery && abData.value.abFiltersHelpers.selectorQuery === querySettings?.queryId) { // only when is removed selected query
          abData.value.abFilters.query = undefined
        } else if (!querySettings.queryId && querySettings.queryTitle?.length && querySettings?.removedQuery === false) {
          abData.value.abFilters.query = referQuery?.Query
          abData.value.abFiltersHelpers.selectorQuery = referQuery.ID
        } else if (querySettings.queryId && querySettings?.removedQuery === false && querySettings.queryId === abData.value.abFiltersHelpers.selectorQuery) {
          abData.value.abFilters.query = referQuery?.Query
        }
        return false // only refresh query list
      }

      abData.value.abResultsData.segments = []
      const segments = data?.data?.segments ?? {}
      const segmentsArr = []
      Object.entries(segments).forEach(([segmentKey, segmentData]) => {
        segmentData.KPIs = Object.entries(segmentData.KPIs || {}).map(([kpiKey, kpiData]) => {
          kpiData.key = kpiKey
          kpiData.Groups = Object.fromEntries((kpiData.Groups ?? []).map((kpiData) => {
            if (kpiData.Continuous) {
              kpiData.ValueFormated = app.$utils.numberFormat(kpiData.Value)
            } else {
              let v = kpiData.Value
              if (kpiData.Percent) {
                v *= 100
              }
              kpiData.ValueFormated = `${app.$utils.numberFormat(v, { precision: kpiData.Precision })}`
            }
            return [`${kpiData.Group}`, kpiData]
          }))
          Object.entries(kpiData.Groups).forEach(([_, grData]) => {
            grData.diff = ''
            grData.diffFormated = ''
            if (grData.Group !== form.value.control && kpiData.Groups?.[form.value.control] && kpiData.Groups?.[form.value.control].Value !== grData.Value) {
              const controlValue = kpiData.Groups?.[form.value.control].Value
              grData.diff = (grData.Value - controlValue) / (controlValue || 1) * 100
              grData.diffFormated = `${grData.diff > 0 ? '+' : ''}${app.$utils.numberFormat(grData.diff)}%`
            }
          })
          return kpiData
        }).sort((a, b) => a.Order - b.Order)
        segmentData.PlayersCompare = {}
        Object.entries(segmentData.Players ?? {}).forEach(([grPlayer, qtPlayer]) => {
          segmentData.PlayersCompare[grPlayer] = {
            qtFormat: app.$utils.numberFormat(qtPlayer),
            diff: '',
            diffFormated: ''
          }
          const grPlayerNumber = grPlayer * 1
          if (grPlayerNumber !== form.value.control && segmentData.Players?.[form.value.control] !== undefined && segmentData.Players?.[form.value.control] !== qtPlayer) {
            const controlValue = segmentData.Players?.[form.value.control]
            segmentData.PlayersCompare[grPlayer].diff = (qtPlayer - controlValue) / (controlValue || 1) * 100
            segmentData.PlayersCompare[grPlayer].diffFormated = `${segmentData.PlayersCompare[grPlayer].diff > 0 ? '+' : ''}${app.$utils.numberFormat(segmentData.PlayersCompare[grPlayer].diff)}%`
          }
        })
        let segmentLabel = ''
        if (segmentKey === '0') {
          segmentLabel = 'Default'
        } else {
          segmentLabel = segmentsList?.[segmentKey]?.Title ?? `Segment id ${segmentKey}`
        }
        segmentsArr.push({ ...segmentData, key: Number.parseInt(segmentKey), label: segmentLabel, query: segmentsList?.[segmentKey]?.Query ?? '' })
      })
      abData.value.abResultsData.segments = segmentsArr.sort((a, b) => {
        const aLabel = a.label.toUpperCase()
        const bLabel = b.label.toUpperCase()
        if (!a.key || !b.key) {
          return 1
        }
        if (aLabel < bLabel)
          return -1
        if (aLabel > bLabel)
          return 1
        return 0
      })
      // abData.value.segmentTabsModel = abData.value.abResultsData?.segments?.[0]?.key ?? 0

      abData.value.abResultsData.segmentsList = Object.values(segmentsList)
      abData.value.abResultsData.settings = data?.data?.settings ?? {}
      setTimeout(() => {
        initChartRetention()
      }, 300)

      function renderCampaigns(renderValue, renderKey, parentKey) {
        if (typeof renderValue === 'object') {
          let users = 0
          const children = Object.entries(renderValue ?? {}).map(([chKey, chVal]) => {
            const child = renderCampaigns(chVal, chKey, renderKey ? `${parentKey ?? ''}${renderKey} / ` : undefined)
            users += (child.users || 0)
            return child
          }).sort((a, b) => {
            return b.users - a.users
          })
          if (renderKey === undefined) {
            return children
          }
          return {
            value: `${parentKey ?? ''}${renderKey}`,
            label: `${renderKey} (${users})`,
            users,
            children
          }
        }
        return {
          value: `${parentKey || ''}${renderKey}`,
          label: `${renderKey} (${renderValue})`,
          users: renderValue
        }
      }
      abData.value.abResultsData.campaigns = renderCampaigns(data?.data?.campaigns ?? {}) || []
    })
    .catch(app.$utils.catchError)
    .then(() => {
      abData.value.loading.abResults = false
      if (callBack) {
        callBack()
      }
    })
}

watch(() => form.value.id, (id) => {
  if (id && form.value.testType === 'ABTest') {
    initDataStruct()
    loadAbResults(null)
  }
})

const activeGroup = computed(() => {
  const data = []
  for (let i = 1; i <= 4; i++) {
    if (form.value.segmentPercentage[i - 1]) {
      data.push(i)
    }
  }
  return data
})

const kpiOrderGroup = computed(() => {
  if (!form.value.control) {
    return activeGroup.value
  }
  const kpiGroups = []
  for (let i = 1; i <= 4; i++) {
    if (form.value.segmentPercentage[i - 1] || i === form.value.control) {
      kpiGroups.push(i)
    }
  }
  return kpiGroups.sort((a) => {
    if (a === form.value.control) {
      return -1
    }
    return 0
  })
})

const segmentsKPIs = computed(() => {
  return abData.value.abResultsData.segments
})

const chartData = computed(() => {
  const data = []
  for (const row of abData.value.abResultsData.segments[abData.value.segmentTabsModel].KPIsHistory[abData.value.chartKPI]) {
    let has = false
    let value = row.Value
    if (row.Percent) {
      value *= 100
    }
    const valueFormatted = app.$utils.numberFormat(value, { precision: row.Precision })
    for (const row2 of data) {
      if (row.Date === row2.date) {
        row2[`value${row.Group}`] = value
        row2[`valueF${row.Group}`] = valueFormatted
        row2[`players${row.Group}`] = app.$utils.numberFormat(row.Players, { precision: 0 })
        row2[`suffix${row.Group}`] = row.Suffix
        has = true
        break
      }
    }
    if (!has) {
      const newRow = { date: row.Date }
      newRow[`value${row.Group}`] = value
      newRow[`valueF${row.Group}`] = valueFormatted
      newRow[`players${row.Group}`] = app.$utils.numberFormat(row.Players, { precision: 0 })
      newRow[`suffix${row.Group}`] = row.Suffix
      data.push(newRow)
    }
  }
  const filteredData = []
  for (const i in data) {
    const row = data[i]
    if (!row.value1 && !row.value2) {
      if (!data[i + 1]?.value1 && !data[i + 1]?.value2) {
        break
      }
    }
    filteredData.push(row)
  }
  return filteredData
})

function updateChart() {
  for (const x of charts[abData.value.segmentTabsModel].series.values) {
    x.data.setAll(chartData.value)
  }
}

function initChartRetention() {
  if (abData.value.retentionChartCreated) {
    updateChart()
    return
  }
  abData.value.retentionChartCreated = true
  for (const i in segmentsKPIs.value) {
    const segmentTab = segmentsKPIs.value[i]
    const root = am5.Root.new(`retentionChart${segmentTab.key}`)

    root.setThemes([
      am5themes_Animated.new(root)
    ])
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: 'none',
      wheelY: 'none',
      pinchZoomX: true
    }))
    charts[i] = chart

    chart.get('colors').set('step', 3)

    const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {}))
    cursor.lineY.set('visible', false)

    const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.3,
      baseInterval: {
        timeUnit: 'day',
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, { minorGridEnabled: true }),
      tooltip: am5.Tooltip.new(root, {})
    }))

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: am5xy.AxisRendererY.new(root, {})
    }))
    // const control = form.value.control

    const series = chart.series.push(am5xy.LineSeries.new(root, {
      name: 'Series 1',
      xAxis,
      yAxis,
      valueYField: 'value1',
      valueXField: 'date',
      tooltip: am5.Tooltip.new(root, {
        labelText: `Control group: {valueF1}{suffix1} ({players1} players)\nGroup 2: {valueF2}{suffix2} ({players2} players)`
      })
    }))

    series.strokes.template.setAll({
      strokeDasharray: [2, 2],
      strokeWidth: 2
    })

    series.get('tooltip').get('background').set('fillOpacity', 0.5)

    const series2 = chart.series.push(am5xy.LineSeries.new(root, {
      name: 'Series 2',
      xAxis,
      yAxis,
      valueYField: 'value2',
      valueXField: 'date'
    }))
    series2.strokes.template.setAll({
      strokeWidth: 2
    })

    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX']
    })

    const data = chartData.value

    series.data.setAll(data)
    series2.data.setAll(data)
    series.appear(1000)
    series2.appear(1000)
    chart.appear(1000, 100)
  }
}

function editQuery(queryData) {
  abData.value.editQuery = queryData
  abData.value.showQueryDrawer = true
}

function onRefreshQueries(data) {
  abData.value.editQuery = {}
  loadAbResults(data.callBack, data.settings)
}

const queriesOptionsById = computed(() => {
  return Object.fromEntries(abData.value.abResultsData.queriesList.map((item) => {
    return [item.ID, item]
  }))
})

watch(() => abData.value.abFilters.query, (qNewValue) => {
  if ((qNewValue?.length && queriesOptionsById.value?.[abData.value.abFiltersHelpers.selectorQuery]?.Query !== qNewValue) || qNewValue === undefined) {
    abData.value.abFiltersHelpers.selectorQuery = undefined
  }
})

watch(() => abData.value.abFiltersHelpers.selectorQuery, (newValue, oldValue) => {
  if (newValue) {
    abData.value.abFilters.query = queriesOptionsById.value?.[newValue]?.Query
  } else if (queriesOptionsById.value?.[oldValue]?.Query === abData.value.abFilters.query) {
    abData.value.abFilters.query = undefined
  }
})
// el.scrollWidth, el.clientWidth

watch(() => abData.value.chartKPI, () => {
  updateChart()
})

watch(() => abData.value.segmentTabsModel, () => {
  updateChart()
})

const loadingDelayD = ref(false)
function onDelayChange(delayD) {
  delayDays.value = delayD
  loadingDelayD.value = true
  app.$axios
    .post(`/admin/api/hipo/test-settings/`, {
      id: form.value.id,
      game: props.game,
      favorite_kpis: abData.value?.abResultsData?.settings?.FavoriteKPIs || '',
      segments: segmentsKPIs.value.map(segment => segment.key),
      days_delay: delayDays.value
    })
    .then(() => {
      loadAbResults(() => {
        loadingDelayD.value = false
      })
    })
    .catch((err) => {
      app.$utils.catchError(err)
      loadingDelayD.value = false
    })
}
</script>

<template>
  <el-tab-pane
    v-if="form.testType === 'ABTest' && !['Idea', 'Voting', 'Canceled'].includes(form.status)"
    name="ab-bi"
  >
    <template #label>
      AB results
      <icon-ify
        v-if="segmentsKPIs?.length"
        icon="mdi:check-bold"
        class="inline-block"
      />
      <icon-ify
        v-if="abData.loading.abResults"
        icon="icomoon-free:spinner9"
        class="inline-block text-green-800 animate-spin"
        style="width: 17px; height: 17px"
      />
    </template>
    <template #default>
      <template
        v-if="kpiOrderGroup?.length && segmentsKPIs?.length"
      >
        <el-row
          :gutter="40"
        >
          <crud-field-textarea
            api-field-name="query"
            :form="abData.abFilters"
            :textarea-rows="6"
            :sm="10"
            class="query-field group"
          >
            <template #label>
              <div class="flex justify-between items-center">
                <div class="w-2/5">
                  Query <HipoQueryTooltip />
                </div>
                <div class="w-3/5">
                  <crud-field-select
                    api-field-name="selectorQuery"
                    :form="abData.abFiltersHelpers"
                    empty-label
                    filter-mode
                    class="pb-1"
                  >
                    <template #options>
                      <el-option
                        v-for="item in abData.abResultsData.queriesList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        class="pr-0.5"
                      >
                        <div class="flex justify-between gap-x-2">
                          <el-tooltip
                            :show-after="300"
                            placement="bottom-start"
                            :show-arrow="false"
                            :offset="0"
                          >
                            <template #content>
                              Query: {{ item.Query }}
                            </template>
                            <div class="flex-grow">
                              {{ item.label }}
                            </div>
                          </el-tooltip>
                          <div @click.stop>
                            <el-button
                              class="gs-btn-outlined-primary-light gs-height-related-sm font-related-sm"
                              :icon="Edit"
                              @click="editQuery(item)"
                            />
                          </div>
                        </div>
                      </el-option>
                    </template>
                    <template #footer>
                      <el-button
                        class="gs-btn-outlined-primary gs-height-related-sm font-related-lg"
                        :icon="Plus"
                        @click="editQuery({ Query: abData.abFilters.query })"
                      >
                        ADD NEW QUERY
                      </el-button>
                    </template>
                  </crud-field-select>
                </div>
              </div>
            </template>
            <template #fieldPrepend>
              <div class="absolute top-0 left-0 z-[1] hidden group-hover:block">
                <icon-ify
                  v-if="abData.abFilters.query?.length"
                  icon="carbon:close-outline"
                  class="gs-scaled-icon-sm inline-block -translate-y-1/2 cursor-pointer translate-x-[1px] active:text-blue-500"
                  @click="abData.abFilters.query = undefined"
                />
              </div>
            </template>
          </crud-field-textarea>
          <fields-col
            :sm="12"
            :gutter="0"
          >
            <crud-field-date-time
              api-field-name="start"
              :form="abData.abFilters"
              :sm="12"
              class="md:pr-0.5"
            />
            <crud-field-date-time
              api-field-name="end"
              :form="abData.abFilters"
              :sm="12"
            >
              <template #fieldPrepend>
                <span class="hidden md:inline-block pr-0.5">-</span>
              </template>
            </crud-field-date-time>
            <!--            <crud-field-select -->
            <!--              api-field-name="campaign" -->
            <!--              :form="abData.abFilters" -->
            <!--              :sm="12" -->
            <!--              col-break -->
            <!--              :options="abData.abResultsData.campaigns" -->
            <!--            /> -->
            <crud-field-slot
              label="Media source"
              :sm="12"
              col-break
            >
              <template #default>
                <el-tree-select
                  v-model="abData.abFilters.campaign"
                  :data="abData.abResultsData.campaigns"
                  check-strictly
                  clearable
                  :render-after-expand="false"
                  placeholder=" "
                >
                  <template #label>
                    {{ abData.abFilters.campaign }}
                  </template>
                </el-tree-select>
              </template>
            </crud-field-slot>
            <el-row>
              <el-button
                class="gs-btn-outlined-primary gs-height-related-lg font-related-sm pl-2"
                :loading="abData.loading.abResults"
                :icon="Filter"
                @click="loadAbResults(null)"
              >
                FILTER
              </el-button>
              <el-button
                class="gs-btn-outlined-neutral gs-height-related-lg font-related-sm pl-2 uppercase"
                :icon="Setting"
                @click="abData.showSegmentsDrawer = true"
              >
                Manage segments
              </el-button>
            </el-row>
          </fields-col>
        </el-row>
        <div class="border-t border-b border-neutral-200 py-3 mt-3">
          <el-dropdown
            split-button
            type="primary"
            max-height="300px"
            size="small"
            :disabled="loadingDelayD"
            @command="onDelayChange"
          >
            Delay days:
            <span
              v-if="!loadingDelayD"
              class="ml-1 min-w-5"
            >{{ delayDays }}</span>
            <icon-ify
              v-else
              icon="icomoon-free:spinner9"
              class="inline-block animate-spin size-4"
            />
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="n in 101"
                  :key="n - 1"
                  :command="n - 1"
                >
                  {{ n - 1 }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="mb-3" />
        <el-tabs
          v-model="abData.segmentTabsModel"
          :class="{ 'hide-tabs': segmentsKPIs.length < 2 }"
        >
          <el-tab-pane
            v-for="(segmentTab, key) in segmentsKPIs"
            :key="key"
            :name="key"
            :label="String(segmentTab.key)"
          >
            <div class="flex flex-wrap justify-start items-start gap-8 lg:gap-24">
              <div>
                <table
                  class="border-collapse text-left text-cyan-600 text-sm"
                >
                  <tbody>
                    <tr class="bg-blue-100 text-cyan-600">
                      <th
                        class="px-1 py-1 font-normal min-w-10"
                      >
                        KPI
                      </th>
                      <th
                        v-for="grNumber in kpiOrderGroup"
                        :key="grNumber"
                        class="px-1 py-1 font-normal min-w-16"
                      >
                        <el-tooltip
                          raw-content
                          placement="bottom-start"
                          :show-after="300"
                        >
                          <template #content>
                            {{ form.segmentDescription?.[grNumber - 1] }}
                            <span
                              v-if="!form.segmentDescription?.[grNumber - 1]?.length"
                              class="capitalize"
                            >AB {{ groupLabel }} {{ grNumber }}</span>
                          </template>
                          <div class="max-w-44 truncate">
                            {{ form.segmentDescription?.[grNumber - 1] }}
                            <span
                              v-if="!form.segmentDescription?.[grNumber - 1]?.length"
                              class="capitalize"
                            >AB {{ groupLabel }} {{ grNumber }}</span>
                          </div>
                        </el-tooltip>
                      </th>
                    </tr>
                    <tr class="hover:bg-slate-100">
                      <td class="pl-1 pr-14 py-3 border-b border-b-slate-300">
                        Players
                      </td>
                      <td
                        v-for="gr in kpiOrderGroup"
                        :key="gr"
                        class="pl-1 pr-16 py-3 border-b border-b-slate-300"
                      >
                        {{ segmentTab.PlayersCompare[gr]?.qtFormat || 0 }}
                        <span
                          v-if="segmentTab?.PlayersCompare?.[gr]?.diffFormated?.length"
                          class="text-xs pl-0.5 whitespace-nowrap"
                        >({{ segmentTab.PlayersCompare[gr]?.diffFormated || 0 }})
                        </span>
                      </td>
                    </tr>
                    <tr
                      v-for="kpi in segmentTab.KPIs"
                      :key="kpi.key"
                      class="hover:bg-slate-100 even:bg-neutral-50"
                    >
                      <td class="pl-1 pr-14 py-3 border-b border-b-slate-300">
                        <div class="truncate">
                          {{ kpi.Description }}
                        </div>
                      </td>
                      <td
                        v-for="gr in kpiOrderGroup"
                        :key="gr"
                        class="pl-1 pr-16 py-3 border-b border-b-slate-300"
                      >
                        <el-tooltip :show-after="300">
                          <template #content>
                            Players: <b>{{ kpi?.Groups?.[gr]?.Players || 0 }}</b>
                          </template>
                          <span class="cursor-help">{{ kpi?.Groups?.[gr]?.ValueFormated }}</span>
                        </el-tooltip>
                        <span
                          v-if="kpi?.Suffix"
                        >{{ kpi?.Suffix }}</span>
                        <span
                          v-if="kpi?.Groups?.[gr]?.diffFormated?.length && !kpi?.Groups?.[gr]?.Continuous"
                          class="text-xs pl-0.5 whitespace-nowrap"
                          :class="{
                            'text-red-600': kpi?.Significant && kpi?.Groups?.[gr]?.diff < 0,
                            'text-green-600': kpi?.Significant && kpi?.Groups?.[gr]?.diff >= 0
                          }"
                        >({{ kpi?.Groups?.[gr]?.diffFormated }})
                          <icon-ify
                            v-if="kpi?.Significant && kpi?.Groups?.[gr]?.diff >= 0"
                            icon="material-symbols:check-circle"
                            class="gs-scaled-icon-md inline-block"
                          />
                          <icon-ify
                            v-else-if="kpi?.Significant && kpi?.Groups?.[gr]?.diff < 0"
                            icon="ep:circle-close-filled"
                            class="gs-scaled-icon-md inline-block"
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="min-w-[30vw]">
                <el-row>
                  <el-col>
                    <el-radio-group
                      v-model="abData.chartKPI"
                      class="py-5"
                      size="small"
                    >
                      <el-radio-button
                        v-for="kpi in segmentTab.KPIs"
                        v-show="kpi.key[0] === 'd'"
                        :key="kpi.key"
                        :label="kpi.key"
                        :value="kpi.key"
                      />
                    </el-radio-group>
                    <h4 class="font-bold text-center">
                      History daily
                    </h4>
                    <div
                      :id="`retentionChart${segmentTab.key}`"
                      style="height: 300px"
                    />
                  </el-col>
                </el-row>
              </div>
            </div>

            <template #label>
              <div @dblclick="abData.showSegmentsDrawer = true">
                <el-tooltip
                  v-if="segmentTab?.query?.length "
                  :show-after="300"
                  placement="bottom"
                >
                  <template #content>
                    Query: {{ segmentTab.query }}
                  </template>
                  {{ segmentTab.label }}
                </el-tooltip>
                <template v-else>
                  {{ segmentTab.label }}
                </template>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </template>
      <HiPoSegmentsDrawer
        v-model:show-segments-drawer="abData.showSegmentsDrawer"
        :api-url="apiUrl"
        :segments-list="abData.abResultsData?.segmentsList"
        :segments-connected="abData.abResultsData?.segments || []"
        :game="game"
        :test-id="form.id"
        @refresh="loadAbResults($event)"
      />
      <HiPoQueryDrawer
        v-model:show-query-drawer="abData.showQueryDrawer"
        :query="abData.editQuery"
        :api-url="apiUrl"
        :game="game"
        :query-list="abData.abResultsData.queriesList"
        @refresh="onRefreshQueries($event)"
      />
    </template>
  </el-tab-pane>
</template>

<style scoped>
.query-field:deep(.el-form-item) {
  .el-form-item {
    &__label {
      padding: 0 0 0 5px !important;
    }

    &__content {
      position: relative;
      padding-left: 5px;
    }
  }
}
</style>

<script setup>
import { defineProps, inject } from 'vue'
import CrudFieldFile from '!/components/forms/crud-fields/CrudFieldFile.vue'

defineProps({
  groupLabel: {
    type: String,
    default: ''
  }
})
const form = inject('crudForm', {})
</script>

<template>
  <template v-if="form.testType === 'ABTest' && !['Idea', 'Voting', 'Canceled'].includes(form.status)">
    <el-tab-pane
      v-for="group in 4"
      :key="group"
      :name="`${groupLabel}-${group}`"
      class="mx-[2px]"
    >
      <template #label>
        <span class="capitalize">AB {{ groupLabel }} {{ group }}</span>
        <icon-ify
          v-if="form.segmentPercentage[group - 1]"
          icon="mdi:check-bold"
          class="inline-block"
        />
      </template>
      <template #default>
        <el-row
          :gutter="40"
        >
          <crud-field-number
            :api-field-name="`segmentPercentage.${group - 1}`"
            label="Percentage"
            :sm="4"
            col-break
          />
          <crud-field-textarea
            :api-field-name="`segmentDescription.${group - 1}`"
            label="Description"
            :sm="8"
          />
          <crud-field-textarea
            :api-field-name="`segmentPlayers.${group - 1}`"
            :sm="8"
            :rules="[
              {
                type: 'string',
                validator: (_, value) => {
                  if (String(value).trim() === '') {
                    return true
                  }

                  return !value.split('\n').some((el) => {
                    const checkEl = String(el).trim()
                    if (checkEl === '') {
                      return false
                    }
                    return !/^(dev|prod)?[1-9]\d*$/.test(checkEl)
                  })

                },
                trigger: 'change',
                message: 'only integers > 0 (\'dev\' or \'prod\' prefix allowed); each in new line;'
              }
            ]"
          >
            <template #label>
              Force player
              <el-tooltip>
                <template #content>
                  ID of players, each in new line,<br> forced to be assigned to this segment.<br>
                  Add "dev" or "prod" prefix for specific environment. <br>For example:<br>
                  12332<br>
                  dev2311<br>
                  prod553
                </template>
                <icon-ify
                  icon="bxs:help-circle"
                  class="inline-block text-sm"
                />
              </el-tooltip>
            </template>
          </crud-field-textarea>
          <crud-field-textarea
            :api-field-name="`segmentCondition.${group - 1}`"
            :sm="8"
            col-break
          >
            <template #label>
              Advanced condition
              <el-tooltip>
                <template #content>
                  Example:<br>(player.country in ["PL", "EN"] and player.createdDays &lt;= 7) <br>or (player.new and player.total_spend > 1000)
                </template>
                <icon-ify
                  icon="bxs:help-circle"
                  class="inline-block text-sm"
                />
              </el-tooltip>
            </template>
          </crud-field-textarea>
          <CrudFieldFile
            v-for="n in 2"
            :key="`${group}_${n}`"
            :sm="3"
            :api-field-name="`segmentScreenShot${n}.${group - 1}`"
            :label="`ScreenShot ${n}`"
            :upload-title="`Upload ScreenShot ${n}`"
          />
        </el-row>
      </template>
    </el-tab-pane>
  </template>
</template>

<style scoped>

</style>

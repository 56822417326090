<script>
import dayjs from 'dayjs'
import { ChatLineSquare, Warning } from '@element-plus/icons-vue'
import PayersTooltip from '@/components/pages/overwatcher/PayersTooltip.vue'
import GuildTooltip from '@/components/pages/overwatcher/GuildTooltip.vue'
import PlayerDrawer from '!/components/pages/gameplay/watcher/PlayerDrawer.vue'
import GuildDrawer from '!/components/pages/gameplay/watcher/GuildDrawer.vue'

function sortPlayers(sort, rows) {
  if (sort === 'Impact') {
    rows.sort((a, b) => {
      return a.Difference < b.Difference ? -1 : 1
    })
  } else if (sort === 'Best') {
    rows.sort((a, b) => {
      return a.Difference > b.Difference ? -1 : 1
    })
  } else if (sort === 'Churned') {
    rows.sort((a, b) => {
      if (!a.ChurnedImpact && !b.ChurnedImpact) {
        return a.Difference < b.Difference ? -1 : 1
      }
      return a.ChurnedImpact < b.ChurnedImpact ? -1 : 1
    })
  } else if (sort === 'New') {
    rows.sort((a, b) => {
      if (!a.New && !b.New) {
        return a.Difference < b.Difference ? -1 : 1
      }
      return a.New > b.New ? -1 : 1
    })
  } else if (sort === 'Zombie') {
    rows.sort((a, b) => {
      if (!a.Zombie && !b.Zombie) {
        return a.Difference < b.Difference ? -1 : 1
      }
      return a.ZombieImpact < b.ZombieImpact ? -1 : 1
    })
  } else if (sort === 'Worse') {
    rows.sort((a, b) => {
      if (!a.Worse && !b.Worse) {
        return a.Difference < b.Difference ? -1 : 1
      }
      return a.WorseImpact < b.WorseImpact ? -1 : 1
    })
  }
}

export default {
  components: [PlayerDrawer, GuildDrawer, PayersTooltip, GuildTooltip],
  setup() {
    return {
      dayjs,
      gamesEnvs: {
        // http://localhost:8097
        snr: 'https://dev.sw-api-gamesture.com',
        gang: 'https://dev.gang-api-gamesture.com',
        soccer: 'https://soccer-dev.netto-api-gamesture.com',
        mafia: 'https://mafia-dev.netto-api-gamesture.com'
      },
      gamesOptions: [
        { value: 'snr', label: 'Slash&Roll' },
        { value: 'gang', label: 'The Gang' },
        { value: 'soccer', label: 'Soccer Clash' },
        { value: 'mafia', label: 'Mafia Rivals' }
      ],
      Warning,
      ChatLineSquare
    }
  },
  data() {
    return {
      spenderSizes: [0, 50, 100, 200, 500, 1000, 2500, 5000, 10000, 15000],
      searchPlayer: '',
      searchGuildInGuild: '',
      searchGuild: '',
      apiData: {},
      churnedDays: 7,
      similarPayment: 10,
      filterTotalPaid: 0,
      filterCreated: 0,
      tableGroup: 'All',
      guildSort: 'Impact',
      guildGroup: 'All',
      tableGroupPaidSize: 'All',
      actualPlayer: null,
      showDrawerPlayer: false,
      actualGuild: null,
      showDrawerGuild: false,
      whaleSpent: 1000,
      dolphinSpent: 200,
      fishSpent: 50,
      searchFields: { group: 'Seasons', env: 'snr' },
      tabModel: 'payers',
      customDates: {
        visible: false,
        date1Start: '',
        date1End: '',
        date2Start: '',
        date2End: ''
      }
    }
  },
  computed: {
    payersTotal() {
      const data = []
      if (!this.apiData?.payers) {
        return data
      }
      let totalPayers = 0
      let totalPayersBefore = 0
      let paidNow = 0
      let paidBefore = 0
      let newPayers = 0
      let newPayersPaid = 0
      let churnedPayers = 0
      let churnedPayersPaid = 0
      let churnedPayersPaidBefore = 0
      let newZombiePayers = 0
      let newZombiePayersPaid = 0
      let newZombiePayersPaidBefore = 0
      let recoveredPayers = 0
      let recoveredPayersPaid = 0
      let zombiePayers = 0
      let improvedPayers = 0
      let improvedPayersPaid = 0
      let improvedPayersPaidBefore = 0
      let similarPayers = 0
      let similarPayersPaid = 0
      let similarPayersPaidBefore = 0
      let worsePayers = 0
      let worsePayersPaid = 0
      let worsePayersPaidBefore = 0
      const groupSpenders = {}
      const groupSpendersNew = {}
      const groupSpendersChurned = {}
      const groupSpendersNewZombie = {}
      const groupSpendersZombie = {}
      const groupSpendersRecovered = {}
      const groupSpendersImproved = {}
      const groupSpendersSimilar = {}
      const groupSpendersWorse = {}
      for (const player of this.apiData.payers) {
        if (this.filterTotalPaid > 0 && player.PaidTotal < this.filterTotalPaid) {
          continue
        }
        if (this.filterCreated > 0 && player.Created / 30 < this.filterCreated) {
          continue
        }
        this.groupBySpend(groupSpenders, player)
        if (player.PaidNow > 0) {
          paidNow += player.PaidNow
          totalPayers++
        }
        if (player.PaidBefore > 0) {
          paidBefore += player.PaidBefore
          totalPayersBefore++
        }
        if (player.New) {
          newPayersPaid += player.PaidNow
          newPayers++
          this.groupBySpend(groupSpendersNew, player, true)
        } else if (player.Active > this.churnedDays) {
          churnedPayersPaid += player.PaidNow
          churnedPayersPaidBefore += player.PaidBefore
          churnedPayers++
          this.groupBySpend(groupSpendersChurned, player, true, true)
        } else if (player.PaidNow === 0 && player.PaidBefore > 0) {
          newZombiePayersPaid += player.PaidNow
          newZombiePayersPaidBefore += player.PaidBefore
          newZombiePayers++
          zombiePayers++
          this.groupBySpend(groupSpendersNewZombie, player, true, true)
        } else if (player.PaidNow === 0 && player.PaidBefore === 0) {
          zombiePayers++
          this.groupBySpend(groupSpendersZombie, player, true, true)
        } else if (player.PaidNow > player.PaidBefore && player.PaidBefore === 0) {
          recoveredPayersPaid += player.PaidNow
          recoveredPayers++
          this.groupBySpend(groupSpendersRecovered, player)
        } else if (
          player.PaidNow > 0 &&
          player.PaidBefore > 0 &&
          Math.abs((player.PaidNow - player.PaidBefore) / player.PaidBefore) < this.similarPayment / 100
        ) {
          similarPayersPaid += player.PaidNow
          similarPayersPaidBefore += player.PaidBefore
          similarPayers++
          this.groupBySpend(groupSpendersSimilar, player)
        } else if (player.PaidNow > player.PaidBefore) {
          improvedPayersPaid += player.PaidNow
          improvedPayersPaidBefore += player.PaidBefore
          improvedPayers++
          this.groupBySpend(groupSpendersImproved, player)
        } else if (
          player.PaidNow > 0 &&
          player.PaidBefore > 0 &&
          Math.abs((player.PaidNow - player.PaidBefore) / player.PaidBefore) >= this.similarPayment / 100
        ) {
          worsePayersPaid += player.PaidNow
          worsePayersPaidBefore += player.PaidBefore
          worsePayers++
          this.groupBySpend(groupSpendersWorse, player)
        } else {
          // eslint-disable-next-line no-console
          console.log('strange player')
        }
      }
      data.push({
        type: 'All',
        class: 'font-bold',
        payersNow: totalPayers,
        payersBefore: totalPayersBefore,
        payersDiff: totalPayers - totalPayersBefore,
        payersDiffPercentage:
          totalPayersBefore > 0 ? Math.round(((totalPayers - totalPayersBefore) / totalPayersBefore) * 100) : 0,
        paidNow,
        paidBefore,
        paidDiff: paidNow - paidBefore,
        paidDiffPercentage: paidBefore > 0 ? Math.round(((paidNow - paidBefore) / paidBefore) * 100) : 0,
        groupSpenders: Object.values(groupSpenders).sort((a, b) => {
          return a.paidNow > b.paidNow ? -1 : 1
        })
      })
      data.push({
        type: 'New',
        tooltip: 'Number of players that spent min 1 dollar in current period and created account in this period',
        payersNow: newPayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: newPayersPaid,
        paidBefore: 0,
        paidDiff: newPayersPaid,
        paidDiffPercentage: 0,
        groupSpenders: Object.values(groupSpendersNew).sort((a, b) => {
          return a.paidNow > b.paidNow ? -1 : 1
        })
      })
      data.push({
        type: 'Churned',
        tooltip: `Number of players that spent min 1 dollar in both periods and logged into games later than ${this.churnedDays} days ago`,
        payersNow: churnedPayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: churnedPayersPaid,
        paidBefore: churnedPayersPaidBefore,
        paidDiff: churnedPayersPaid - churnedPayersPaidBefore,
        paidDiffPercentage:
          churnedPayersPaidBefore > 0
            ? Math.round(((churnedPayersPaid - churnedPayersPaidBefore) / churnedPayersPaidBefore) * 100)
            : 0,
        groupSpenders: Object.values(groupSpendersChurned).sort((a, b) => {
          return a.paidDiff > b.paidDiff ? 1 : -1
        })
      })
      data.push({
        type: 'New Zombie',
        tooltip: 'Number of players that spent some money in previous period but stopped paying in this period',
        payersNow: newZombiePayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: newZombiePayersPaid,
        paidBefore: newZombiePayersPaidBefore,
        paidDiff: newZombiePayersPaid - newZombiePayersPaidBefore,
        paidDiffPercentage:
          newZombiePayersPaidBefore > 0
            ? Math.round(((newZombiePayersPaid - newZombiePayersPaidBefore) / newZombiePayersPaidBefore) * 100)
            : 0,
        groupSpenders: Object.values(groupSpendersNewZombie).sort((a, b) => {
          return a.paidDiff > b.paidDiff ? 1 : -1
        })
      })
      data.push({
        type: 'Zombie',
        tooltip:
          'Number of players that spent min 100$ in the past but never in previous and current and are still playing the game',
        payersNow: zombiePayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: 0,
        paidBefore: 0,
        paidDiff: 0,
        paidDiffPercentage: 0,
        groupSpenders: Object.values(groupSpendersZombie).sort((a, b) => {
          return a.paidDiff > b.paidDiff ? 1 : -1
        })
      })
      data.push({
        type: 'Recovered',
        tooltip: 'Number of players that didn\'t spent any money on previous period and spent some in current period',
        payersNow: recoveredPayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: recoveredPayersPaid,
        paidBefore: 0,
        paidDiff: recoveredPayersPaid,
        paidDiffPercentage: 0,
        groupSpenders: Object.values(groupSpendersRecovered).sort((a, b) => {
          return a.paidNow > b.paidNow ? -1 : 1
        })
      })
      data.push({
        type: 'Improved',
        tooltip: 'Number of players that spent more money in current period compared to previous one',
        payersNow: improvedPayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: improvedPayersPaid,
        paidBefore: improvedPayersPaidBefore,
        paidDiff: improvedPayersPaid - improvedPayersPaidBefore,
        paidDiffPercentage:
          improvedPayersPaidBefore > 0
            ? Math.round(((improvedPayersPaid - improvedPayersPaidBefore) / improvedPayersPaidBefore) * 100)
            : 0,
        groupSpenders: Object.values(groupSpendersImproved).sort((a, b) => {
          return a.paidNow > b.paidNow ? -1 : 1
        })
      })
      data.push({
        type: 'Similar',
        tooltip: `Number of players that spent min 1 dollar in both periods and difference is less than ${this.similarPayment}%`,
        payersNow: similarPayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: similarPayersPaid,
        paidBefore: similarPayersPaidBefore,
        paidDiff: similarPayersPaid - similarPayersPaidBefore,
        paidDiffPercentage:
          similarPayersPaidBefore > 0
            ? Math.round(((similarPayersPaid - similarPayersPaidBefore) / similarPayersPaidBefore) * 100)
            : 0,
        groupSpenders: Object.values(groupSpendersSimilar).sort((a, b) => {
          return a.paidNow > b.paidNow ? -1 : 1
        })
      })
      data.push({
        type: 'Worse',
        tooltip: `Number of players that spent min 1 dollar in both periods but less in current one and difference is bigger than ${this.similarPayment}%`,
        payersNow: worsePayers,
        payersBefore: 0,
        payersDiff: 0,
        payersDiffPercentage: 0,
        paidNow: worsePayersPaid,
        paidBefore: worsePayersPaidBefore,
        paidDiff: worsePayersPaid - worsePayersPaidBefore,
        paidDiffPercentage:
          worsePayersPaidBefore > 0 ? Math.round(((worsePayersPaid - worsePayersPaidBefore) / worsePayersPaidBefore) * 100) : 0,
        groupSpenders: Object.values(groupSpendersWorse).sort((a, b) => {
          return a.paidDiff > b.paidDiff ? 1 : -1
        })
      })
      return data
    },
    guilds() {
      let rows = []
      if (!this.payers.length) {
        return rows
      }
      const mapping = {}
      for (const player of this.payers) {
        if (!player.guild) {
          continue
        }
        if (!player.PaidBefore && !player.PaidNow) {
          continue
        }
        if (this.guildGroup === 'Search') {
          if (this.searchGuildInGuild !== '') {
            const id = Number.parseInt(this.searchGuildInGuild)
            if (id) {
              if (player.guild.ID !== id) {
                continue
              }
            } else {
              if (player.guild.Name.toLowerCase().trim() !== this.searchGuildInGuild.toLowerCase().trim()) {
                continue
              }
            }
          } else {
            continue
          }
        }

        const id = player.guild.ID
        let data = mapping[id]

        if (data === undefined) {
          data = { ID: id, Name: player.guild.Name, Country: player.guild.Country, Zone: player.guild.Zone, Group: player.guild.Group, Payers: 0, PaidTotal: 0, PaidBefore: 0, PaidNow: 0, Difference: 0, New: 0, NewImpact: 0, Churned: 0, ChurnedImpact: 0, Zombie: 0, ZombieImpact: 0, Worse: 0, WorseImpact: 0, Recovered: 0, RecoveredImpact: 0, Improved: 0, ImprovedImpact: 0, Similar: 0, SimilarImpact: 0, Other: 0, OtherImpact: 0, Division: player.guild.Division, payers: [] }
          data.guild = player.guild
          data.Comments = player.guild.Comments
          mapping[id] = data
          rows.push(data)
        }
        if (player.Status !== 'churned') {
          data.Payers++
        }
        data.PaidTotal += player.PaidTotal
        data.PaidBefore += player.PaidBefore
        data.PaidNow += player.PaidNow
        data.Difference += (player.PaidNow - player.PaidBefore)
        if (player.Status === 'new') {
          data.New++
          data.NewImpact += player.PaidNow
        } else if (player.Status === 'churned') {
          data.Churned++
          data.ChurnedImpact += (player.PaidNow - player.PaidBefore)
        } else if (player.Status === 'newZombie') {
          data.Zombie++
          data.ZombieImpact += (player.PaidNow - player.PaidBefore)
        } else if (player.Status === 'worse') {
          data.Worse++
          data.WorseImpact += (player.PaidNow - player.PaidBefore)
        } else if (player.Status === 'recovered') {
          data.Recovered++
          data.RecoveredImpact += (player.PaidNow - player.PaidBefore)
          data.Other++
          data.OtherImpact += (player.PaidNow - player.PaidBefore)
        } else if (player.Status === 'improved') {
          data.Improved++
          data.ImprovedImpact += (player.PaidNow - player.PaidBefore)
          data.Other++
          data.OtherImpact += (player.PaidNow - player.PaidBefore)
        } else if (player.Status === 'similar') {
          data.Similar++
          data.SimilarImpact += (player.PaidNow - player.PaidBefore)
          data.Other++
          data.OtherImpact += (player.PaidNow - player.PaidBefore)
        }
        data.payers.push(player)
      }
      const paidColorsLimits = [50000, 25000, 15000, 5000, 0]
      const paidColors = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
      const paidBeforeColorsLimits = [10000, 5000, 2500, 1000, 0]
      const paidBeforeColors = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
      for (const row of rows) {
        for (const i in paidColorsLimits) {
          if (row.PaidTotal >= paidColorsLimits[i]) {
            row.PaidTotalColor = paidColors[i]
            break
          }
        }
        for (const i in paidBeforeColorsLimits) {
          if (row.PaidBefore >= paidBeforeColorsLimits[i]) {
            row.PaidBeforeColor = paidBeforeColors[i]
            break
          }
        }
        for (const i in paidBeforeColorsLimits) {
          if (row.PaidNow >= paidBeforeColorsLimits[i]) {
            row.PaidNowColor = paidBeforeColors[i]
            break
          }
        }
      }

      if (this.guildGroup === 'Leagues') {
        const used = {}
        const newRows = []
        for (const row of rows) {
          const group = row.Group
          if (used[group] === undefined) {
            used[group] = { header: true, Name: `League ${group}`, Group: group, Division: row.Division, Payers: 0, PaidTotal: 0, PaidBefore: 0, PaidNow: 0, Difference: 0, PaidBeforeSpread: [0, 0, 0] }
            newRows.push(used[group])
            let maxPaidBefore = 0
            for (const row2 of rows) {
              if (row2.Group === row.Group) {
                maxPaidBefore = Math.max(row2.PaidBefore, maxPaidBefore)
                used[group].Payers += row2.Payers
                used[group].PaidTotal += row2.PaidTotal
                used[group].PaidBefore += row2.PaidBefore
                used[group].PaidNow += row2.PaidNow
                used[group].Difference += row2.Difference
              }
            }
            if (maxPaidBefore > 0) {
              for (const row2 of rows) {
                if (row2.Group === row.Group) {
                  const diff = row2.PaidBefore / maxPaidBefore
                  if (diff >= 0.66) {
                    used[group].PaidBeforeSpread[0]++
                  } else if (diff >= 0.33) {
                    used[group].PaidBeforeSpread[1]++
                  } else {
                    used[group].PaidBeforeSpread[2]++
                  }
                }
              }
            }
          }
        }
        const paidColorsLimitsLeagues = [150000, 75000, 45000, 15000, 0]
        const paidColorsLeagues = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
        const paidBeforeColorsLimitsLeagues = [30000, 15000, 7500, 3000, 0]
        const paidBeforeColorsLeagues = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
        for (const row of newRows) {
          for (const i in paidColorsLimitsLeagues) {
            if (row.PaidTotal >= paidColorsLimitsLeagues[i]) {
              row.PaidTotalColor = paidColorsLeagues[i]
              break
            }
            for (const i in paidBeforeColorsLimitsLeagues) {
              if (row.PaidBefore >= paidBeforeColorsLimitsLeagues[i]) {
                row.PaidBeforeColor = paidBeforeColorsLeagues[i]
                break
              }
            }
            for (const i in paidBeforeColorsLimitsLeagues) {
              if (row.PaidNow >= paidBeforeColorsLimitsLeagues[i]) {
                row.PaidNowColor = paidBeforeColorsLeagues[i]
                break
              }
            }
          }
        }
        sortPlayers(this.guildSort, newRows)
        const map = {}
        const finalRows = []
        for (const row of newRows) {
          map[row.Group] = []
          finalRows.push(row)
          for (const row2 of rows) {
            if (row.Group === row2.Group) {
              map[row.Group].push(row2)
            }
          }
          sortPlayers(this.guildSort, map[row.Group])
          finalRows.push(...map[row.Group])
        }
        rows = finalRows
      } else {
        sortPlayers(this.guildSort, rows)
      }

      return rows
    },
    payers() {
      const rows = []
      if (!this.apiData.payers) {
        return rows
      }
      const createdColorsLimits = [729, 540, 360, 130, 90, 0]
      const createdColors = ['#1b468c', '#3a609e', '#5b78a8', '#7992bd', '#9daecc', '#ccd8ed']
      const paidColorsLimits = [10000, 5000, 3000, 1000, 0]
      const paidColors = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
      const activeColorsLimits = [1, 0.7, 0.5, 0.3, 0]
      const activeColors = ['#9c3024', '#ab4a3f', '#bd7768', '#89ad51', '#669123']
      const paidBeforeColorsLimits = [2000, 1000, 500, 200, 0]
      const paidBeforeColors = ['#047504', '#2f7a2f', '#77b577', '#9bc99b', '#c3e6c3']
      const chatMessagesColorsLimits = [5000, 3000, 1000, 500, 100, 0]
      const chatMessagesColors = ['#b88a21', '#d1af62', '#d4c094', '#d4c8ae', '#d1ccc2', '#e3e1de']
      const battlesScoreColorsLimits = [0.9, 0.7, 0.5, 0.3, 0.2, 0]
      const battlesScoreColors = ['#024a02', '#1e871e', '#5ba85b', '#8fbf8f', '#bdd9bd', '#d3ded3']
      const battleScoreColorsLimits = [0.3, 0.2, 0.1, 0.05, 0.01, 0]
      const battleScoreColors = ['#9c3024', '#bd7768', '#bd7768', '#8fbf8f', '#bdd9bd', '#f2f4f7']
      const playerScoreColorsLimits = [0.3, 0.2, 0.1, 0.05, 0.01, 0]
      const playerScoreColors = ['#9c3024', '#bd7768', '#bd7768', '#8fbf8f', '#bdd9bd', '#f2f4f7']

      let maxScore = 0
      const payersMapping = {}
      for (const player of this.apiData.payers) {
        payersMapping[player.ID] = player
      }
      for (const player of this.apiData.payers) {
        if (this.filterTotalPaid > 0 && player.PaidTotal < this.filterTotalPaid) {
          continue
        }
        if (this.filterCreated > 0 && player.Created / 30 < this.filterCreated) {
          continue
        }
        if (this.tableGroup === 'Search') {
          if (this.searchPlayer !== '') {
            const id = Number.parseInt(this.searchPlayer)
            if (id) {
              if (player.ID !== id) {
                continue
              }
            } else {
              if (player.Name.toLowerCase().trim() !== this.searchPlayer.toLowerCase().trim()) {
                continue
              }
            }
          } else if (this.searchGuild !== '') {
            const id = Number.parseInt(this.searchGuild)
            if (id) {
              if (player.Guild !== id) {
                continue
              }
            } else {
              const guild = this.apiData.guilds[player.Guild]
              if (!guild) {
                continue
              }
              if (guild.Name.toLowerCase().trim() !== this.searchGuild.toLowerCase().trim()) {
                continue
              }
            }
          } else {
            continue
          }
        }
        const row = {}
        const guild = this.apiData.guilds[player.Guild]
        if (guild) {
          row.guild = {}
          row.guild.BattlesScoreSum = 0
          row.guild.PlayersScoreSum = 0
          row.guild.PlayersPaidSum = 0
          row.guild.PayersNow = []
          row.guild.PayersBefore = []
          row.Guild = guild.Name
          row.guild.Zone = guild.Zone
          row.guild.Division = guild.Division
          row.guild.Group = guild.Group
          row.guild.Country = guild.Country
          row.guild.Comments = guild.Notes
          for (const v of guild.BattlesScore) {
            row.guild.BattlesScoreSum += v
          }
          for (const v in guild.PlayersScore) {
            const score = guild.PlayersScore[v]
            row.guild.PlayersScoreSum += score
            let p = payersMapping[v]
            if (p === undefined) {
              p = { ID: v, PaidTotal: 0, PaidNow: 0 }
            }
            const data = {
              ID: p.ID,
              PaidTotal: p.PaidTotal,
              PaidNow: p.PaidNow,
              Score: score
            }
            row.guild.PlayersPaidSum += p.PaidNow
            if (p.Guild === player.Guild) {
              row.guild.PayersNow.push(data)
            } else {
              row.guild.PayersBefore.push(data)
            }
          }
          maxScore = Math.max(maxScore, row.guild.BattlesScoreSum)
        }
        if (player.New) {
          row.Status = 'new'
          row.StatusColor = 'green'
        } else if (player.Active > this.churnedDays) {
          row.Status = 'churned'
          row.StatusColor = 'red'
        } else if (player.PaidNow === 0 && player.PaidBefore > 0) {
          row.Status = 'newZombie'
          row.StatusColor = 'red'
        } else if (player.PaidNow === 0 && player.PaidBefore === 0) {
          row.Status = 'zombie'
          row.StatusColor = 'red'
        } else if (player.PaidNow > player.PaidBefore > 0 && player.PaidBefore === 0) {
          row.Status = 'recovered'
          row.StatusColor = 'green'
        } else if (player.PaidNow > player.PaidBefore > 0) {
          row.Status = 'improved'
          row.StatusColor = 'green'
        } else if (
          player.PaidNow > 0 &&
          player.PaidBefore > 0 &&
          Math.abs((player.PaidNow - player.PaidBefore) / player.PaidBefore) < this.similarPayment / 100
        ) {
          row.Status = 'similar'
          row.StatusColor = 'green'
        } else if (
          player.PaidNow > 0 &&
          player.PaidBefore > 0 &&
          Math.abs((player.PaidNow - player.PaidBefore) / player.PaidBefore) >= this.similarPayment / 100
        ) {
          row.Status = 'worse'
          row.StatusColor = 'orange'
        }

        if (this.tableGroupPaidSize !== 'All') {
          if (this.tableGroupPaidSize === 'Whale') {
            if (player.PaidTotal < this.whaleSpent) {
              continue
            }
          } else if (this.tableGroupPaidSize === 'Dolphin') {
            if (player.PaidTotal < this.dolphinSpent || player.PaidTotal >= this.whaleSpent) {
              continue
            }
          } else if (this.tableGroupPaidSize === 'Fish') {
            if (player.PaidTotal < this.fishSpent || player.PaidTotal >= this.dolphinSpent) {
              continue
            }
          } else if (this.tableGroupPaidSize === 'Plankton') {
            if (player.PaidTotal >= this.fishSpent) {
              continue
            }
          }
        }
        if (this.tableGroup !== 'All') {
          if (this.tableGroup === 'New') {
            if (row.Status !== 'new') {
              continue
            }
          } else if (this.tableGroup === 'Churned') {
            if (row.Status !== 'churned') {
              continue
            }
          } else if (this.tableGroup === 'New Zombie') {
            if (row.Status !== 'newZombie') {
              continue
            }
          } else if (this.tableGroup === 'Zombie') {
            if (row.Status !== 'zombie') {
              continue
            }
          } else if (this.tableGroup === 'Recovered') {
            if (row.Status !== 'recovered') {
              continue
            }
          } else if (this.tableGroup === 'Improved') {
            if (row.Status !== 'improved') {
              continue
            }
          } else if (this.tableGroup === 'Similar') {
            if (row.Status !== 'similar') {
              continue
            }
          } else if (this.tableGroup === 'Worse') {
            if (row.Status !== 'worse') {
              continue
            }
          }
        }
        row.Name = player.Name
        row.ID = player.ID
        row.Comments = player.Comments

        let createdLabel = ''
        let months = Number.parseInt(player.Created / 30)
        const years = Number.parseInt(player.Created / 360)
        if (years >= 1) {
          createdLabel = `${years}Y`
          months -= years * 12
        }
        if (months >= 1) {
          if (createdLabel !== '') {
            createdLabel += ' '
          }
          createdLabel += `${months}M`
        }

        row.Created = player.Created
        row.CreatedLabel = createdLabel
        row.PaidTotal = player.PaidTotal
        row.Active = player.Active
        row.PaidBefore = player.PaidBefore
        row.PaidNow = player.PaidNow
        row.Difference = player.PaidNow - player.PaidBefore
        if (guild) {
          row.guild.ID = player.Guild
          row.guild.Name = guild.Name
          row.guild.ChatMessages = guild.ChatMessages
          row.guild.BattlesPlayers = guild.BattlesPlayers
          row.guild.BattlesScore = []
          if (row.guild.BattlesScoreSum > 0) {
            for (const v of guild.BattlesScore) {
              const percentage = v / row.guild.BattlesScoreSum
              for (const i in battleScoreColorsLimits) {
                if (percentage >= battleScoreColorsLimits[i]) {
                  row.guild.BattlesScore.push({ color: battleScoreColors[i], value: v })
                  break
                }
              }
            }
          }
          if (row.guild.PlayersScoreSum > 0) {
            for (const v of row.guild.PayersNow) {
              const percentage = v.Score / row.guild.PlayersScoreSum
              for (const i in playerScoreColorsLimits) {
                if (percentage >= playerScoreColorsLimits[i]) {
                  v.color = playerScoreColors[i]
                  break
                }
              }
              const percentagePaid = v.PaidNow / row.guild.PlayersPaidSum
              for (const i in playerScoreColorsLimits) {
                if (percentagePaid >= playerScoreColorsLimits[i]) {
                  v.colorPaid = playerScoreColors[i]
                  break
                }
              }
            }
            for (const v of row.guild.PayersBefore) {
              const percentage = v.Score / row.guild.PlayersScoreSum
              for (const i in playerScoreColorsLimits) {
                if (percentage >= playerScoreColorsLimits[i]) {
                  v.color = playerScoreColors[i]
                  break
                }
              }
            }
          }
          for (const i in chatMessagesColorsLimits) {
            if (guild.ChatMessages >= createdColorsLimits[i]) {
              row.guild.ChatMessagesColor = chatMessagesColors[i]
              break
            }
          }
        }
        for (const i in createdColorsLimits) {
          if (row.Created >= createdColorsLimits[i]) {
            row.CreatedColor = createdColors[i]
            break
          }
        }
        for (const i in paidColorsLimits) {
          if (row.PaidTotal >= paidColorsLimits[i]) {
            row.PaidTotalColor = paidColors[i]
            break
          }
        }
        const activePercentage = row.Active / this.churnedDays
        for (const i in activeColorsLimits) {
          if (activePercentage >= activeColorsLimits[i]) {
            row.ActiveColor = activeColors[i]
            break
          }
        }
        for (const i in paidBeforeColorsLimits) {
          if (row.PaidBefore >= paidBeforeColorsLimits[i]) {
            row.PaidBeforeColor = paidBeforeColors[i]
            break
          }
        }
        for (const i in paidBeforeColorsLimits) {
          if (row.PaidNow >= paidBeforeColorsLimits[i]) {
            row.PaidNowColor = paidBeforeColors[i]
            break
          }
        }

        rows.push(row)
      }
      if (this.tableGroup === 'New' || this.tableGroup === 'Recovered' || this.tableGroup === 'Improved') {
        rows.reverse()
      } else if (this.tableGroup === 'Zombie') {
        rows.sort((a, b) => {
          return a.PaidTotal > b.PaidTotal ? -1 : 1
        })
      }
      for (const payer of rows) {
        if (!payer.guild) {
          continue
        }
        payer.guild.BattlesScoreSumPercentage = 0
        if (maxScore) {
          payer.guild.BattlesScoreSumPercentage = Math.round((payer.guild.BattlesScoreSum / maxScore) * 100)
        }
        payer.guild.BattlesScoreSum = Math.round(payer.guild.BattlesScoreSum / 1000000)
        for (const i in battlesScoreColorsLimits) {
          if (payer.guild.BattlesScoreSum >= battlesScoreColorsLimits[i]) {
            payer.guild.BattlesScoreSumColor = battlesScoreColors[i]
            break
          }
        }
      }
      return rows
    }
  },
  created() {
    if (this.$route.query?.c_sea?.visible === 'true') {
      this.customDates = {
        visible: true,
        date1Start: this.$route.query?.c_sea?.date1Start,
        date1End: this.$route.query?.c_sea?.date1End,
        date2Start: this.$route.query?.c_sea?.date2Start,
        date2End: this.$route.query?.c_sea?.date2End
      }
    } else {
      this.customDates = {
        visible: false,
        date1Start: '',
        date1End: '',
        date2Start: '',
        date2End: ''
      }
    }
  },
  methods: {
    groupBySpend(group, player, zeroPayersDiff, forcePayers) {
      let type = ''
      let tooltip = ''
      let sort = 0
      if (player.PaidTotal >= this.whaleSpent) {
        type = 'whale'
        tooltip = `Spent >= ${this.whaleSpent}$`
      } else if (player.PaidTotal >= this.dolphinSpent) {
        sort = 1
        type = 'doplhin'
        tooltip = `Spent >= ${this.dolphinSpent}$ and < ${this.whaleSpent}$`
      } else if (player.PaidTotal >= this.fishSpent) {
        sort = 2
        type = 'fish'
        tooltip = `Spent >= ${this.fishSpent}$ and < ${this.dolphinSpent}$`
      } else {
        sort = 3
        type = 'plankton'
        tooltip = `Spent < ${this.fishSpent}$`
      }
      if (group[type] === undefined) {
        group[type] = {
          type,
          tooltip,
          payersNow: 0,
          payersBefore: 0,
          paidBefore: 0,
          paidNow: 0,
          paidTotal: 0,
          payersDiff: 0,
          paidDiffPercentage: 0,
          payersDiffPercentage: 0,
          sort
        }
      }
      if (player.PaidNow > 0 || forcePayers) {
        group[type].payersNow++
      }
      group[type].paidBefore += player.PaidBefore
      group[type].paidNow += player.PaidNow
      group[type].paidTotal += player.PaidTotal
      if (player.PaidBefore > 0) {
        group[type].payersBefore++
      }
      group[type].paidDiff = group[type].paidNow - group[type].paidBefore
      if (!forcePayers) {
        group[type].paidDiffPercentage =
          group[type].paidBefore > 0
            ? Math.round(((group[type].paidNow - group[type].paidBefore) / group[type].paidBefore) * 100)
            : 0
      }
      if (!zeroPayersDiff) {
        group[type].payersDiff = group[type].payersNow - group[type].payersBefore
        group[type].payersDiffPercentage =
          group[type].payersBefore > 0
            ? Math.round(((group[type].payersNow - group[type].payersBefore) / group[type].payersBefore) * 100)
            : 0
      }
    },
    onLoadData(response) {
      this.apiData = this.$utils.cloneDeep(response)
    },
    showPlayerDrawer(data) {
      this.actualPlayer = {
        id: data?.ID,
        country: undefined,
        name: data?.Name,
        created: data?.Created,
        active: data?.Active,
        comments: data?.Comments
      }
      this.showDrawerPlayer = true
    },
    showGuildDrawer(data) {
      this.actualGuild = {
        id: data?.ID,
        country: undefined,
        name: data?.Name,
        created: data?.Created,
        active: data?.Active,
        comments: data?.Comments
      }
      this.showDrawerGuild = true
    },
    filterCustomDates(clear = false) {
      if (clear) {
        this.customDates.date1Start = ''
        this.customDates.date1End = ''
        this.customDates.date2Start = ''
        this.customDates.date2End = ''
      }
      if (
        clear ||
        (this.customDates.date1End && this.customDates.date2End && this.customDates.date1Start && this.customDates.date2End)
      ) {
        this.searchFields = { ...this.searchFields, ...this.customDates }
      }
    }
  }
}
</script>

<template>
  <crud-table
    api="proxy/request"
    :versioned="false"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    :proxy-request-params="{
      uri: '/admin/api/overwatcher/payers/',
      method: 'GET',
      env: 'snr',
      envsUrls: gamesEnvs
    }"
    :render-loaded-data="onLoadData"
    disable-pagination
    hide-columns-selector
    hide-clear-btn
    disable-multi-select-rows
    hide-bottom-action-bar
    :search-fields="searchFields"
  >
    <template #topLeft>
      <div class="flex items-center justify-between gap-4 flex-wrap w-full">
        <div class="flex gap-4 flex-grow">
          <div class="w-32 flex items-center">
            <crud-type-fields
              v-model:value-field="searchFields.env"
              type-value-field="select"
              full-width
              :options="gamesOptions"
              label="Game"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              :clearable="false"
            />
          </div>
          <div class="w-20 px-0.5">
            <crud-type-fields
              v-model:value-field="searchFields.offset"
              :disabled="customDates.visible"
              :min="-30"
              :max="0"
              type-value-field="int"
              full-width
              label="Season current"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              :init-value="0"
            />
          </div>
          <div class="w-20 px-0.5">
            <crud-type-fields
              v-model:value-field="searchFields.offset2"
              :disabled="customDates.visible"
              :min="-30"
              :max="-1"
              type-value-field="int"
              full-width
              label="Season before"
              :small-label="false"
              class-label="text-[0.65rem] pb-1"
              :init-value="-1"
            />
          </div>
          <div class="px-0.5 flex items-center">
            <crud-type-fields label="&nbsp;">
              <el-checkbox
                v-model="customDates.visible"
                size="small"
                @change="filterCustomDates(!$event)"
              >
                Custom dates
              </el-checkbox>
            </crud-type-fields>
          </div>
          <div
            v-show="customDates.visible"
            class="whitespace-nowrap"
          >
            <crud-type-fields label="&nbsp;">
              <el-date-picker
                v-model="customDates.date1Start"
                type="datetime"
                style="width: 125px"
                size="small"
                :clearable="false"
                format="YYYY-MM-DD HH"
                time-format="HH"
                popper-class="only-hours"
              />
              -
              <el-date-picker
                v-model="customDates.date1End"
                type="datetime"
                style="width: 125px"
                size="small"
                :clearable="false"
                format="YYYY-MM-DD HH"
                time-format="HH"
                popper-class="only-hours"
              />
            </crud-type-fields>
          </div>
          <div
            v-show="customDates.visible"
            class="whitespace-nowrap"
          >
            <crud-type-fields label="&nbsp;">
              <el-date-picker
                v-model="customDates.date2Start"
                type="datetime"
                style="width: 125px"
                size="small"
                :clearable="false"
                format="YYYY-MM-DD HH"
                time-format="HH"
                popper-class="only-hours"
              />
              -
              <el-date-picker
                v-model="customDates.date2End"
                type="datetime"
                style="width: 125px"
                size="small"
                :clearable="false"
                format="YYYY-MM-DD HH"
                time-format="HH"
                popper-class="only-hours"
              />
            </crud-type-fields>
          </div>
          <div
            v-show="customDates.visible"
            class="-ml-2"
          >
            <crud-type-fields label="&nbsp;">
              <el-button
                :size="$store.getters['auth/userScaledMediumSize']"
                class="gs-btn-outlined-info-light gs-loading gs-font-scaled mx-1 pl-1 pr-2"
                :disabled="!customDates.date1Start || !customDates.date1End || !customDates.date2Start || !customDates.date2End"
                @click="filterCustomDates(false)"
              >
                <icon-ify
                  icon="iconoir:filter"
                  class="gs-scaled-icon-xs mr-0.5"
                />
                filter dates
              </el-button>
            </crud-type-fields>
          </div>
        </div>
        <div
          v-if="!apiData?.error && apiData?.dates"
          class="inline-block"
        >
          <div
            v-show="apiData.season1"
            class="info-box inline-block pr-5"
          >
            <div class="text-left">
              Seasons
            </div>
            <div class="values">
              {{ apiData.season1 }}/{{ apiData.season2 }}
            </div>
            <div class="values">
              {{ apiData.days }} days
            </div>
          </div>
          <div class="info-box inline-block px-5">
            <div class="text-left">
              Dates EU
              <el-tooltip>
                <template #content>
                  <div
                    v-for="(dates, zone) in apiData.dates"
                    :key="zone"
                    class="inline-block"
                  >
                    <div class="info-box inline-block px-5">
                      <div class="text-left">
                        {{ zone }}
                      </div>
                      <div class="values">
                        {{ `${dayjs(dates[0]).format('DD MMM HH:mm')}-${dayjs(dates[1]).format('DD MMM HH:mm')}` }}
                      </div>
                      <div class="values">
                        {{ `${dayjs(dates[2]).format('DD MMM HH:mm')}-${dayjs(dates[3]).format('DD MMM HH:mm')}` }}
                      </div>
                    </div>
                  </div>
                </template>
                <el-icon
                  style="margin-left: 4px"
                  :size="12"
                >
                  <component :is="Warning" />
                  <!--                  <Warning /> -->
                </el-icon>
              </el-tooltip>
            </div>
            <div class="values">
              {{
                `${dayjs(apiData.dates.Europe[0]).format('DD MMM HH:mm')
                } - ${
                  dayjs(apiData.dates.Europe[1]).format('DD MMM HH:mm')}`
              }}
            </div>
            <div class="values">
              {{
                `${dayjs(apiData.dates.Europe[2]).format('DD MMM HH:mm')
                } - ${
                  dayjs(apiData.dates.Europe[3]).format('DD MMM HH:mm')}`
              }}
            </div>
          </div>
        </div>
        <div
          v-if="apiData?.error"
          class="red"
        >
          {{ apiData.error }}
        </div>
      </div>
    </template>
    <template #table="{ loading }">
      <div v-if="loading">
        loading...
      </div>
      <div v-else-if="!loading && apiData?.players !== undefined">
        no data
      </div>
      <div v-show="!loading">
        <el-tabs
          v-model="tabModel"
        >
          <el-tab-pane
            label="Payers"
            name="payers"
          >
            <template #default>
              <el-row>
                <el-col>
                  <div class="flex items-start gap-4 flex-col 2xl:flex-row">
                    <div class="w-full">
                      <div class="flex flex-wrap gap-4 mb-5">
                        <div>
                          <div>churn D:</div>
                          <el-input-number
                            v-model="churnedDays"
                            :min="1"
                            :max="60"
                            size="small"
                            class="w-20"
                          />
                        </div>
                        <div>
                          <div>similar %:</div>
                          <el-input-number
                            v-model="similarPayment"
                            :min="1"
                            :max="80"
                            size="small"
                            class="w-20"
                          />
                        </div>
                        <div>
                          <div>spend:</div>
                          <el-input-number
                            v-model="filterTotalPaid"
                            :min="0"
                            :max="30000"
                            size="small"
                            class="w-20"
                            :step="10"
                          />
                        </div>
                        <div>
                          <div>created:</div>
                          <el-input-number
                            v-model="filterCreated"
                            :min="0"
                            :max="48"
                            size="small"
                            class="w-20"
                          />
                        </div>
                        <div>
                          <div>whale:</div>
                          <el-input-number
                            v-model="whaleSpent"
                            :min="1"
                            :max="50000"
                            size="small"
                            class="w-20"
                          />
                        </div>
                        <div>
                          <div>dolphin:</div>
                          <el-input-number
                            v-model="dolphinSpent"
                            :min="1"
                            :max="50000"
                            size="small"
                            class="w-20"
                          />
                        </div>
                        <div>
                          <div>fish:</div>
                          <el-input-number
                            v-model="fishSpent"
                            :min="1"
                            :max="50000"
                            size="small"
                            class="w-20"
                          />
                        </div>
                      </div>
                      <el-table
                        v-if="payersTotal.length"
                        :data="payersTotal"
                        class="pb-5"
                        size="small"
                      >
                        <el-table-column type="expand">
                          <template #default="props">
                            <el-table
                              :data="props.row.groupSpenders"
                              size="small"
                              :show-header="false"
                              class-name="ml-10"
                            >
                              <el-table-column
                                prop="type"
                                width="180"
                                label="Type"
                              >
                                <template #default="{ row }">
                                  <span :class="row.class">{{ row.type }}</span>
                                  <el-tooltip
                                    v-if="row.tooltip"
                                    effect="dark"
                                    :content="row.tooltip"
                                    placement="top"
                                  >
                                    <el-icon
                                      style="margin-left: 4px"
                                      :size="12"
                                    >
                                      <component :is="Warning" />
                                      <!--                                <Warning /> -->
                                    </el-icon>
                                  </el-tooltip>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="payersNow"
                                width="180"
                                label="Payers"
                              >
                                <template #default="{ row }">
                                  <span :class="row.class">{{ row.payersNow }}</span>
                                  <span
                                    v-if="row.payersDiff !== 0"
                                    :class="[row.payersDiff > 0 ? 'green' : 'red', row.class]"
                                    class="ml-1"
                                  >
                                    ({{ row.payersDiff > 0 ? '+' : '' }}{{ row.payersDiff }}
                                    {{ row.payersDiffPercentage !== 0 ? `|${row.payersDiffPercentage}%` : '' }})
                                  </span>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="paidNow"
                                width="180"
                                label="Spent now"
                              >
                                <template #default="{ row }">
                                  <span :class="row.class">{{ $utils.numberFormat(row.paidNow) }}$</span>
                                  <span
                                    v-if="row.paidDiff !== 0"
                                    :class="[row.paidDiff > 0 ? 'green' : 'red', row.class]"
                                    class="ml-1"
                                  >
                                    {{
                                      row.paidDiffPercentage !== 0
                                        ? `(${row.paidDiffPercentage > 0 ? '+' : ''}${row.paidDiffPercentage}%)`
                                        : ''
                                    }}
                                  </span>
                                </template>
                              </el-table-column>
                              <el-table-column
                                prop="impact"
                                label="Income impact"
                              >
                                <template #default="{ row }">
                                  <span
                                    :class="[row.paidDiff >= 0 ? 'green' : 'red', row.class]"
                                    class="ml-1"
                                  >
                                    {{ (row.paidDiff > 0 ? '+' : '') + $utils.numberFormat(row.paidDiff) }}$
                                  </span>
                                </template>
                              </el-table-column>
                            </el-table>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="type"
                          width="180"
                          label="Type"
                        >
                          <template #default="{ row }">
                            <span :class="row.class">{{ row.type }}</span>
                            <el-tooltip
                              v-if="row.tooltip"
                              effect="dark"
                              :content="row.tooltip"
                              placement="top"
                            >
                              <el-icon
                                style="margin-left: 4px"
                                :size="12"
                              >
                                <component :is="Warning" />
                              </el-icon>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="payersNow"
                          width="180"
                          label="Payers"
                        >
                          <template #default="{ row }">
                            <span :class="row.class">{{ row.payersNow }}</span>
                            <span
                              v-if="row.payersDiff !== 0"
                              :class="[row.payersDiff > 0 ? 'green' : 'red', row.class]"
                              class="ml-1"
                            >
                              ({{ row.payersDiff > 0 ? '+' : '' }}{{ row.payersDiff }}
                              {{ row.payersDiffPercentage !== 0 ? `|${row.payersDiffPercentage}%` : '' }})
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="paidNow"
                          width="180"
                          label="Spent now"
                        >
                          <template #default="{ row }">
                            <span :class="row.class">{{ $utils.numberFormat(row.paidNow) }}$</span>
                            <span
                              v-if="row.paidDiff !== 0"
                              :class="[row.paidDiff > 0 ? 'green' : 'red', row.class]"
                              class="ml-1"
                            >
                              {{
                                row.paidDiffPercentage !== 0
                                  ? `(${row.paidDiffPercentage > 0 ? '+' : ''}${row.paidDiffPercentage}%)`
                                  : ''
                              }}
                            </span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="impact"
                          label="Income impact"
                        >
                          <template #default="{ row }">
                            <span
                              :class="[row.paidDiff >= 0 ? 'green' : 'red', row.class]"
                              class="ml-1"
                            >
                              {{ (row.paidDiff > 0 ? '+' : '') + $utils.numberFormat(row.paidDiff) }}$
                            </span>
                          </template>
                        </el-table-column>
                      </el-table>
                      <el-row>
                        <div class="w-500 p-4 pt-4">
                          <el-radio-group
                            v-model="tableGroup"
                            size="small"
                          >
                            <el-radio-button
                              label="All"
                              value="All"
                            />
                            <el-radio-button
                              label="New"
                              value="New"
                            />
                            <el-radio-button
                              label="Churned"
                              value="Churned"
                            />
                            <el-radio-button
                              label="New Zombie"
                              value="New Zombie"
                            />
                            <el-radio-button
                              label="Recovered"
                              value="Recovered"
                            />
                            <el-radio-button
                              label="Improved"
                              value="Improved"
                            />
                            <el-radio-button
                              label="Similar"
                              value="Similar"
                            />
                            <el-radio-button
                              label="Worse"
                              value="Worse"
                            />
                            <el-radio-button
                              label="Zombie"
                              value="Zombie"
                            />
                            <el-radio-button
                              label="Search"
                              value="Search"
                            />
                          </el-radio-group>
                          <el-radio-group
                            v-model="tableGroupPaidSize"
                            size="small"
                            class="ml-3"
                          >
                            <el-radio-button
                              label="All"
                              value="All"
                            />
                            <el-radio-button
                              label="Whale"
                              value="Whale"
                            />
                            <el-radio-button
                              label="Doplhin"
                              value="Doplhin"
                            />
                            <el-radio-button
                              label="Fish"
                              value="Fish"
                            />
                            <el-radio-button
                              label="Plankton"
                              value="Plankton"
                            />
                          </el-radio-group>
                        </div>
                      </el-row>
                      <div v-show="tableGroup === 'Search'">
                        <el-input
                          v-model="searchPlayer"
                          size="small"
                          class="pb-2"
                          style="width: 200px"
                          placeholder="Player ID or name"
                        />
                        <el-input
                          v-model="searchGuild"
                          size="small"
                          class="pb-2 pl-3"
                          style="width: 200px"
                          placeholder="Guild ID or name"
                        />
                      </div>
                      <flex-table
                        v-if="payers.length"
                        :rows="payers"
                        :full-width="true"
                        row-key="ID"
                        :columns="[
                          {
                            key: 'ID'
                          },
                          {
                            key: 'Name',
                            width: 180
                          },
                          {
                            key: 'Guild',
                            width: 180
                          },
                          {
                            key: 'GuildStats',
                            title: 'Guild stats',
                            width: 90
                          },
                          {
                            key: 'Created',
                            title: 'Created D'
                          },
                          {
                            key: 'PaidTotal',
                            title: 'Total spent'
                          },
                          {
                            key: 'LastActive',
                            title: 'Active D'
                          },
                          {
                            key: 'PaidBefore',
                            title: 'Paid before'
                          },
                          {
                            key: 'PaidNow',
                            title: 'Paid now'
                          },
                          {
                            key: 'Difference'
                          }
                        ]"
                      >
                        <template #cell_ID="props">
                          <button
                            :key="`ID_${props.rowData.ID}`"
                            class="gs-btn-outlined-primary-neutral"
                            @click="showPlayerDrawer(props.rowData)"
                          >
                            {{ props.rowData.ID }}
                            <el-tooltip
                              v-if="props.rowData.Comments"
                            >
                              <template #content>
                                <pre>{{ props.rowData.Comments }}</pre>
                              </template>
                              <el-icon
                                :size="16"
                                color="red"
                              >
                                <component :is="ChatLineSquare" />
                              </el-icon>
                            </el-tooltip>
                          </button>
                        </template>
                        <template #cell_Created="props">
                          <el-progress
                            :key="`Created_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.Created / 720) * 100, 100)"
                            :color="props.rowData.CreatedColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ props.rowData.CreatedLabel }}</span>
                          </el-progress>
                        </template>
                        <template
                          #cell_Guild="props"
                        >
                          <el-link @click="showGuildDrawer(props.rowData.guild)">
                            {{ props.rowData.Guild }}
                          </el-link>
                        </template>
                        <template #cell_GuildStats="props">
                          <template v-if="props.rowData.guild">
                            <GuildTooltip
                              :guild="props.rowData.guild"
                              :player-id="props.rowData.ID"
                            />
                          </template>
                        </template>
                        <template #cell_PaidTotal="props">
                          <el-progress
                            :key="`PaidTotal_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidTotal / 5000) * 100, 100)"
                            :color="props.rowData.PaidTotalColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ props.rowData.PaidTotal }}</span>
                          </el-progress>
                        </template>
                        <template #cell_LastActive="props">
                          <el-progress
                            :key="`Active_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.Active / churnedDays) * 100, 100)"
                            :color="props.rowData.ActiveColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ props.rowData.Active }}</span>
                          </el-progress>
                        </template>
                        <template #cell_PaidBefore="props">
                          <el-progress
                            :key="`PaidBefore_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidBefore / 1000) * 100, 100)"
                            :color="props.rowData.PaidBeforeColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ $utils.numberFormat(props.rowData.PaidBefore) }}</span>
                          </el-progress>
                        </template>
                        <template #cell_PaidNow="props">
                          <el-progress
                            :key="`PaidNow_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidNow / 1000) * 100, 100)"
                            :color="props.rowData.PaidNowColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ $utils.numberFormat(props.rowData.PaidNow) }}</span>
                          </el-progress>
                        </template>
                        <template #cell_Difference="props">
                          <span :class="props.rowData.Difference >= 0 ? 'green' : 'red'">{{
                            $utils.numberFormat(props.rowData.Difference)
                          }}</span>
                        </template>
                      </flex-table>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-tab-pane>
          <el-tab-pane
            label="Guilds"
            name="guilds"
          >
            <template #default>
              <el-row>
                <el-col>
                  <el-row>
                    <div class="w-500 pl-4 pb-4">
                      <el-radio-group
                        v-model="guildSort"
                        size="small"
                      >
                        <el-radio-button
                          label="Impact"
                          value="Impact"
                        />
                        <el-radio-button
                          label="Best"
                          value="Best"
                        />
                        <el-radio-button
                          label="Churned"
                          value="Churned"
                        />
                        <el-radio-button
                          label="New"
                          value="New"
                        />
                        <el-radio-button
                          label="Zombie"
                          value="Zombie"
                        />
                        <el-radio-button
                          label="Worse"
                          value="Worse"
                        />
                      </el-radio-group>
                      <el-radio-group
                        v-model="guildGroup"
                        size="small"
                        class="ml-5"
                      >
                        <el-radio-button
                          label="All"
                          value="All"
                        />
                        <el-radio-button
                          label="Leagues"
                          value="Leagues"
                        />
                        <el-radio-button
                          label="Search"
                          value="Search"
                        />
                      </el-radio-group>
                    </div>
                  </el-row>
                  <div v-show="guildGroup === 'Search'">
                    <el-input
                      v-model="searchGuildInGuild"
                      size="small"
                      class="pb-2"
                      style="width: 200px"
                      placeholder="Guild ID or name"
                    />
                  </div>
                  <div class="flex items-start gap-4 flex-col 2xl:flex-row">
                    <div class="w-full">
                      <flex-table
                        v-if="guilds.length"
                        :rows="guilds"
                        :full-width="true"
                        row-key="ID"
                        :row-class="(row) => {
                          return row.rowData.header ? 'bg-blue-200 font-bold' : ''
                        }"
                        :columns="[
                          {
                            key: 'ID',
                            width: 60
                          },
                          {
                            key: 'Name',
                            width: 160
                          },
                          {
                            key: 'Division',
                            width: 50
                          },
                          {
                            key: 'Payers',
                            width: 50
                          },
                          {
                            key: 'Stats',
                            width: 95
                          },
                          {
                            key: 'PaidTotal',
                            title: 'Total spent'
                          },
                          {
                            key: 'PaidBefore',
                            title: 'Paid before'
                          },
                          {
                            key: 'PaidNow',
                            title: 'Paid now'
                          },
                          {
                            key: 'Difference'
                          },
                          {
                            key: 'New'
                          },
                          {
                            key: 'Churned'
                          },
                          {
                            key: 'Zombie'
                          },
                          {
                            key: 'Worse'
                          },
                          {
                            key: 'Other'
                          }
                        ]"
                      >
                        <template #cell_ID="props">
                          <button
                            :key="`ID_${props.rowData.ID}`"
                            class="gs-btn-outlined-primary-neutral"
                            @click="showGuildDrawer(props.rowData)"
                          >
                            {{ props.rowData.ID }}
                            <el-tooltip
                              v-if="props.rowData.Comments"
                            >
                              <template #content>
                                <pre>{{ props.rowData.Comments }}</pre>
                              </template>
                              <el-icon
                                :size="16"
                                color="red"
                              >
                                <component :is="ChatLineSquare" />
                              </el-icon>
                            </el-tooltip>
                          </button>
                        </template>
                        <template #cell_Stats="props">
                          <GuildTooltip
                            v-if="!props.rowData.header"
                            :guild="props.rowData.guild"
                          />
                          <div v-else>
                            <el-tooltip content="number of guilds that spent before more than 66% of the best guild">
                              <span class="text-green-800 mx-0.5">
                                {{ props.rowData.PaidBeforeSpread[0] }}
                              </span>
                            </el-tooltip>
                            <el-tooltip content="number of guilds that spent before between 33% and 66% of the best guild">
                              <span class="text-orange-700 mx-0.5">
                                {{ props.rowData.PaidBeforeSpread[1] }}
                              </span>
                            </el-tooltip>
                            <el-tooltip content="number of guilds that spent before less than 33% of the best guild">
                              <span class="text-red-700 mx-0.5">
                                {{ props.rowData.PaidBeforeSpread[2] }}
                              </span>
                            </el-tooltip>
                            <el-tooltip content="number of guilds that spent some money before">
                              <span class="text-black ml-0.5">
                                {{ props.rowData.PaidBeforeSpread.reduce((a, b) => a + b) }}
                              </span>
                            </el-tooltip>
                          </div>
                        </template>
                        <template #cell_PaidTotal="props">
                          <el-progress
                            :key="`PaidTotal_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidTotal / 25000) * 100, 100)"
                            :color="props.rowData.PaidTotalColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ props.rowData.PaidTotal }}</span>
                          </el-progress>
                        </template>
                        <template #cell_PaidBefore="props">
                          <el-progress
                            :key="`PaidBefore_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidBefore / 1000) * 100, 100)"
                            :color="props.rowData.PaidBeforeColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ $utils.numberFormat(props.rowData.PaidBefore) }}</span>
                          </el-progress>
                        </template>
                        <template #cell_PaidNow="props">
                          <el-progress
                            :key="`PaidNow_${props.rowData.ID}`"
                            :text-inside="true"
                            :stroke-width="20"
                            :percentage="Math.min((props.rowData.PaidNow / 1000) * 100, 100)"
                            :color="props.rowData.PaidNowColor"
                            class="w-20"
                          >
                            <span class="text-blue-950">{{ $utils.numberFormat(props.rowData.PaidNow) }}</span>
                          </el-progress>
                        </template>
                        <template #cell_Difference="props">
                          <span :class="props.rowData.Difference >= 0 ? 'green' : 'red'">{{
                            $utils.numberFormat(props.rowData.Difference)
                          }}</span>
                        </template>
                        <template #cell_New="props">
                          <el-tooltip v-if="props.rowData.NewImpact">
                            <template #content>
                              <PayersTooltip
                                :payers="props.rowData.payers.filter((r) => { return r.Status === 'new' })"
                                :show-player-drawer="showPlayerDrawer"
                              />
                            </template>
                            <span
                              :class="props.rowData.NewImpact < 0 ? 'red' : 'green'"
                            >{{
                              $utils.numberFormat(props.rowData.NewImpact)
                            }}|{{ props.rowData.New }}</span>
                          </el-tooltip>
                        </template>
                        <template #cell_Churned="props">
                          <el-tooltip v-if="props.rowData.ChurnedImpact">
                            <template #content>
                              <PayersTooltip
                                :payers="props.rowData.payers.filter((r) => { return r.Status === 'churned' })"
                                :show-player-drawer="showPlayerDrawer"
                              />
                            </template>
                            <span
                              :class="props.rowData.ChurnedImpact < 0 ? 'red' : 'green'"
                            >{{
                              $utils.numberFormat(props.rowData.ChurnedImpact)
                            }}|{{ props.rowData.Churned }}</span>
                          </el-tooltip>
                        </template>
                        <template #cell_Zombie="props">
                          <el-tooltip v-if="props.rowData.ZombieImpact">
                            <template #content>
                              <PayersTooltip
                                :payers="props.rowData.payers.filter((r) => { return r.Status === 'newZombie' })"
                                :show-player-drawer="showPlayerDrawer"
                              />
                            </template>
                            <span
                              v-if="props.rowData.ZombieImpact"
                              :class="props.rowData.ZombieImpact < 0 ? 'red' : 'green'"
                            >{{
                              $utils.numberFormat(props.rowData.ZombieImpact)
                            }}|{{ props.rowData.Zombie }}</span>
                          </el-tooltip>
                        </template>
                        <template #cell_Worse="props">
                          <el-tooltip v-if="props.rowData.WorseImpact">
                            <template #content>
                              <PayersTooltip
                                :payers="props.rowData.payers.filter((r) => { return r.Status === 'worse' })"
                                :show-player-drawer="showPlayerDrawer"
                              />
                            </template>
                            <span
                              v-if="props.rowData.WorseImpact"
                              :class="props.rowData.WorseImpact < 0 ? 'red' : 'green'"
                            >{{
                              $utils.numberFormat(props.rowData.WorseImpact)
                            }}|{{ props.rowData.Worse }}</span>
                          </el-tooltip>
                        </template>
                        <template #cell_Other="props">
                          <el-tooltip v-if="props.rowData.OtherImpact">
                            <template #content>
                              <PayersTooltip
                                :payers="props.rowData.payers.filter((r) => { return r.Status === 'similar' || r.Status === 'improved' || r.Status === 'recovered' }).reverse()"
                                :show-player-drawer="showPlayerDrawer"
                              />
                            </template>
                            <span
                              :class="props.rowData.OtherImpact < 0 ? 'red' : 'green'"
                            >{{
                              $utils.numberFormat(props.rowData.OtherImpact)
                            }}|{{ props.rowData.Other }}</span>
                          </el-tooltip>
                        </template>
                      </flex-table>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
    <template #drawers>
      <player-drawer
        v-model:show-drawer-player="showDrawerPlayer"
        :actual-player="actualPlayer"
        :api-url="gamesEnvs?.[searchFields?.env] || ''"
      />
      <guild-drawer
        v-model:show-drawer-guild="showDrawerGuild"
        :actual-guild="actualGuild"
        :api-url="gamesEnvs?.[searchFields?.env] || ''"
        :payers="payers"
      />
    </template>
  </crud-table>
</template>

<style scoped>
.info-box {
  font-size: 0.65rem;
  line-height: 1em;
  text-align: center;
  .values {
    font-size: 0.85rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
}
:global(h2#card-usage ~ .example .example-showcase) {
  background-color: var(--el-fill-color) !important;
}

.el-statistic {
  --el-statistic-content-font-size: 28px;
}

.statistic-card {
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: var(--el-text-color-regular);
  margin-top: 16px;
}

.statistic-footer .footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statistic-footer .footer-item span:last-child {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
}

.green {
  color: var(--el-color-success);
}
.red {
  color: var(--el-color-error);
}
</style>

<script setup>
import { computed, defineProps, provide, ref } from 'vue'

const props = defineProps({
  form: {
    type: Object,
    required: true
  },
  crudExternalErrors: {
    type: Object,
    default: undefined
  }
})

const initForm = ref({})
const crudExternalErrors = ref({})
const elFormRef = ref(null)

provide('crudForm', computed(() => props.form))
provide('initForm', initForm)
provide('crudExternalErrors', props.crudExternalErrors ? computed(() => props.crudExternalErrors) : crudExternalErrors)
provide('crudFormRefer', elFormRef)
</script>

<template>
  <el-form
    ref="elFormRef"
    :model="form"
    label-position="top"
    class="px-1 sm:px-4 max-w-[100vw] sm:max-w-full sm:min-w-[1200px]"
  >
    <el-row
      class="px-5"
      :gutter="40"
    >
      <slot
        :form="form"
        :form-ref="elFormRef"
      />
    </el-row>
  </el-form>
</template>

<style scoped>

</style>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import { getEmojiFlag } from 'countries-list'
import TimeAgo from 'javascript-time-ago'
import dayjs from 'dayjs'

import en from 'javascript-time-ago/locale/en'
import InfoBox from '!/components/shared/InfoBox.vue'
import { globalProperties as app } from '!/plugins/utilities'

const props = defineProps({
  actualPlayer: {
    type: Object,
    default: undefined
  },
  showDrawerPlayer: {
    type: Boolean,
    default: false
  },
  apiUrl: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:showDrawerPlayer'])

TimeAgo.addLocale(en)

const playerDataStruct = {
  playerHistory: [],
  playerPayments: [],
  chat: [],
  playerOffers: {},
  playerTabModel: 'history',
  zone: '-'
}

const playerData = ref({ ...playerDataStruct })

const loadingPlayerData = ref(false)
const loadingPlayerChatAIData = ref(false)
const loadingSaveComment = ref(false)
const playerChatAISummary = ref(false)
const paymentsGroupDate = ref('Season')
const paymentsGroupOffer = ref('Type')
const paymentsGroupStore = ref('Hide')
const paymentsGroupRefund = ref('Hide')
const historyShow = ref(['Paid', 'Guild', 'Battle'])
const historyGroup = ref('Season')
const comment = ref('')

const playerHistory = computed(() => {
  const data = []
  for (const row of playerData.value.playerHistory) {
    const val = {}
    val.Season = row.Season
    val.Day = row.Day
    val.Live = row.Live
    val.PlayersActive = row.PlayersActive
    val.OpponentPlayersActive = row.OpponentPlayersActive
    val.Level = row.Level
    val.GuildID = row.GuildID
    val.GuildChanged = []
    val.GuildCountry = row.GuildCountry
    val.Energy = row.Energy
    val.EnergyOffer = row.EnergyOffer
    val.EnergyBattle = row.EnergyBattle
    val.EnergyPlayerGift = row.EnergyPlayerGift
    val.EnergyOther = row.EnergyOther
    val.EnergyRewardBattle = row.EnergyRewardBattle
    val.EnergyRewardCards = row.EnergyRewardCards
    val.EnergyRewardOther = row.EnergyRewardOther
    val.EnergyShop = row.EnergyShop
    val.EnergyExpense = row.EnergyExpense
    val.Gems = row.Gems
    val.GemsOffer = row.GemsOffer
    val.GemsOther = row.GemsOther
    val.GemsRewardOther = row.GemsRewardOther
    val.GemsRewardRush = row.GemsRewardRush
    val.GemsExpense = row.GemsExpense
    val.Result = row.Result
    val.OpponentResult = row.OpponentResult
    val.MembersPlayers = row.MembersPlayers
    val.OpponentMembersPlayers = row.OpponentMembersPlayers
    val.GuildPower = row.GuildPower
    val.OpponentPower = row.OpponentPower
    val.PlayerScore = row.PlayerScore
    val.GuildScore = row.GuildScore
    val.OpponentGuildScore = row.OpponentGuildScore
    val.Division = row.Division
    val.PaymentsTotal = 0
    val.Payments = []
    val.BattlesTypes = [0, 0, 0]
    val.BattlesTotal = 1
    val.PaymentsTotalSeason = 0
    const powerColors = {
      2: 'text-red-700',
      1: 'text-orange-400',
      0: 'text-green-700'
    }
    for (const p of playerData.value.playerPayments) {
      if (p.Season === row.Season && p.Day === row.Day) {
        val.PaymentsTotal += p.Price
        const o = playerData.value.playerOffers[p.OfferID]
        val.Payments.push({ price: p.Price, name: o.Name, type: o.Type, count: 1 })
      }
    }
    val.GuildPowerDiff = 0
    const powerTotal = row.GuildPower + row.OpponentPower
    if (powerTotal > 0) {
      const diff = row.GuildPower > row.OpponentPower ? row.GuildPower / row.OpponentPower : row.OpponentPower / row.GuildPower
      if (diff > 2) {
        val.GuildPowerDiff = 2
      } else if (diff > 1.5) {
        val.GuildPowerDiff = 1
      }
      val.GuildPowerDiffColor = powerColors[val.GuildPowerDiff]
    }
    val.BattlesTypes[val.GuildPowerDiff]++
    if (historyGroup.value === 'Season') {
      let has = false
      for (const i in data) {
        if (data[i].Season === val.Season) {
          has = true
          data[i].BattlesTotal++
          if (val.Live) {
            data[i].LiveTotal++
          }
          data[i].LevelMax = val.Level
          data[i].EnergyOffer += val.EnergyOffer
          data[i].EnergyBattle += val.EnergyBattle
          data[i].EnergyPlayerGift += val.EnergyPlayerGift
          data[i].EnergyOther += val.EnergyOther
          data[i].EnergyRewardBattle += val.EnergyRewardBattle
          data[i].EnergyRewardCards += val.EnergyRewardCards
          data[i].EnergyRewardOther += val.EnergyRewardOther
          data[i].EnergyShop += val.EnergyShop
          data[i].EnergyExpense += val.EnergyExpense
          data[i].GemsOffer += val.GemsOffer
          data[i].GemsOther += val.GemsOther
          data[i].GemsRewardOther += val.GemsRewardOther
          data[i].GemsRewardRush += val.GemsRewardRush
          data[i].GemsExpense += val.GemsExpense
          if (val.Result > val.OpponentResult) {
            data[i].WinTotal++
          }
          data[i].BattlesTypes[val.GuildPowerDiff]++
          data[i].ScoreTotal += val.GuildScore
          data[i].OpponentScoreTotal += val.OpponentGuildScore
          data[i].PlayerScoreTotal += val.PlayerScore
          for (const p of val.Payments) {
            let hasPayment = false
            for (const k in data[i].Payments) {
              if (data[i].Payments[k].type === p.type) {
                hasPayment = true
                data[i].Payments[k].count++
                data[i].Payments[k].price += p.price
                data[i].PaymentsTotalSeason += p.price
                break
              }
            }
            if (!hasPayment) {
              data[i].Payments.push(p)
              data[i].PaymentsTotalSeason += p.price
            }
          }
          if (data[i].GuildID !== val.GuildID) {
            data[i].GuildChanged.push(val.GuildID)
            data[i].GuildID = val.GuildID
          }
          break
        }
      }
      if (!has) {
        val.LiveTotal = 0
        val.BattlesTotal = 1
        val.LevelMin = val.Level
        val.LevelMax = val.Level
        if (val.Live) {
          val.LiveTotal++
        }
        val.WinTotal = val.Result > val.OpponentResult ? 1 : 0
        val.BattlesTypes = [0, 0, 0]
        val.BattlesTypes[val.GuildPowerDiff]++
        val.ScoreTotal = val.GuildScore
        val.OpponentScoreTotal = val.OpponentGuildScore
        val.PlayerScoreTotal = val.PlayerScore
        data.push(val)
      }
    } else {
      data.push(val)
    }
  }
  const maxScores = {}
  const maxPlayerScores = {}
  for (const row of data) {
    if (maxScores[row.Season] === undefined) {
      maxScores[row.Season] = row.GuildScore
      maxPlayerScores[row.Season] = row.PlayerScore
    } else {
      maxScores[row.Season] = Math.max(maxScores[row.Season], row.GuildScore)
      maxPlayerScores[row.Season] = Math.max(maxPlayerScores[row.Season], row.PlayerScore)
    }
  }
  for (const row of data) {
    row.ScorePercent = 0
    row.PlayerScorePercent = 0
    if (maxScores[row.Season]) {
      row.ScorePercent = Math.round(row.GuildScore / maxScores[row.Season] * 100)
    }
    if (maxPlayerScores[row.Season]) {
      row.PlayerScorePercent = Math.round(row.PlayerScore / maxPlayerScores[row.Season] * 100)
    }
  }

  data.sort((a, b) => {
    if (a.Season > b.Season) {
      return -1
    } else if (a.Season === b.Season) {
      if (a.Day > b.Day) {
        return -1
      }
      return 1
    }
    return 1
  })
  if (historyGroup.value === 'Day') {
    let guildBefore = 0
    for (const row of data) {
      if (guildBefore > 0 && row.GuildID !== guildBefore) {
        row.GuildChanged.push(row.GuildID)
      }
      guildBefore = row.GuildID
    }
  }
  return data
})

const showDrawerPlayerModel = computed({
  get() {
    return props.showDrawerPlayer
  },
  set(newVal) {
    emit('update:showDrawerPlayer', newVal)
  }
})

const playerTotalPaid = computed(() => {
  let total = 0
  for (const row of playerData.value.playerPayments) {
    total += row.Price
  }
  return total
})

const playerTotalLive = computed(() => {
  let total = 0
  let totalLive = 0
  for (const row of playerData.value.playerHistory) {
    total++
    if (row.Live) {
      totalLive++
    }
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalLive / total * 100)
  return `${totalLive}/${total}(${percentage}%)`
})

const playerTotalWins = computed(() => {
  let total = 0
  let totalWins = 0
  for (const row of playerData.value.playerHistory) {
    total++
    if (row.OpponentResult < row.Result) {
      totalWins++
    }
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalWins / total * 100)
  return `${totalWins}/${total}(${percentage}%)`
})

const greenBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of playerHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[0] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const orangeBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of playerHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[1] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const redBattles = computed(() => {
  let total = 0
  let totalColor = 0
  for (const row of playerHistory.value) {
    total += row.BattlesTotal
    totalColor += row.BattlesTypes ? row.BattlesTypes[2] : 0
  }
  if (!total) {
    return '-'
  }
  const percentage = Math.round(totalColor / total * 100)
  return `${totalColor}(${percentage}%)`
})

const playerPayments = computed(() => {
  const data = []
  let i = 0
  for (const row of playerData.value.playerPayments) {
    let key = ''
    if (paymentsGroupDate.value === 'Season') {
      key = row.Season
    } else if (paymentsGroupDate.value === 'Day') {
      key = `${row.Season}_${row.Day}`
    } else if (paymentsGroupDate.value === 'All') {
      key = i
    }
    const offer = playerData.value.playerOffers[row.OfferID]
    if (paymentsGroupOffer.value === 'Type') {
      key += offer.Type
    } else if (paymentsGroupOffer.value === 'All') {
      key = row.OfferID
    }
    if (paymentsGroupStore.value === 'Store') {
      key += row.Store ? 'y' : 'n'
    }
    if (paymentsGroupRefund.value === 'Refund') {
      key += row.Refund ? 'y' : 'n'
    }
    let has = false
    for (const before of data) {
      if (before.Key === key) {
        before.Count++
        before.Price += row.Price
        has = true
        break
      }
    }
    if (!has) {
      const val = {}
      val.Key = key
      val.Count = 1
      val.Date = row.Date
      val.Season = row.Season
      val.Day = row.Day
      val.OfferID = row.OfferID
      val.Price = row.Price
      val.Store = row.Store
      val.Refund = row.Refund
      data.push(val)
    }
    i++
  }
  return data
}
)

const lazyInitTabsContent = ref({ history: true })
function dateAgo(seconds) {
  if (seconds) {
    const d = new Date()
    d.setSeconds(d.getSeconds() - seconds)
    const timeAgo = new TimeAgo('en-US')
    return timeAgo.format(d)
  }
  return ''
}

const player = computed(() => {
  let data = {}
  if (props.actualPlayer?.id) {
    data = { ...props.actualPlayer }
    data.countryFlag = props.actualPlayer?.country ? getEmojiFlag(props.actualPlayer.country) : ''
    data.createdAgo = props.actualPlayer?.created ? dateAgo(props.actualPlayer.created * 86400) : ''
    data.activeAgo = props.actualPlayer?.active ? dateAgo(props.actualPlayer.active * 86400) : ''
  }
  return data
})

function saveComment() {
  loadingSaveComment.value = true
  app.$axios
    .post(`${props.apiUrl}/admin/api/overwatcher/comment/save/`, { id: props.actualPlayer.id, comment: comment.value })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingSaveComment.value = false
    })
}

function aiChat() {
  loadingPlayerChatAIData.value = true
  app.$axios
    .get(`${props.apiUrl}/admin/api/overwatcher/ai/chat/`, { params: { id: player.value.id } })
    .then((data) => {
      playerChatAISummary.value = data.data.summary
    })
    .catch(app.$utils.catchError)
    .then(() => {
      loadingPlayerChatAIData.value = false
    })
}
function loadPlayer() {
  comment.value = props.actualPlayer.comments
  playerChatAISummary.value = false
  paymentsGroupDate.value = 'Season'
  paymentsGroupOffer.value = 'Type'
  paymentsGroupStore.value = 'Hide'
  paymentsGroupRefund.value = 'Hide'
  historyShow.value = ['Paid', 'Guild', 'Battle']
  lazyInitTabsContent.value = { history: true }
  playerData.value = { ...playerDataStruct }
  if (player.value?.id && props.apiUrl) {
    loadingPlayerData.value = true
    // http://localhost:8097
    app.$axios
      .get(`${props.apiUrl}/admin/api/overwatcher/payer/`, { params: { id: player.value.id } })
      .then((data) => {
        playerData.value.playerHistory = data.data.data ?? []
        playerData.value.playerPayments = data.data.payments ?? []
        playerData.value.playerOffers = data.data.offers ?? []
        playerData.value.chat = data.data.chat ?? []
        playerData.value.zone = data.data.data.length ? data.data.data[0].Zone : '-'
      })
      .catch(app.$utils.catchError)
      .then(() => {
        loadingPlayerData.value = false
      })
  }
}
function onTabsChange(tabName) {
  lazyInitTabsContent.value[tabName] = true
}

function onClose() {
  lazyInitTabsContent.value = {}
  playerData.value = { ...playerDataStruct }
}
</script>

<template>
  <el-drawer
    v-model="showDrawerPlayerModel"
    class="player-drawer"
    size="1200px"
    @open="loadPlayer"
    @closed="onClose"
  >
    <template #header>
      <div class="w-full">
        <div class="flex flex-wrap justify-start gap-2">
          <div>
            <!--            <router-link -->
            <!--              v-if="player.id" -->
            <!--              :to="{ name: 'gameplay-players-details', params: { id: player.id }, query: { env: 'prod' } }" -->
            <!--              target="_blank" -->
            <!--              class="hover:text-blue-600" -->
            <!--              @click.stop -->
            <!--            > -->
            <!--              <icon-ify -->
            <!--                :icon="player.bot ? 'fluent:bot-20-regular' : 'ep:user'" -->
            <!--                class="inline-block text-xl mr-1" -->
            <!--              /> -->
            <!--              <b>{{ player.name }}</b> -->
            <!--            </router-link> -->
            <icon-ify
              :icon="player.bot ? 'fluent:bot-20-regular' : 'ep:user'"
              class="inline-block text-xl mr-1"
            />
            <b>{{ player.name }}</b>
          </div>
          <div>
            <!--            <router-link -->
            <!--              v-if="player.id" -->
            <!--              :to="{ name: 'gameplay-players-details', params: { id: player.id }, query: { env: 'prod' } }" -->
            <!--              target="_blank" -->
            <!--              class="hover:text-blue-600" -->
            <!--              @click.stop -->
            <!--            > -->
            <!--              <icon-ify -->
            <!--                icon="ph:hash-light" -->
            <!--                class="inline-block text-xl" -->
            <!--              /> -->
            <!--              <b>{{ player.id }}</b> -->
            <!--            </router-link> -->
            <icon-ify
              icon="ph:hash-light"
              class="inline-block text-xl"
            />
            <b>{{ player.id }}</b>
          </div>
          <div
            v-if="$utils.countries?.[player.country]?.name || player.country"
            class="pr-8 relative"
          >
            <icon-ify
              icon="ph:map-pin-thin"
              class="inline-block text-xl mr-0.5"
            />
            <b>{{ $utils.countries?.[player.country]?.name || player.country }}</b>
            <span class="text-2xl absolute -top-1.5 right-0">{{ player.countryFlag }}</span>
          </div>
          <div class="relative ml-2">
            <icon-ify
              v-if="!!actualPlayer?.whales"
              icon="icon-park-outline:whale"
              class="inline text-3xl absolute -top-1"
            />
          </div>
        </div>
        <div class="flex flex-wrap justify-start gap-x-4 gap-y-2 mt-2">
          <InfoBox
            v-if="player.level !== undefined"
            title="Level"
            :value="player.level"
          />
          <InfoBox
            title="Total paid"
            :value="$utils.currencySymbolFormat(playerTotalPaid || 0)"
          />
          <InfoBox
            v-if="player.createdAgo !== ''"
            title="Created"
            :value="player.createdAgo"
          />
          <InfoBox
            v-if="player.activeAgo !== ''"
            title="Last active"
            :value="player.activeAgo"
          />
          <InfoBox
            v-if="player.activeAgo !== ''"
            title="Zone"
            :value="playerData.zone"
          />
          <InfoBox
            title="Live"
            :value="playerTotalLive"
          />
          <InfoBox
            title="Wins"
            :value="playerTotalWins"
          />
          <InfoBox
            title="Green Battles"
            :value="greenBattles"
          />
          <InfoBox
            title="Orange Battles"
            :value="orangeBattles"
          />
          <InfoBox
            title="Red Battles"
            :value="redBattles"
          />
        </div>
      </div>
    </template>
    <el-tabs
      v-model="playerData.playerTabModel"
      v-loading="loadingPlayerData"
      @tab-change="onTabsChange"
    >
      <el-tab-pane
        v-if="showDrawerPlayerModel"
        label="History"
        name="history"
      >
        <template #default>
          <el-radio-group
            v-model="historyGroup"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Season"
              value="Season"
            />
            <el-radio-button
              label="Day"
              value="Day"
            />
          </el-radio-group>
          <el-checkbox-group
            v-model="historyShow"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-checkbox-button
              label="Guild"
              value="Guild"
            />
            <el-checkbox-button
              label="Paid"
              value="Paid"
            />
            <el-checkbox-button
              label="Battle"
              value="Battle"
            />
            <el-checkbox-button
              label="Level"
              value="Level"
            />
            <el-checkbox-button
              label="Energy"
              value="Energy"
            />
            <el-checkbox-button
              label="Gems"
              value="Gems"
            />
            <el-checkbox-button
              v-if="historyGroup === 'Day'"
              label="Members"
              value="Members"
            />
            <el-checkbox-button
              label="Division"
              value="Division"
            />
          </el-checkbox-group>
          <table>
            <tr>
              <th>
                <el-tooltip content="Season">
                  Season
                </el-tooltip>
              </th>
              <th v-if="historyGroup === 'Day'">
                <el-tooltip content="Day of season">
                  Day
                </el-tooltip>
              </th>
              <th>
                <el-tooltip content="Live">
                  Live
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Level')">
                <el-tooltip content="Level">
                  Lvl
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Used energy">
                  E.Used
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Energy')"
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Energy at the end of day">
                  E.LEFT
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from offers">
                  E.O
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from battle">
                  E.B
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from players gift">
                  E.G
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from other sources">
                  E.?
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain as reward from battle">
                  E.B
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain as reward from cards">
                  E.C
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from other rewards">
                  E.R?
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Energy')">
                <el-tooltip content="Energy gain from shop">
                  E.S
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Gems')">
                <el-tooltip content="Used gems">
                  G.USED
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Gems')"
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Gems left at the end of day">
                  G.LEFT
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Gems')">
                <el-tooltip content="Gems gain from offer">
                  G.O
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Gems')">
                <el-tooltip content="Energy gain from other sources">
                  E.?
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Gems')">
                <el-tooltip content="Energy gain from other rewards">
                  E.?
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Gems')">
                <el-tooltip content="Gems gain from rush rewards">
                  E.?
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Members')"
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players in guild/opponent guild">
                  Members
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Number of players/opponents in skirmish">
                  Skirmish
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Battle')">
                <el-tooltip content="Battle score">
                  Win
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
              >
                <el-tooltip content="Guild power/opponent guild power">
                  Power
                </el-tooltip>
              </th>
              <th
                v-show="historyShow.includes('Battle')"
                v-else
              >
                <el-tooltip content="Green/Orange/Red battles">
                  Battles
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Battle')">
                <el-tooltip content="Guild score/opponent guild score">
                  G.Score
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Battle')">
                <el-tooltip content="Player score">
                  P.Score
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Division')">
                <el-tooltip content="Division">
                  Division
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Paid')">
                <el-tooltip content="Payments">
                  Paid
                </el-tooltip>
              </th>
              <th v-show="historyShow.includes('Guild')">
                Guild
              </th>
            </tr>
            <tr
              v-for="(row, key) in playerHistory"
              :key="key"
            >
              <td class="p-2">
                {{ row.Season }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.Day }}
              </td>
              <td
                v-if="historyGroup === 'Day'"
                style="width: 5px"
                :class="{ 'bg-green-700': row.Live }"
              />
              <td
                v-if="historyGroup === 'Season'"
                style="width: 5px"
              >
                {{ row.LiveTotal }}/{{ row.BattlesTotal }}
              </td>
              <td
                v-show="historyShow.includes('Level')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.Level }}
              </td>
              <td
                v-show="historyShow.includes('Level')"
                v-else
                class="p-2"
              >
                {{ row.LevelMin }}-{{ row.LevelMax }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyExpense ? $utils.numberFormat(row.EnergyExpense) : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.Energy ? row.Energy : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyOffer ? row.EnergyOffer : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyBattle ? row.EnergyBattle : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyPlayerGift ? row.EnergyPlayerGift : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyOther ? row.EnergyOther : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyRewardBattle ? row.EnergyRewardBattle : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyRewardCards ? row.EnergyRewardCards : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyRewardOther ? row.EnergyRewardOther : '' }}
              </td>
              <td
                v-show="historyShow.includes('Energy')"
                class="p-2"
              >
                {{ row.EnergyShop ? row.EnergyShop : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                class="p-2"
              >
                {{ row.GemsExpense ? row.GemsExpense : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.Gems ? row.Gems : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                class="p-2"
              >
                {{ row.GemsOffer ? row.GemsOffer : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                class="p-2"
              >
                {{ row.GemsOther ? row.GemsOther : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                class="p-2"
              >
                {{ row.GemsRewardOther ? row.GemsRewardOther : '' }}
              </td>
              <td
                v-show="historyShow.includes('Gems')"
                class="p-2"
              >
                {{ row.GemsRewardRush ? row.GemsRewardRush : '' }}
              </td>
              <td
                v-show="historyShow.includes('Members')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.MembersPlayers }}/{{ row.OpponentMembersPlayers }}
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                {{ row.PlayersActive }}/{{ row.OpponentPlayersActive }}
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
                class="p-2"
                :class="{ 'text-green-600': row.Result > row.OpponentResult, 'text-red-600': row.Result < row.OpponentResult }"
              >
                {{ row.Result }}/{{ row.OpponentResult }}
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-else
                class="p-2"
                :class="{ 'text-green-600': row.WinTotal / row.BattlesTotal > 0.5, 'text-red-600': row.WinTotal / row.BattlesTotal < 0.5 }"
              >
                {{ row.WinTotal }}/{{ row.BattlesTotal }}
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
                class="p-2"
                :class="row.GuildPowerDiffColor"
              >
                {{ $utils.numberFormat(Math.round(row.GuildPower / 100) * 100, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentPower / 100) * 100, { shortcuts: true }) }}
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-else
                class="p-2"
              >
                <span class="text-green-700">{{ row.BattlesTypes[0] }}</span>|<span class="text-orange-400">{{ row.BattlesTypes[1] }}</span>|<span class="text-red-700">{{ row.BattlesTypes[2] }}</span>
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.GuildScore / 1000) * 1000, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentGuildScore / 1000) * 1000, { shortcuts: true }) }}
                </div>
                <div
                  style="height: 3px"
                  class="bg-green-700"
                  :style="{ width: `${row.ScorePercent}%` }"
                />
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-else
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.ScoreTotal / 1000) * 1000, { shortcuts: true }) }}/{{ $utils.numberFormat(Math.round(row.OpponentScoreTotal / 1000) * 1000, { shortcuts: true }) }}
                </div>
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.PlayerScore / 1000) * 1000, { shortcuts: true }) }}
                </div>
                <div
                  style="height: 3px"
                  class="bg-green-700"
                  :style="{ width: `${row.PlayerScorePercent}%` }"
                />
              </td>
              <td
                v-show="historyShow.includes('Battle')"
                v-else
                class="p-2"
              >
                <div>
                  {{ $utils.numberFormat(Math.round(row.PlayerScoreTotal / 1000) * 1000, { shortcuts: true }) }}
                </div>
              </td>
              <td
                v-show="historyShow.includes('Division')"
                class="p-2"
              >
                {{ row.Division }}
              </td>
              <td
                v-show="historyShow.includes('Paid')"
                v-if="historyGroup === 'Day'"
                class="p-2"
              >
                <el-tooltip>
                  <template #content>
                    <div
                      v-for="(p, i) in row.Payments"
                      :key="i"
                    >
                      {{ p.type }} | {{ p.name }} = {{ $utils.currencySymbolFormat(p.price) }}
                    </div>
                  </template>
                  <div v-if="row.PaymentsTotal">
                    {{ $utils.currencySymbolFormat(row.PaymentsTotal) }}
                  </div>
                </el-tooltip>
              </td>
              <td
                v-show="historyShow.includes('Paid')"
                v-else
                class="p-2"
              >
                <el-tooltip>
                  <template #content>
                    <div
                      v-for="(p, i) in row.Payments"
                      :key="i"
                    >
                      {{ p.type }} x {{ p.count }} = {{ $utils.currencySymbolFormat(p.price) }}
                    </div>
                  </template>
                  <div v-if="row.PaymentsTotalSeason">
                    {{ $utils.currencySymbolFormat(row.PaymentsTotalSeason) }}
                  </div>
                </el-tooltip>
              </td>
              <td
                v-show="historyShow.includes('Guild')"
                class="p-2"
              >
                <el-tag
                  v-for="(id, guildKey) in row.GuildChanged"
                  :key="guildKey"
                  type="primary"
                  size="small"
                >
                  {{ id }}
                </el-tag>
              </td>
            </tr>
          </table>
        </template>
        <template #label>
          <el-badge
            :value="playerData.playerHistory.length"
            :max="999"
            type="primary"
          >
            History
          </el-badge>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="showDrawerPlayerModel"
        label="Chat"
        name="chat"
      >
        <template #default>
          <el-button
            v-show="playerData.chat.length >= 5"
            type="primary"
            size="small"
            :loading="loadingPlayerChatAIData"
            @click="aiChat"
          >
            AI summary
          </el-button>
          <div
            v-show="playerChatAISummary"
            class="m-5 font-bold"
            style="white-space: pre-line"
          >
            {{ playerChatAISummary }}
          </div>
          <div
            v-for="(row, key) in playerData.chat"
            :key="key"
            class="m-3"
            style="font-size: 10px"
          >
            <div>{{ new TimeAgo('en-US').format(row.Date) }} {{ row.Season }}|{{ row.Day }} ({{ dayjs(row.Date).format('YYYY/MM/DD HH:mm') }})</div>
            <div>{{ row.Message }}</div>
          </div>
        </template>
        <template #label>
          <el-badge
            :value="playerData.chat.length"
            :max="999"
            type="primary"
          >
            Chat
          </el-badge>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="showDrawerPlayerModel"
        label="Payments"
        name="payments"
      >
        <template #default>
          <el-radio-group
            v-model="paymentsGroupDate"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Season"
              value="Season"
            />
            <el-radio-button
              label="Day"
              value="Day"
            />
            <el-radio-button
              label="All"
              value="All"
            />
            <el-radio-button
              label="Hide"
              value="Hide"
            />
          </el-radio-group>
          <el-radio-group
            v-model="paymentsGroupOffer"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Type"
              value="Type"
            />
            <el-radio-button
              label="All"
              value="All"
            />
            <el-radio-button
              label="Hide"
              value="Hide"
            />
          </el-radio-group>
          <el-radio-group
            v-model="paymentsGroupStore"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Store"
              value="Store"
            />
            <el-radio-button
              label="Hide"
              value="Hide"
            />
          </el-radio-group>
          <el-radio-group
            v-model="paymentsGroupRefund"
            size="small"
            type="primary"
            class="my-3 mr-3"
          >
            <el-radio-button
              label="Refund"
              value="Refund"
            />
            <el-radio-button
              label="Hide"
              value="Hide"
            />
          </el-radio-group>
          <table>
            <tr>
              <th v-show="paymentsGroupDate === 'Season' || paymentsGroupDate === 'Day' || paymentsGroupDate === 'All'">
                Season
              </th>
              <th v-show="paymentsGroupDate === 'Day' || paymentsGroupDate === 'All'">
                Day
              </th>
              <th v-show="paymentsGroupOffer === 'Type' || paymentsGroupOffer === 'All'">
                Type
              </th>
              <th v-show="paymentsGroupOffer === 'All'">
                Name
              </th>
              <th v-show="paymentsGroupStore === 'Store'">
                Store
              </th>
              <th v-show="paymentsGroupDate !== 'All'">
                Count
              </th>
              <th v-show="paymentsGroupDate === 'All'">
                Date
              </th>
              <th v-show="paymentsGroupRefund === 'Refund'">
                Refund
              </th>
              <th>Paid</th>
            </tr>
            <tr
              v-for="(row, key) in playerPayments"
              :key="key"
            >
              <td
                v-show="paymentsGroupDate === 'Season' || paymentsGroupDate === 'Day' || paymentsGroupDate === 'All'"
                class="pl-5"
              >
                {{ row.Season }}
              </td>
              <td
                v-show="paymentsGroupDate === 'Day' || paymentsGroupDate === 'All'"
                class="pl-5"
              >
                {{ row.Day }}
              </td>
              <td
                v-show="paymentsGroupOffer === 'Type' || paymentsGroupOffer === 'All'"
                class="pl-5"
              >
                {{ playerData.playerOffers[row.OfferID].Type }}
              </td>
              <td
                v-show="paymentsGroupOffer === 'All'"
                class="pl-5"
              >
                {{ playerData.playerOffers[row.OfferID].Name }}
              </td>
              <td
                v-show="paymentsGroupStore === 'Store'"
                class="pl-5"
              >
                {{ row.Store ? 'yes' : '' }}
              </td>
              <td
                v-show="paymentsGroupDate !== 'All'"
                class="pl-5"
              >
                {{ row.Count }}
              </td>
              <td
                v-show="paymentsGroupDate === 'All'"
                class="pl-5"
              >
                {{ dayjs(row.Date).format('YYYY/MM/DD HH:mm') }}
              </td>
              <td
                v-show="paymentsGroupRefund === 'Refund'"
                class="pl-5"
              >
                {{ row.Refund ? 'yes' : '' }}
              </td>
              <td class="pl-5">
                {{ $utils.currencySymbolFormat(row.Price || 0) }}
              </td>
            </tr>
          </table>
        </template>
        <template #label>
          <el-badge
            :value="playerData.playerPayments.length"
            :max="999"
            type="primary"
          >
            Payments
          </el-badge>
        </template>
      </el-tab-pane>
      <el-tab-pane
        v-if="showDrawerPlayerModel"
        label="Comment"
        name="comment"
      >
        <template #default>
          <el-input
            v-model="comment"
            autosize
            type="textarea"
            placeholder="Add comment"
          />
          <el-button
            type="primary"
            size="small"
            class="mt-3"
            :loading="loadingSaveComment"
            @click="saveComment"
          >
            save
          </el-button>
        </template>
        <template #label>
          <el-badge
            :value="comment ? 1 : ''"
            type="danger"
          >
            Comment
          </el-badge>
        </template>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<style lang="postcss">
.player-drawer {
  .el-drawer__header {
    margin-bottom: 5px;
    align-items: flex-start;
  }
}
</style>

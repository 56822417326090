<script>
import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'

export default {
  name: 'LinesChartComponent',
  props: {
    chartCode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    stats: {
      type: Object,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    goals: {
      type: Array,
      default: undefined
    }
  },
  mounted() {
    this.initChart()
  },
  methods: {
    reverseArr(input) {
      const ret = []
      for (let i = input.length - 1; i >= 0; i--) {
        ret.push(input[i])
      }
      return ret
    },
    initChart() {
      const root = am5.Root.new(this.chartCode)
      root.setThemes([am5themes_Animated.new(root)])

      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none'
      }))

      const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        forceHidden: true,
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }))

      let minMax = {}
      if (this.min || this.max) {
        minMax = {
          min: this.min,
          max: this.max,
          strictMinMax: true

        }
      }
      const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        ...minMax
      }))

      for (const version in this.stats[this.chartCode]) {
        const series = chart.series.push(am5xy.LineSeries.new(root, {
          name: version,
          xAxis,
          yAxis,
          valueYField: 'value',
          valueXField: 'date',
          legendValueText: '{valueY}',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: `v${version}: {valueY} ({users} users)`
          })
        }))
        series.data.setAll(this.reverseArr(this.stats[this.chartCode][version]))
        series.appear()
      }

      chart.set('scrollbarX', am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      }))

      const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
        behavior: 'none'
      }))
      cursor.lineY.set('visible', false)

      for (const goal of this.goals ?? []) {
        // add ranges
        const goalRange = yAxis.createAxisRange(yAxis.makeDataItem({
          value: goal.value
        }))

        goalRange.get('grid').setAll({
          forceHidden: false,
          strokeOpacity: 1,
          strokeWidth: 3,
          stroke: am5.Color.fromString(goal.color)
        })

        const goalLabel = goalRange.get('label')

        goalLabel.setAll({
          centerY: am5.p100,
          centerX: am5.p100,
          text: goal.label,
          fontWeight: 'bold',
          fill: am5.Color.fromString(goal.color)
        })

        goalLabel.adapters.add('x', () => {
          return 45
        })
        chart.plotContainer.onPrivate('width', () => {
          goalLabel.markDirtyPosition()
        })
      }

      chart.appear(1000, 100)
    }
  }
}
</script>

<template>
  <el-divider v-if="title">
    {{ title }}
  </el-divider>
  <div
    :id="chartCode"
    style="height: 250px"
  />
</template>

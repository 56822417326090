<script>
import { computed, h, ref, watch } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { debounce } from 'lodash'
import { Bottom, DCaret, Search, Top } from '@element-plus/icons-vue'
import { globalProperties as app } from '!/plugins/utilities'
import CrudTypeFields from '!/components/crud/sub/CrudTypeFields.vue'

const Row = {
  props: ['cells', 'rowData'],
  render() {
    if (this.rowData.expandRow) {
      return [
        h(
          'div',
          { class: 'w-full' },
          this.$slots.default({
            row: this.rowData.data
          })
        )
      ]
    }
    return this.cells
  }
}
Row.inheritAttrs = false
export default {
  name: 'FlexTable',
  components: { CrudTypeFields, Row },
  props: {
    columns: {
      type: Array,
      default: () => [
        // {
        //   title: '',
        //   width: 100,
        //   class: '', // td cell class
        //   headerClass: '', // th cell class
        //   key: ''// api column name
        //   filter: true || callBackFilter(filterVal, fieldVal) { return true/false}  //to add a default filter field to header_cell filtering (looks for values containing value data); if the function is used for filtering (must return true/false)to add a default filter field to header_cell filtering (looks for values containing value data); if the function is used for filtering (must return true/false)
        //   type: '' //type data in column (generally used to build filter field (if enabled) and for filter data; see also CrudTypeFIelds.vue;
        //   sort: true/false || callBackSort(a, b) { return -1 || 1 || 0 (like js compare sort function)
        // }
      ]
    },
    rows: {
      type: Array,
      default: () => []
    },
    /**
     * name of column used as rowKey
     * Important: el-table-v2 requires a valid (existing in the data) row-key
     */
    rowKey: {
      type: String,
      default: 'id'
    },
    height: {
      type: Number,
      default: 400
    },
    rowClass: {
      type: Function,
      default: null
    },
    defaultColWidth: {
      type: Number,
      default: 100
    },
    headerClass: {
      type: String,
      default: 'font-medium bg-sky-50 text-sky-800'
    },
    calcRowHeight: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    /**
     * key - field name
     * order - asc/desc
     */
    defSort: {
      type: Object,
      default: () => ({ key: '', order: 'asc' })
    },
    /**
     * callback
     * args: rowData
     * returns data object for expand row; otherwise expand row is not created;
     * Important: el-table-v2 requires a valid (existing in the data) row-key
     */
    expandRowsData: {
      type: Function,
      default: undefined
    }
  },
  setup(props) {
    const resizeEl = ref(null)
    const widthResizeEl = ref(0)
    const filters = ref({})
    const filtersValues = ref({})
    const rowWidth = ref(0)
    const alignThToTop = ref(false)
    const tableRows = ref([])
    const sortBy = ref({ key: '', order: 'asc', ...props.defSort })

    useResizeObserver(resizeEl, (entries) => {
      const entry = entries[0]
      const { width } = entry.contentRect
      widthResizeEl.value = width
    })

    const columnsDef = computed(() => {
      const colLastIndex = props.columns.length - 1
      let widthAllCols = 0
      return props.columns.map((col, indexCol) => {
        const colSettings = { ...col }
        colSettings.dataKey = colSettings.key
        colSettings.width = ((colSettings.width || props.defaultColWidth) + (colSettings?.sort ? 10 : 0)) * app.$store.getters['auth/userScaledRatioWidth']

        colSettings.headerClass = props.headerClass + (colSettings.headerClass || '')
        if (!colSettings?.title?.length) {
          colSettings.title = app.$utils.convertApiNamesToHuman(colSettings.key)
        }
        widthAllCols += colSettings.width
        if (indexCol === colLastIndex) {
          rowWidth.value = widthAllCols
          if (props.fullWidth && widthResizeEl.value > widthAllCols) {
            colSettings.width += widthResizeEl.value - widthAllCols - 5
            rowWidth.value += widthResizeEl.value - widthAllCols - 5
          }
        }
        return colSettings
      })
    })

    columnsDef.value.forEach((col) => {
      if (col.filter) {
        filters.value[col.dataKey] = col.filter
        filtersValues.value[col.dataKey] = app.$route?.query?.[col.dataKey] || ''
        alignThToTop.value = true
      }
    })

    const columnsDefMap = Object.fromEntries(
      Object.entries(columnsDef.value).map(([, col]) => {
        return [col.key, col]
      })
    )

    const propsRows = computed(() => {
      if (props.expandRowsData) {
        return [...props.rows].map((row) => {
          const childRow = props.expandRowsData({ ...row })
          if (childRow) {
            row.children = [{ data: childRow, expandRow: true, [props.rowKey]: `${row[props.rowKey]}_1` }]
          }
          return row
        })
      }
      return props.rows
    })

    const sortedPropsRows = computed(() => {
      if (sortBy.value.key) {
        const sortCallBack = typeof columnsDefMap[sortBy.value.key]?.sort === 'function'
        const orderedRows = [...propsRows.value].sort(
          sortCallBack
            ? columnsDefMap[sortBy.value.key].sort
            : (a, b) => {
                if (a[sortBy.value.key] < b[sortBy.value.key]) {
                  return -1
                }
                if (a[sortBy.value.key] > b[sortBy.value.key]) {
                  return 1
                }
                return 0
              }
        )
        return sortBy.value.order === 'desc' ? orderedRows.reverse() : orderedRows
      }
      return propsRows.value
    })

    const renderTableRows = debounce(() => {
      const filtersFields = []
      Object.entries(filtersValues.value).forEach(([nameField, val]) => {
        if (val !== undefined && val !== '') {
          filtersFields.push(nameField)
        }
      })
      if (filtersFields.length) {
        const filteredRows = []
        sortedPropsRows.value.forEach((row) => {
          if (
            !filtersFields.some((nameField) => {
              if (filters.value[nameField] === true) {
                const typeField = columnsDefMap[nameField]?.type || 'any'
                if (['any', 'string'].includes(typeField)) {
                  if (!String(row[nameField]).includes(filtersValues.value[nameField])) {
                    return true
                  }
                } else if (['number', 'int', 'uint', 'float'].includes(typeField)) {
                  if (row[nameField] * 1 !== filtersValues.value[nameField] * 1) {
                    return true
                  }
                } else if (row[nameField] !== filtersValues.value[nameField]) {
                  return true
                }
              } else if (typeof filters.value[nameField] === 'function' && !filters.value[nameField](filtersValues.value[nameField], row)) {
                return true
              }
              return false
            })
          ) {
            filteredRows.push(row)
          }
        })
        tableRows.value = filteredRows
      } else {
        tableRows.value = sortedPropsRows.value
      }
    }, 400)

    watch(
      filtersValues,
      () => {
        renderTableRows()
      },
      { deep: true, immediate: true }
    )

    watch(
      () => propsRows,
      () => {
        renderTableRows()
      },
      { deep: true, immediate: true }
    )

    const onSort = (fieldName) => {
      const disableCurrentSorting = sortBy.value.key && sortBy.value.key === fieldName && sortBy.value.order === 'desc'
      sortBy.value = {
        key: disableCurrentSorting ? '' : fieldName,
        order: disableCurrentSorting || sortBy.value.key !== fieldName ? 'asc' : 'desc'
      }
      renderTableRows()
    }

    return {
      resizeEl,
      widthResizeEl,
      rowWidth,
      filters,
      filtersValues,
      columnsDef,
      alignThToTop,
      tableRows,
      icons: {
        Search,
        Top,
        Bottom,
        DCaret
      },
      sortBy,
      onSort,
      propsRows
    }
  }
}
</script>

<template>
  <div
    ref="resizeEl"
    :class="{ 'w-full': fullWidth, 'inline-block': !fullWidth }"
    class="resize-el"
  >
    <el-table-v2
      class="gs-font-scaled fast-table"
      :height="height"
      :row-class="rowClass"
      :columns="columnsDef"
      :data="tableRows"
      v-bind="$attrs"
      :width="Math.ceil(fullWidth ? widthResizeEl : rowWidth)"
      :estimated-row-height="calcRowHeight ? 40 : undefined"
      :row-height="40"
      :fixed="true"
      :row-key="rowKey"
      :header-height="alignThToTop ? 60 : 50"
      :expand-column-key="expandRowsData ? columns[0].key : undefined"
    >
      <template #row="props">
        <Row v-bind="props">
          <template #default="slotProps">
            <!-- slotProps : {row: {}, expandRow: boolean, id: String} -->
            <slot
              name="expand"
              v-bind="slotProps"
            />
          </template>
        </Row>
      </template>
      <template #header-cell="props">
        <div
          class="ft-header-cell"
          :class="[props.column.headerClass, { 'self-start': alignThToTop, 'self-center': !alignThToTop }, `header_cell_${props.column.dataKey}`]"
        >
          <div>
            <div class="relative inline-block pr-5">
              <template v-if="$slots[`header_cell_${props.column.dataKey}`]">
                <slot
                  :name="`header_cell${props.column.dataKey}`"
                  v-bind="props"
                />
              </template>
              <template v-else>
                {{ props.column.title }}
              </template>
              <el-button
                v-if="props.column.sort"
                class="gs-btn-text-neutral-light gs-sort gs-font-scaled absolute -top-0.5 right-0 mr-1 cursor-pointer bg-transparent px-0"
                :class="{
                  visible: sortBy.key === props.column.dataKey,
                  invisible: sortBy.key !== props.column.dataKey
                }"
                :icon="sortBy.key === props.column.dataKey ? (sortBy.order === 'asc' ? icons.Top : icons.Bottom) : icons.DCaret"
                size="small"
                @click.stop="onSort(props.column.dataKey)"
              />
            </div>
          </div>
          <div
            v-if="$slots[`header_filter_${props.column.dataKey}`]"
            class="w-full"
          >
            <slot
              :name="`header_filter_$${props.column.dataKey}`"
              v-bind="props"
            />
          </div>
          <div
            v-else-if="props.column?.filter"
            class="w-full"
          >
            <CrudTypeFields
              v-model:value-field="filtersValues[props.column.dataKey]"
              :type-value-field="props.column?.type"
              :prefix-icon="icons.Search"
            />
          </div>
        </div>
      </template>
      <template #cell="props">
        <div
          class="ft-cell"
          :class="[props.column.class, `cell_${props.column.dataKey}`]"
        >
          <div v-if="$slots[`cell_${props.column.dataKey}`]">
            <slot
              :name="`cell_${props.column.dataKey}`"
              v-bind="props"
              :filter-fields="filtersValues"
            />
          </div>
          <div v-else>
            {{ props.rowData[props.column.dataKey] }}
          </div>
        </div>
      </template>
    </el-table-v2>
  </div>
</template>

<style lang="postcss">
.fast-table {
  .el-table-v2__row-cell,
  .el-table-v2__header-cell {
    padding: 0;
  }
  .ft-header-cell {
    padding: 0.1em 0.3em;
    display: flex;
    flex-wrap: wrap;
    &:hover {
      .gs-sort {
        visibility: visible !important;
      }
    }
  }
  .ft-cell {
    padding: 0.4em 0.65em;
    min-height: 3em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .el-table-v2__body > div:nth-child(1) {
    overflow: auto !important;
  }
}
</style>
